import React from "react";

export default function ShoppingCartSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      strokeWidth={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2452 7.66658C10.7246 4.64394 13.3425 2.33325 16.5 2.33325C19.6575 2.33325 22.2754 4.64394 22.7548 7.66658H28.5C28.7891 7.66658 29.064 7.79166 29.2539 8.00956C29.4438 8.22747 29.5301 8.51689 29.4906 8.80322L26.824 28.1366C26.7557 28.6314 26.3328 28.9999 25.8333 28.9999H7.16667C6.66719 28.9999 6.2443 28.6314 6.17605 28.1366L3.50939 8.80322C3.46989 8.51689 3.55623 8.22747 3.74614 8.00956C3.93605 7.79166 4.21096 7.66658 4.50001 7.66658H10.2452ZM5.6474 9.66658L8.03821 26.9999H24.9618L27.3526 9.66658H5.6474ZM20.7174 7.66658H12.2826C12.7341 5.75546 14.451 4.33325 16.5 4.33325C18.549 4.33325 20.2659 5.75546 20.7174 7.66658Z"
        fill="#111318"
      />
    </svg>
  );
}
