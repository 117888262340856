import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { PATHS } from "@app-utils/routes";

import PolicyContent, {
  PHONE_NUMBER_LINK,
  PolicyContentData,
  generateLink,
  generateList,
  getEmailLink,
} from "./PolicyContent";

export default function ShippingContent() {
  const { t } = useTranslation();

  const tableContent: PolicyContentData = useMemo(() => {
    return {
      hideTableContent: true,
      content: {
        returnsExchanges: {
          items: [
            {
              title: t("page.shipping.new.option.title"),
              content: [
                t("page.shipping.new.option.content1"),
                t("page.shipping.new.option.content2"),
                generateList([
                  t("page.shipping.new.option.content3.1"),
                  t("page.shipping.new.option.content3.2"),
                  t("page.shipping.new.option.content3.3"),
                  t("page.shipping.new.option.content3.4"),
                ]),
              ],
            },
            {
              title: t("page.shipping.new.checkGoods.title"),
              content: [t("page.shipping.new.checkGoods.content")],
            },
            {
              title: t("page.shipping.new.internationalShipping.title"),
              content: [t("page.shipping.new.internationalShipping.content")],
            },
            {
              title: t("page.shipping.new.disclaimer.title"),
              content: [
                t("page.shipping.new.disclaimer.content"),
                t("page.shipping.new.disclaimer.content1"),
              ],
            },
            {
              title: t("page.shipping.new.questions.title"),
              content: [
                t("page.shipping.new.questions.content", {
                  link: generateLink({
                    type: "internal",
                    path: PATHS.returnsPolicy,
                  }),
                }),
              ],
            },
            {
              title: t("page.shipping.new.contactUs.title"),
              content: [
                t("page.shipping.new.contactUs.content", {
                  email: getEmailLink("contact"),
                  phone: PHONE_NUMBER_LINK,
                }),
              ],
            },
          ],
        },
      },
    };
  }, [t]);

  return <PolicyContent data={tableContent} />;
}
