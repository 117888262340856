import React from "react";

export default function FingerTap() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.56999 19.1C8.56999 19.1 8.52999 19.15 8.51999 19.18C8.53999 19.15 8.55999 19.13 8.56999 19.1Z"
        fill="#111318"
      />
      <path
        d="M8.50999 19.18C8.47999 19.24 8.44999 19.3 8.39999 19.34C8.44999 19.29 8.47999 19.23 8.50999 19.18Z"
        fill="#111318"
      />
      <path
        d="M7.34 19.34C7.55 19.55 7.87 19.62 8.16 19.5C8.07 19.54 7.97 19.56 7.87 19.56C7.66 19.56 7.48 19.48 7.34 19.34Z"
        fill="#111318"
      />
      <path
        d="M20.39 15.37C19.47 14.46 18.26 13.96 16.97 13.96H16.94L11.92 13.99V7.26001C11.92 6.63001 11.66 6.01001 11.22 5.56001C10.32 4.66001 8.72 4.66001 7.83 5.56001C7.38 6.01001 7.13 6.62001 7.13 7.26001V15.05L6.66 13.93C6.37 13.38 5.87 12.97 5.26 12.8C4.62 12.61 3.92 12.7 3.34 13.03C2.26 13.64 1.9 14.85 2.4 16.19L4.62 22.27C4.73 22.57 5.01999 22.76 5.31999 22.76C5.40999 22.76 5.49 22.75 5.58 22.71C5.97 22.57 6.17 22.14 6.03 21.75L3.81 15.67C3.57 15.02 3.67 14.58 4.09 14.34C4.32 14.21 4.6 14.17 4.85 14.25C5 14.29 5.21 14.4 5.31 14.58L7.19 19.11C7.23 19.2 7.28 19.28 7.35 19.35C7.49 19.49 7.67 19.57 7.88 19.57C7.98 19.57 8.08 19.55 8.17 19.51C8.26 19.47 8.33999 19.42 8.41 19.35C8.46 19.3 8.49 19.24 8.52 19.19C8.54 19.16 8.55999 19.14 8.56999 19.11C8.60999 19.02 8.63 18.92 8.63 18.82V7.26001C8.63 7.02001 8.72999 6.79001 8.88999 6.62001C9.22999 6.28001 9.81999 6.28001 10.16 6.62001C10.33 6.79001 10.42 7.02001 10.42 7.26001V14.73C10.42 14.93 10.5 15.12 10.64 15.26C10.78 15.4 10.97 15.48 11.17 15.48L16.94 15.45H16.96C17.85 15.45 18.69 15.8 19.32 16.43C19.96 17.06 20.31 17.91 20.31 18.81C20.31 19.9 20.2 20.92 19.97 21.82C19.87 22.22 20.11 22.63 20.52 22.73C20.58 22.75 20.64 22.75 20.7 22.75C21.04 22.75 21.34 22.52 21.43 22.18C21.68 21.16 21.81 20.02 21.81 18.81C21.81 17.51 21.3 16.29 20.38 15.37H20.39Z"
        fill="#111318"
      />
      <path
        d="M5.20001 7C5.20001 4.66 7.11001 2.75 9.45001 2.75C11.79 2.75 13.7 4.66 13.7 7C13.7 7.52 13.6 8.02 13.43 8.49V11.13C14.52 10.09 15.2 8.63 15.2 7C15.2 3.83 12.62 1.25 9.45001 1.25C6.28001 1.25 3.70001 3.83 3.70001 7C3.70001 8.7 4.45001 10.22 5.63001 11.28V8.84C5.36001 8.28 5.20001 7.66 5.20001 7Z"
        fill="#111318"
      />
    </svg>
  );
}
