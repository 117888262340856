import classNames from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import MTLogoWhiteSvg from "@app-components/svg/MTLogoSvg";
import { OutlineIcon } from "@app-components/svg/icon";
import { WEB_TYPE, WEB_TYPE_NAME, WEB_URL } from "@app-constants/common";
import { PATHS } from "@app-utils/routes";

import HeaderNavigation from "./HeaderNavigation";
import UserInfo from "./UserInfo";

export default function Header() {
  const navigate = useNavigate();

  const [openMenu, setOpenMenu] = useState(false);

  const handleOnNavigte = () => {
    if (WEB_TYPE === WEB_TYPE_NAME.AGENCY) {
      window.location.href = WEB_URL;
    } else {
      navigate(PATHS.home);
    }
  };
  const renderHeader = () => {
    if (WEB_TYPE === WEB_TYPE_NAME.AGENCY) {
      return (
        <div className="header__inner">
          <div className="left">
            <a href={WEB_URL}>
              <OutlineIcon.Home />
            </a>
          </div>
          <div className="center mt-logo">
            <MTLogoWhiteSvg />
          </div>
          <div className="right">
            <div className="flex user-menu">
              <UserInfo />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="header__inner">
        <div className="left">
          <div
            className={classNames("burger-menu pointer", {
              open: openMenu,
            })}
            onClick={() => setOpenMenu(!openMenu)}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="center mt-logo">
          {/* <img alt="" src={MTLogo} /> */}
          <div onClick={handleOnNavigte} className="cursor-pointer">
            <MTLogoWhiteSvg />
          </div>
        </div>

        <div className="right">
          <div className="flex user-menu">
            <UserInfo />
          </div>
        </div>
      </div>
    );
  };

  return (
    <header className="header mt-header">
      <div className="container">{renderHeader()}</div>
      <HeaderNavigation onClose={() => setOpenMenu(false)} open={openMenu} />
    </header>
  );
}
