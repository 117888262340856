import React from "react";

export default function ChevronLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
    >
      <path
        d="M22.0001 3.66663C11.8753 3.66663 3.66675 11.8752 3.66675 22C3.66675 32.1247 11.8753 40.3333 22.0001 40.3333C32.1248 40.3333 40.3334 32.1247 40.3334 22C40.3334 11.8752 32.1248 3.66663 22.0001 3.66663ZM25.1123 29.4646C25.2488 29.5943 25.3579 29.7499 25.4333 29.9224C25.5087 30.0949 25.5487 30.2807 25.5511 30.469C25.5535 30.6572 25.5182 30.844 25.4473 31.0184C25.3764 31.1927 25.2713 31.3511 25.1382 31.4842C25.0051 31.6173 24.8467 31.7224 24.6723 31.7934C24.498 31.8643 24.3112 31.8996 24.1229 31.8972C23.9347 31.8948 23.7489 31.8547 23.5764 31.7793C23.4039 31.704 23.2482 31.5948 23.1186 31.4584L14.6571 22.9968C14.3928 22.7324 14.2443 22.3738 14.2443 22C14.2443 21.6261 14.3928 21.2675 14.6571 21.0031L23.1186 12.5415C23.3852 12.2882 23.7402 12.1491 24.108 12.1538C24.4757 12.1585 24.8271 12.3067 25.0871 12.5667C25.3472 12.8268 25.4954 13.1782 25.5001 13.5459C25.5048 13.9136 25.3656 14.2687 25.1123 14.5353L17.6486 22L25.1123 29.4646Z"
        fill="#111318"
      />
    </svg>
  );
}
