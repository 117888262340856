import { ConfigProvider } from "antd";
import { Provider, createStore } from "jotai";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import { SEO } from "@app-components/header/SEO";

import "./App.css";
import Root from "./pages/Root";

const jotaiStore = createStore();

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Roboto",
        },
      }}
    >
      <HelmetProvider>
        <BrowserRouter>
          <Provider store={jotaiStore}>
            <SEO />
            <Root />
          </Provider>
        </BrowserRouter>
      </HelmetProvider>
    </ConfigProvider>
  );
}

export default App;
