import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";

import HalfRingBlurImage from "@app-assets/images/product/half-ring-blur.png";
import LightBlurImage from "@app-assets/images/product/light-blur.png";
import TheRingPannelBlurImage from "@app-assets/images/product/ring-panel-blur.png";
import LockSvg from "@app-assets/svg/solid/lock.svg";
import TheRingSvg from "@app-assets/svg/the-ring.svg";

import { ProductDetailType } from "./DetailCard";
import ProductInfo from "./ProductInfo";

function ProductHeader({ scrollPosition }: { scrollPosition: any }) {
  // const ring = fetchImageS3(
  //   "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/product/half-ring.png"
  // );

  return (
    <div className="header-bg">
      <div className="top">
        <div className="light">
          <LazyLoadImage
            height={"100%"}
            width={"100%"}
            src={LightBlurImage}
            placeholderSrc={LightBlurImage}
            effect="blur"
            alt="The Ring - light background"
          />
        </div>
        <div className="half-ring container">
          <LazyLoadImage
            height={"100%"}
            width={"100%"}
            placeholderSrc={HalfRingBlurImage}
            scrollPosition={scrollPosition}
            src={
              "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/product/half-ring.png"
            }
            alt="The Ring - Secure"
          />
          <div className="title">Secure</div>
        </div>
        <div className="lock">
          <div className="flex justify-center ">
            <LazyLoadImage
              height={"100%"}
              width={"100%"}
              src={LockSvg}
              scrollPosition={scrollPosition}
              alt="The lock image"
            />
          </div>
        </div>
        <div className="the-ring">
          <LazyLoadImage
            height={"100%"}
            width={"100%"}
            src={TheRingSvg}
            alt="The Ring"
          />
        </div>
      </div>
      <div className="pannel">
        <LazyLoadImage
          height={"100%"}
          width={"100%"}
          src={
            "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/product/ring-panel.png"
          }
          placeholderSrc={TheRingPannelBlurImage}
          scrollPosition={scrollPosition}
          alt="The Ring information"
        />
        <div className="content">
          {Object.values(ProductDetailType).map((key: any) => (
            <ProductInfo type={key} key={key} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default trackWindowScroll(ProductHeader);
