import { throwErrorMessage } from "@app-utils/errorHandler";

import { CreateAddressReq } from "../interface/address";
import { baseApi } from "./common";

const getAddressesList = async () => {
  try {
    const response = await baseApi.get(`addresses`);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const createAddress = async (payload: CreateAddressReq) => {
  try {
    const response = await baseApi.post(`addresses`, payload);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const editAddress = async (id: number, payload: CreateAddressReq) => {
  try {
    const response = await baseApi.patch(`addresses/${id}`, payload);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const deleteAddress = async (id: number) => {
  try {
    const response = await baseApi.delete(`addresses/${id}`);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const createAddressAnonymous = async (payload: CreateAddressReq) => {
  try {
    const response = await baseApi.post(`addresses/anonymous`, payload);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const addressServices = {
  getAddressesList,
  createAddress,
  editAddress,
  deleteAddress,
  createAddressAnonymous,
};

export default addressServices;
