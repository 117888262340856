import { Modal } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import validator from "validator";

import StyledButton from "@app-components/button/StyledButton";
import { MTAutoComplete } from "@app-components/input/MTAutoComplete";
import MTInput from "@app-components/input/MTInput";
import MTTextArea from "@app-components/input/MTTextArea";
import { SolidIcon } from "@app-components/svg/icon";
import { INDUSTRIES, JOB_ROLE, PROVINCES } from "@app-constants/pns";
import { IPartnership } from "@app-interface/pns";
import pnsServices from "@app-services/pnsServices";
import useService from "@app-services/shared/use_service";

const useCreatePartnership = () => useService(pnsServices.createPartnership);

const initPns = {
  name: "",
  email: "",
  phoneNumber: "",
  companyName: "",
  address: "",
  city: undefined,
  industry: undefined,
  jobRole: "",
  message: "",
  proposal: "",
};

export function PNSForm() {
  const { t } = useTranslation();
  const [
    createPartnershipPayload,
    onCreatePartnership,
    clearCreatePartnershipState,
  ] = useCreatePartnership();

  const [pns, setPns] = useState<IPartnership>(initPns);
  const [showModal, setShowModal] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);

  const provinces = useMemo(() => {
    return (
      PROVINCES.map((item) => ({
        label: item,
        value: item,
      })) || []
    );
  }, []);

  const disabledButton = useMemo(() => {
    return !Object.values(pns).every((value) => !!value);
  }, [pns]);

  const validEmail = validator.isEmail(pns.email);
  const validPhone = validator.isMobilePhone(pns.phoneNumber, "vi-VN");

  useEffect(() => {
    if (createPartnershipPayload.error || createPartnershipPayload.success) {
      setShowModal(true);
    }
  }, [createPartnershipPayload.error, createPartnershipPayload.success]);

  const handleOnSubmit = () => {
    onCreatePartnership(pns);
  };

  const handleOnCloseModal = () => {
    clearCreatePartnershipState();
    setShowModal(false);
    setPns(initPns);
  };

  return (
    <div className="pns-landing-page__hero-form">
      <div className="mt-heading-xl mb-[8px]">
        {t("page.pns.hero.form.title")}
      </div>
      <div className="subtitle">{t("page.pns.hero.form.subtitle")}</div>
      <div className="input-container">
        <MTInput
          label={t("input.name.label")}
          value={pns.name}
          disabled={createPartnershipPayload.loading}
          onChangeText={(name) => setPns((prev) => ({ ...prev, name }))}
        />
        <MTInput
          label={t("input.email.label")}
          value={pns.email}
          disabled={createPartnershipPayload.loading}
          onChangeText={(email) => setPns((prev) => ({ ...prev, email }))}
          error={showEmailError && !validEmail ? t("content.invalidEmail") : ""}
          onFocus={() => setShowEmailError(false)}
          onBlur={() => setShowEmailError(!!pns.email)}
        />
        <MTInput
          label={t("input.phoneNumber.label")}
          value={pns.phoneNumber}
          disabled={createPartnershipPayload.loading}
          onChangeText={(phoneNumber) =>
            setPns((prev) => ({ ...prev, phoneNumber }))
          }
          error={showPhoneError && !validPhone ? t("content.invalidPhone") : ""}
          onFocus={() => setShowPhoneError(false)}
          onBlur={() => setShowPhoneError(!!pns.phoneNumber)}
        />
        <MTInput
          label={t("input.companyName.label")}
          value={pns.companyName}
          disabled={createPartnershipPayload.loading}
          onChangeText={(companyName) =>
            setPns((prev) => ({ ...prev, companyName }))
          }
        />
        <MTInput
          label={t("input.address.label")}
          value={pns.address}
          disabled={createPartnershipPayload.loading}
          onChangeText={(address) => setPns((prev) => ({ ...prev, address }))}
        />
      </div>

      <div className="input-container">
        <MTAutoComplete
          className="w-full"
          placeholder={t("input.city.label")}
          suffix
          allowClear
          options={provinces}
          value={pns.city}
          disabled={createPartnershipPayload.loading}
          onSelect={(city) => setPns((prev) => ({ ...prev, city }))}
          onSearch={(city) => setPns((prev) => ({ ...prev, city }))}
        />
      </div>
      <div className="select-container">
        <div className="wrapper">
          <MTAutoComplete
            className="w-full"
            options={INDUSTRIES}
            placeholder={t("input.industry.label")}
            suffix
            allowClear
            value={pns.industry}
            disabled={createPartnershipPayload.loading}
            onSelect={(industry) => setPns((prev) => ({ ...prev, industry }))}
            onSearch={(industry) => setPns((prev) => ({ ...prev, industry }))}
          />
        </div>
        <div className="wrapper">
          <MTAutoComplete
            className="w-full"
            options={JOB_ROLE.map((role) => ({ value: role, label: role }))}
            placeholder={t("input.jobRole.label")}
            suffix
            allowClear
            value={pns.jobRole}
            disabled={createPartnershipPayload.loading}
            onSelect={(jobRole) => setPns((prev) => ({ ...prev, jobRole }))}
            onSearch={(jobRole) => setPns((prev) => ({ ...prev, jobRole }))}
          />
        </div>
      </div>
      <div className="input-container">
        <MTTextArea
          label={t("input.message.label")}
          rows={1}
          value={pns.message}
          onChangeText={(message) => setPns((prev) => ({ ...prev, message }))}
        />
        <MTTextArea
          label={t("input.proposal.label")}
          rows={1}
          value={pns.proposal}
          onChangeText={(proposal) => setPns((prev) => ({ ...prev, proposal }))}
        />
      </div>
      <div>
        <StyledButton
          loading={createPartnershipPayload.loading}
          disabled={disabledButton}
          className="w-full primary mt-button-lg"
          onClick={handleOnSubmit}
        >
          {t("button.submit")}
        </StyledButton>
      </div>

      <Modal
        open={showModal}
        onCancel={handleOnCloseModal}
        title={null}
        footer={null}
      >
        {createPartnershipPayload.error && (
          <div className="flex flex-col justify-center items-center gap-2">
            <SolidIcon.Warning color="red" className="h-[82px] w-[82px]" />

            <div className="mt-title-xxl text-center">
              {createPartnershipPayload.error}
            </div>
            <div className="mt-title-xxl text-center">{t("errorCode.429")}</div>
          </div>
        )}
        {createPartnershipPayload.success && (
          <div className="flex flex-col justify-center items-center gap-2">
            <div>
              <SolidIcon.Check className="h-[82px] w-[82px]" />
            </div>

            <div className="mt-title-xxl text-center whitespace-break-spaces">
              {t("page.pns.hero.form.submitSuccess")}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
