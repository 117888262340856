import React from "react";

export default function MTLogoWhiteSvg({
  color = "white",
}: {
  color?: string;
}) {
  return (
    <svg
      width="127"
      height="39"
      viewBox="0 0 127 39"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M70.5077 10.9385V16.6989H72.6087L72.5956 10.7002C72.7161 10.3162 72.9302 9.99808 73.238 9.75971C73.5458 9.52134 73.9338 9.40246 74.4156 9.40246C75.0179 9.40246 75.4461 9.53461 75.7004 9.78623C75.9547 10.0511 76.0885 10.4486 76.0885 11.0048V16.6989H78.1902V10.9518C78.1768 9.90558 77.9224 9.12421 77.4138 8.5945C76.9053 8.06479 76.1421 7.7998 75.1116 7.7998C73.8803 7.7998 72.9168 8.25007 72.2342 9.13734C71.7792 8.23683 70.9227 7.7998 69.6647 7.7998C68.5271 7.7998 67.6169 8.15744 66.9611 8.87255L66.8938 7.95887H64.9131V16.7122H67.0147V10.3692C67.3627 9.7335 67.9648 9.40246 68.8079 9.40246C69.9188 9.38921 70.4809 9.90557 70.5077 10.9385Z" />
      <path d="M87.7326 10.7268C87.7192 9.79981 87.3846 9.07128 86.7288 8.55481C86.073 8.03834 85.2031 7.78662 84.1057 7.78662C83.3829 7.78662 82.7272 7.90583 82.1517 8.1442C81.5762 8.38257 81.1077 8.71363 80.7598 9.12416C80.4252 9.54793 80.2507 9.98497 80.2507 10.4749H82.3523C82.3523 10.1306 82.4993 9.83944 82.8072 9.62755C83.115 9.41567 83.503 9.29648 83.9848 9.29648C84.5335 9.29648 84.9489 9.42899 85.2166 9.70709C85.4843 9.98519 85.6179 10.3425 85.6179 10.7928V11.3624H84.3331C82.9546 11.3624 81.8974 11.6138 81.1479 12.117C80.4118 12.6203 80.037 13.3355 80.037 14.2625C80.037 15.0041 80.3313 15.6264 80.9336 16.1297C81.5225 16.6329 82.299 16.8846 83.2359 16.8846C84.1995 16.8846 85.0291 16.5535 85.6982 15.9046C85.7652 16.2887 85.845 16.5534 85.9387 16.7255H88.0802C87.8393 16.262 87.7188 15.4942 87.7188 14.6997V10.7268H87.7326ZM85.6309 14.236C85.4436 14.5539 85.1764 14.8056 84.8016 14.991C84.4403 15.1764 84.0383 15.269 83.6234 15.269C83.1683 15.269 82.8209 15.1631 82.5399 14.9379C82.2722 14.7128 82.138 14.4083 82.138 14.0375C82.138 13.574 82.3387 13.2164 82.7268 12.9648C83.1149 12.7132 83.7039 12.5807 84.4801 12.5807H85.6179V14.236H85.6309Z" />
      <path d="M94.612 7.78662C93.4878 7.78662 92.5776 8.18409 91.8817 8.96541L91.8144 7.95895H89.8336V16.7123H91.9353V10.4882C92.3502 9.77309 92.9791 9.41546 93.8356 9.41546C94.4245 9.41546 94.8393 9.54795 95.0936 9.81281C95.3479 10.0777 95.4818 10.4884 95.4818 11.0446V16.7123H97.5828V10.9253C97.556 8.83296 96.5526 7.78662 94.612 7.78662Z" />
      <path d="M104.167 18.1027L106.938 8.02515H104.85L103.083 14.6465L100.648 8.02515H98.5466L101.839 16.7254C102.053 17.3081 102.468 18.5001 101.785 18.5531C101.317 18.5928 99.885 18.5664 99.885 18.5664V31.0277H101.986V24.7772C102.401 24.0753 103.03 23.7309 103.873 23.7309C104.422 23.7309 104.823 23.8502 105.104 24.1018C105.386 24.3534 105.519 24.7771 105.519 25.3863V31.0277H107.62V25.3067C107.594 23.1747 106.576 22.1154 104.582 22.1154C103.538 22.1154 102.669 22.4863 101.973 23.2279V20.6456C103.164 20.6456 103.632 20.2746 104.167 18.1027Z" />
      <path d="M67.2285 29.346C67.0813 29.2003 67.0148 28.9619 67.0148 28.6176V23.7311H68.674V22.2746H67.0148V20.1423H64.9131V22.2746H63.3741V23.7311H64.9131V28.6971C64.9131 30.3657 65.7162 31.2002 67.3356 31.2002C67.7773 31.2002 68.2461 31.1338 68.7145 31.0146V29.4918C68.4602 29.5448 68.2189 29.5714 67.978 29.5714C67.6301 29.5714 67.3757 29.4917 67.2285 29.346Z" />
      <path d="M77.1864 23.2545C76.3968 22.4997 75.3792 22.1155 74.1078 22.1155C73.2513 22.1155 72.5018 22.3008 71.8461 22.6716C71.1903 23.0424 70.6815 23.5723 70.3201 24.261C69.9588 24.9496 69.7719 25.7177 69.7719 26.5785V26.6709C69.7719 28.0349 70.1729 29.1341 70.9625 29.9551C71.7522 30.7762 72.8096 31.1869 74.1346 31.1869C75.0045 31.1869 75.7673 31.0013 76.4231 30.6305C77.0789 30.2597 77.5877 29.7301 77.9491 29.0547C78.3104 28.3793 78.4843 27.598 78.4843 26.7372L78.4712 26.2739C78.4043 25.0291 77.9761 24.0226 77.1864 23.2545ZM75.7945 28.8429C75.393 29.3594 74.8439 29.624 74.1346 29.624C73.4253 29.624 72.8769 29.3727 72.4754 28.8562C72.0739 28.3397 71.8728 27.6377 71.8728 26.7372C71.8728 25.7175 72.0739 24.9627 72.4754 24.4462C72.8769 23.943 73.4252 23.678 74.1078 23.678C74.8038 23.678 75.3528 23.943 75.7677 24.4595C76.1692 24.976 76.3833 25.6779 76.3833 26.5652C76.3833 27.5717 76.1826 28.3265 75.7945 28.8429Z" />
      <path d="M85.9923 28.5649C85.6309 29.2535 84.9486 29.5979 83.9181 29.5979C82.9277 29.5979 82.4327 29.0417 82.4327 27.9293V22.2878H80.3311V27.9555C80.3311 29.0017 80.5854 29.8096 81.1074 30.3658C81.6294 30.922 82.3925 31.2002 83.4097 31.2002C84.5473 31.2002 85.4169 30.8559 86.0459 30.1805L86.0995 31.0412H88.0802V22.2878H85.9792V28.5649H85.9923Z" />
      <path d="M92.6175 24.3798C92.9922 23.9163 93.5144 23.6911 94.197 23.6911C94.7323 23.6911 95.1606 23.8502 95.5085 24.1813C95.8565 24.4991 96.03 24.9228 96.0568 25.4128H98.0376C97.9974 24.4195 97.6363 23.6249 96.9403 23.0157C96.2444 22.4066 95.3346 22.1021 94.2238 22.1021C92.9122 22.1021 91.8681 22.4995 91.1053 23.3074C90.3424 24.1019 89.9675 25.1745 89.9675 26.512V26.7105C89.9675 28.101 90.3555 29.2001 91.1183 29.9947C91.8812 30.7893 92.9252 31.1868 94.2368 31.1868C94.906 31.1868 95.5352 31.0543 96.1241 30.7762C96.713 30.4981 97.1678 30.1271 97.5023 29.6504C97.8369 29.1736 98.0245 28.6705 98.0513 28.1276H96.0705C96.0438 28.5514 95.8562 28.8957 95.5216 29.1871C95.1736 29.4784 94.7454 29.6106 94.21 29.6106C93.5141 29.6106 92.9922 29.3725 92.6175 28.909C92.2561 28.4455 92.0685 27.7303 92.0685 26.7901V26.4722C92.0551 25.5452 92.2427 24.8433 92.6175 24.3798Z" />
      <path d="M113.737 22.1155C112.934 22.1155 112.211 22.3008 111.555 22.6848C110.899 23.0689 110.391 23.5989 110.043 24.2875C109.682 24.9761 109.508 25.7571 109.508 26.6312V26.8698C109.508 28.1808 109.922 29.2136 110.752 30.0081C111.582 30.7895 112.666 31.1869 113.991 31.1869C114.768 31.1869 115.477 31.0412 116.092 30.7498C116.721 30.4585 117.217 30.0613 117.592 29.5316L116.454 28.5251C115.852 29.2535 115.062 29.6108 114.098 29.6108C113.415 29.6108 112.84 29.3989 112.385 28.9752C111.93 28.5514 111.676 27.9822 111.609 27.2539H117.725V26.4592C117.725 25.0687 117.377 23.9961 116.681 23.228C115.985 22.4996 115.008 22.1155 113.737 22.1155ZM115.651 25.8368H111.649C111.743 25.1614 111.97 24.6318 112.332 24.261C112.693 23.8902 113.148 23.6913 113.724 23.6913C114.312 23.6913 114.767 23.8636 115.089 24.1947C115.41 24.5258 115.611 25.0288 115.651 25.691V25.8368V25.8368Z" />
      <path d="M126.625 27.3464C126.398 27.0153 126.05 26.7374 125.581 26.499C125.113 26.2606 124.457 26.062 123.641 25.8899C122.811 25.7177 122.262 25.5454 121.995 25.3865C121.727 25.2276 121.593 24.9892 121.593 24.6846C121.593 24.3535 121.74 24.0888 122.035 23.8902C122.329 23.6916 122.704 23.5856 123.159 23.5856C123.654 23.5856 124.056 23.7049 124.35 23.9565C124.644 24.2081 124.805 24.5259 124.805 24.8967H126.906C126.906 24.0756 126.558 23.4002 125.876 22.8837C125.18 22.3673 124.283 22.1023 123.159 22.1023C122.115 22.1023 121.245 22.354 120.562 22.8572C119.88 23.3605 119.545 23.996 119.545 24.7509C119.545 25.6514 120.067 26.3401 121.124 26.8168C121.606 27.0287 122.195 27.214 122.918 27.3464C123.641 27.4788 124.149 27.6511 124.457 27.8365C124.765 28.0219 124.926 28.2866 124.926 28.6309C124.926 28.9487 124.778 29.2005 124.497 29.3991C124.203 29.5977 123.788 29.6904 123.239 29.6904C122.664 29.6904 122.209 29.5712 121.861 29.3196C121.513 29.0679 121.326 28.7106 121.299 28.2471H119.251C119.251 28.7769 119.411 29.2534 119.759 29.7169C120.107 30.1804 120.563 30.5248 121.165 30.7897C121.767 31.0545 122.449 31.1737 123.212 31.1737C124.336 31.1737 125.26 30.9353 125.956 30.4453C126.652 29.9553 127 29.3198 127 28.5384C126.96 28.0749 126.853 27.6775 126.625 27.3464Z" />
      <path d="M28.9251 18.2222H26.302V20.8177H28.9251V18.2222Z" />
      <path d="M10.5354 19.5198C10.5354 21.9036 11.7131 23.4264 13.1585 23.4264V11.7859C13.1585 11.0311 13.7745 10.4219 14.5374 10.4219C18.1376 10.4219 21.0547 13.309 21.0547 16.8713V19.5331C21.0547 21.9168 22.2331 23.4397 23.6785 23.4397V16.9111C23.6785 11.9053 19.5696 7.8396 14.5106 7.8396H0.0160341V10.4351H1.36747C2.03665 10.4351 2.53187 11.0313 2.39803 11.6802L0.123208 19.5464C-0.358603 21.7844 0.658448 23.1483 1.68899 23.4529H1.70206L5.02117 11.72C5.16839 10.9784 5.8244 10.4616 6.57388 10.4616H9.18395C9.92005 10.4616 10.5223 11.0576 10.5223 11.7859V19.5198H10.5354Z" />
      <path d="M31.5613 15.6133C31.5613 17.0435 33.2077 18.2088 35.5097 18.2088H44.0888C43.7275 18.6326 42.0813 20.4997 42.0813 23.4132V31.2133C43.5267 31.2133 44.7044 29.518 44.7044 27.3065V23.4264C44.7044 20.5395 46.9662 18.2088 49.9775 18.2088H55.2238V15.6133H31.5613Z" />
      <path d="M19.7036 4.47591C20.1051 4.26403 20.5199 4.07872 20.9348 3.90656C23.0494 3.0193 25.2848 2.58228 27.5868 2.58228C29.8888 2.58228 32.1368 3.03254 34.2381 3.90656C36.2724 4.7541 38.106 5.97243 39.6719 7.53508C40.5686 8.42235 41.3586 9.389 42.0278 10.4352C42.4694 10.4087 42.9107 10.3825 43.3524 10.3825C43.9145 10.3825 44.477 10.4087 45.0391 10.4617C44.1156 8.71364 42.9377 7.12471 41.5056 5.70773C39.6988 3.91995 37.584 2.51602 35.2418 1.53605C32.8194 0.516353 30.2364 0 27.573 0C24.9097 0 22.3401 0.516353 19.9043 1.53605C18.7667 2.01279 17.6827 2.59555 16.6655 3.27093C17.2678 3.44309 17.8698 3.66819 18.4587 3.90656C18.887 4.07872 19.3021 4.26403 19.7036 4.47591Z" />
      <path d="M35.496 34.5373C35.0945 34.7492 34.6797 34.9345 34.2648 35.1066C32.1502 35.9939 29.9155 36.4309 27.6136 36.4309C25.3116 36.4309 23.0628 35.9807 20.9616 35.1066C18.9273 34.2591 17.0937 33.0408 15.5278 31.4781C14.6311 30.5909 13.8418 29.6242 13.1726 28.578C12.7309 28.6045 12.2889 28.6307 11.8473 28.6307C11.2852 28.6307 10.7234 28.6045 10.1613 28.5515C11.0847 30.2996 12.262 31.8885 13.6941 33.2922C15.5009 35.08 17.6157 36.4839 19.9578 37.4639C22.3803 38.4836 24.9633 38.9999 27.6266 38.9999C30.29 38.9999 32.8596 38.4836 35.2954 37.4639C36.433 36.9872 37.517 36.4044 38.5341 35.7423C37.9319 35.5701 37.3298 35.345 36.7409 35.1066C36.3127 34.9345 35.8975 34.7359 35.496 34.5373Z" />
    </svg>
  );
}
