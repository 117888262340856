export function NewsCard({
  title,
  datetime,
  img,
  url,
}: {
  title: string;
  datetime: string;
  img: string;
  url?: string;
}) {
  return (
    <div className="news-card">
      <div className="news-card__img">
        <img src={img} alt={title} />
      </div>
      <div className="news-card__content">
        <div className="news-card__title">{title}</div>
        <div className="news-card__bottom">
          <div className="news-card__datetime">{datetime}</div>
          <div className="news-card__url">
            <button className="view-more-btn">
              <a href={url}>View Details</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
