import { Divider } from "antd";

import MemberCarousel from "@app-components/aboutUs/MemberCarousel";

const MEMBERS = [
  {
    name: "Paul Emile Destouches",
    role: "page.pns.team.ceo.title",
    image:
      "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/about/polo.jpg",
    desc: "page.pns.team.ceo.desc",
  },
  {
    name: "Lộc Triệu",
    role: "page.pns.team.strategyConsultant.title",
    image:
      "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/about/trieu.jpg",
    desc: "page.pns.team.strategyConsultant.decs",
  },
  {
    name: "Guillaume Copp",
    role: "page.pns.team.headOfStrategicPns.title",
    image:
      "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/about/copp.jpg",
    desc: "page.pns.team.headOfStrategicPns.desc",
  },
  {
    name: "Linh",
    role: "page.pns.team.headOfPnsDev.title",
    image:
      "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/about/linh.jpg",
    desc: "page.pns.team.headOfPnsDev.desc",
  },
];
export function PNSTeamSection() {
  return (
    <section className="container pns-landing-page__team">
      <Divider className="my-[72px]" />
      <h2 className="section-title mb-[40px]">The Team</h2>
      <div>
        <MemberCarousel members={MEMBERS} />
      </div>
    </section>
  );
}
