import React from "react";
import { useTranslation } from "react-i18next";

import { SEO } from "@app-components/header/SEO";

import Header from "../../components/header/Header";
import LPFooter from "../../components/landing-page/Footer";
import PolicyContact from "../../components/policy/PolicyContact";

export default function Legal() {
  const { t } = useTranslation();
  return (
    <div className="policy">
      <Header />
      <div className="container">
        <SEO title={t("title.legal")} />
        <div className="policy__page-title">{t("title.legal")}</div>
        <PolicyContact />
      </div>
      <LPFooter />
    </div>
  );
}
