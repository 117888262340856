import { Modal } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomLazyLoadImage from "@app-components/common/CustomLazyLoadImage";

export default function MemberInfo({
  image,
  name,
  role,
  desc,
}: {
  image: string;
  name: string;
  role: string;
  desc: string;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const Image = useMemo(() => {
    return (
      <CustomLazyLoadImage
        placeholder={
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8Ww8AAj8BXkQ+xPEAAAAASUVORK5CYII="
        }
        image={image}
        alt={`${name} - ${role} - Many Touches`}
      />
    );
  }, [image, name, role]);

  return (
    <div>
      <div className="member" onClick={() => setOpen(true)}>
        <div className="img">
          {Image}
          <div className="overlay"></div>
        </div>
        <div>
          <div className="name">{name}</div>
          <div className="role">{t(role)}</div>
        </div>
      </div>
      {
        <Modal
          open={open}
          centered
          footer={null}
          onCancel={() => setOpen(false)}
          bodyStyle={{
            maxWidth: "1000px",
            padding: 20,
          }}
          style={{ width: "auto", padding: 20 }}
          className="modal-w-auto"
          rootClassName="w-full"
          wrapClassName="member-info-modal"
        >
          <div className="flex flex-col items-center md:flex-row gap-[32px] info-modal">
            <div>{Image}</div>

            <div className="flex flex-col flex-1">
              <div className="mt-title-xl">{name}</div>
              <div className="mt-text-body-lg role">{t(role)}</div>
              <div className="mt-text-body-lg">{t(desc)}</div>
            </div>
          </div>
        </Modal>
      }
    </div>
  );
}
