export default function Circle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00065 2.38613C4.9001 2.38613 2.38662 4.89962 2.38662 8.00016C2.38662 11.1007 4.9001 13.6142 8.00065 13.6142C11.1012 13.6142 13.6147 11.1007 13.6147 8.00016C13.6147 4.89962 11.1012 2.38613 8.00065 2.38613ZM1.33398 8.00016C1.33398 4.31826 4.31875 1.3335 8.00065 1.3335C11.6825 1.3335 14.6673 4.31826 14.6673 8.00016C14.6673 11.6821 11.6825 14.6668 8.00065 14.6668C4.31875 14.6668 1.33398 11.6821 1.33398 8.00016Z"
      />
    </svg>
  );
}
