import { PNSHeroSection } from "@app-components/PNS/landing/PNSHeroSection";
import { PNSNewsSection } from "@app-components/PNS/landing/PNSNewsSection";
import { PNSPartnersSection } from "@app-components/PNS/landing/PNSPartnersSection";
import { PNSTeamSection } from "@app-components/PNS/landing/PNSTeamSection";
import { PNSValueSection } from "@app-components/PNS/landing/PNSValueSection";
import Header from "@app-components/header/Header";
import useScrollToTop from "@app-hook/useScrollToTop";

export default function PNSLandingPage() {
  useScrollToTop();

  return (
    <div className="pns-landing-page">
      <Header />
      <div className="content">
        <PNSHeroSection />
        <PNSValueSection />
        <PNSPartnersSection />
        <PNSTeamSection />
        <PNSNewsSection />
      </div>
    </div>
  );
}
