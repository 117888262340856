import { useRef } from "react";

export default function MTTextArea({
  className,
  label,
  value,
  error,
  floating = true,
  onChangeText,
  onBlur,
  onFocus,
  rows = 1,
}: {
  className?: string;
  label: string;
  value?: string;
  error?: string;
  floating?: boolean;
  onChangeText?: (text: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  rows?: number;
}) {
  const handleOnInput = (e: any) => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0px";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
      textAreaRef.current.marginBlockStart = "14px";
    }
  };
  const textAreaRef = useRef<any>(null);
  return (
    <div className={className}>
      <div className="input relative w-full z-10 mt-text-area">
        <textarea
          onInput={handleOnInput}
          value={value}
          rows={rows}
          className="
            block
            py-[6px]
            w-full
            bg-transparent
            border-0
            border-b
            border-b-metalic-silver
            appearance-none
            focus:outline-none
            focus:ring-0
            peer
            mt-text-button-lg
          "
          ref={textAreaRef}
          // dont remove this line
          placeholder={floating ? " " : label}
          // dont remove this line
          autoComplete="off"
          onChange={(e) => onChangeText?.(e.target.value)}
          onBlur={onBlur}
          onFocus={() => onFocus?.()}
        />
        {floating && (
          <label
            className="
          absolute
          duration-300
          transform
          -translate-y-[8px]
          flex
          items-center
          top-0
          left-0
          -z-10
          origin-[0]
          h-[34px]
          peer-placeholder-shown:translate-y-[16px]
          peer-placeholder-shown:text-[18px]
          peer-placeholder-shown:leading-6
          peer-focus:-translate-y-[8px]
          peer-focus:bottom-0
          text-tertiary
          select-none
          peer-focus:text-[14px]
          peer-focus:leading-[20px]
          "
          >
            {label}
          </label>
        )}
      </div>
      {!!error && (
        <div className="input-error text-red-600 text-md pt-2 text-left">
          {error}
        </div>
      )}
    </div>
  );
}
