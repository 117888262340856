import React from "react";

export default function ChevronLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M18.0007 1.33301C8.79926 1.33301 1.33398 8.79829 1.33398 17.9997C1.33398 27.2011 8.79926 34.6663 18.0007 34.6663C27.202 34.6663 34.6673 27.2011 34.6673 17.9997C34.6673 8.79829 27.202 1.33301 18.0007 1.33301Z"
        stroke="#111318"
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M21.4751 26.3351L13.1406 18.0005L21.4751 9.66602"
        stroke="#111318"
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
