import { memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CustomLazyLoadImage = memo(
  ({
    placeholder,
    image,
    wrapperClassName = "cover-center",
    className,
    alt = "The Ring",
  }: {
    placeholder: any;
    image: string;
    className?: string;
    wrapperClassName?: string;
    alt?: string;
  }) => {
    return (
      <LazyLoadImage
        wrapperClassName={wrapperClassName}
        className={className}
        width={"100%"}
        height={"100%"}
        placeholderSrc={placeholder}
        src={image}
        alt={alt}
      />
    );
  }
);

export default CustomLazyLoadImage;
