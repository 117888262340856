export default function MinusCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 12C1.25 6.06495 6.06495 1.25 12 1.25C17.935 1.25 22.75 6.06495 22.75 12C22.75 17.935 17.935 22.75 12 22.75C6.06495 22.75 1.25 17.935 1.25 12ZM12 2.75C6.89338 2.75 2.75 6.89338 2.75 12C2.75 17.1066 6.89338 21.25 12 21.25C17.1066 21.25 21.25 17.1066 21.25 12C21.25 6.89338 17.1066 2.75 12 2.75ZM7.0835 12.0008C7.0835 11.5866 7.41929 11.2508 7.8335 11.2508H16.168C16.5822 11.2508 16.918 11.5866 16.918 12.0008C16.918 12.415 16.5822 12.7508 16.168 12.7508H7.8335C7.41929 12.7508 7.0835 12.415 7.0835 12.0008Z"
        fill="#111318"
      />
    </svg>
  );
}
