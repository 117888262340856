import { useTranslation } from "react-i18next";
import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import RingFunc2 from "@app-assets/images/ring-func-2.png";
import RingFunc3 from "@app-assets/images/ring-func-3.png";
import RingOverview from "@app-components/ringFunction/RingOverview";
import GuideImage from "@app-components/userGuide/GuideImage";

export default function ContentHowToUsingRing() {
  const { t } = useTranslation();

  return (
    <div className="mt-ugd">
      <div className="mt-title">
        <span>{t("page.newProduct.guide.useRing")}</span>
      </div>
      <div className="">
        <Swiper
          navigation
          modules={[Navigation, Pagination, Keyboard, Mousewheel]}
          spaceBetween={50}
          mousewheel
          loop
          keyboard
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <div className="mt-ugd__ur">
              <div className="mt-ugd__ur-img">
                <div className="lp-mansory__image">
                  <RingOverview />
                </div>
              </div>
              <div className="mt-ugd__ur-content">
                <div className="mt-ugd__title">
                  {t("page.product.guide.theRingDetail.title")}
                </div>
                <div
                  className="mt-ugd__description"
                  dangerouslySetInnerHTML={{
                    __html: t("page.product.guide.theRingDetail.content"),
                  }}
                ></div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="h-auto">
            <div className="mt-ugd__ur-imgs h-full">
              <GuideImage
                className="ur-img"
                title={t("page.product.guide.theRingDetail.forAccessKey")}
                src={RingFunc2}
                // description={t("page.guide.forAccessKeyDesc")}
              />
              <GuideImage
                title={t("page.product.guide.theRingDetail.forPayment")}
                src={RingFunc3}
                // description={t("page.guide.forPaymentDesc")}
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
