import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import PolicyContent, {
  PolicyContentData,
  generateList,
  getEmailLink,
} from "./PolicyContent";

export default function WarrantyContent() {
  const { t } = useTranslation();

  const tableContent: PolicyContentData = useMemo(() => {
    return {
      hideTableContent: true,
      content: {
        covered: {
          items: [
            {
              title: t("page.warranty.limited.title"),
              content: [t("page.warranty.limited.content.1")],
            },
            {
              title: t("page.warranty.coverage.title"),
              content: [t("page.warranty.coverage.content.1")],
            },
            {
              title: t("page.warranty.exclusions.title"),
              content: [
                t("page.warranty.exclusions.content.1"),
                generateList([
                  t("page.warranty.exclusions.content.2"),
                  t("page.warranty.exclusions.content.3"),
                  t("page.warranty.exclusions.content.4"),
                  t("page.warranty.exclusions.content.5"),
                ]),
              ],
            },
            {
              title: t("page.warranty.process.title"),
              content: [
                t("page.warranty.process.content.1", {
                  email: getEmailLink("contact"),
                }),
              ],
            },
            {
              title: t("page.warranty.repaired.title"),
              content: [
                t("page.warranty.repaired.content.1"),
                t("page.warranty.repaired.content.2"),
              ],
            },
            {
              title: t("page.warranty.liability.title"),
              content: [t("page.warranty.liability.content.1")],
            },
            {
              title: t("page.warranty.contact.title"),
              content: [
                t("page.warranty.contact.content.1", {
                  email: getEmailLink("contact"),
                }),
                t("page.warranty.contact.content.2"),
              ],
            },
          ],
        },
      },
    };
  }, [t]);

  return <PolicyContent data={tableContent} />;
}
