import { Helmet } from "react-helmet-async";

import { WEB_TITLE } from "@app-constants/common";

export const SEO = ({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) => {
  const defaultTitle = WEB_TITLE;
  return (
    <Helmet>
      <title>{title ? `${title} - ${defaultTitle}` : defaultTitle}</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
};
