import { message } from "antd";
import { useAtomValue } from "jotai";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";

import { LocalStorageKeys } from "../constants/common";
import { isLoggedAtom, useAuthWriteOnly } from "../jotai/auth";
import { useUserWriteOnly } from "../jotai/user";

export default function useAuth() {
  const isLogged = useAtomValue(isLoggedAtom);
  const { onSignout, setToken } = useAuthWriteOnly();
  const { getProfile } = useUserWriteOnly();
  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLogged) {
      getProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  const checkAuth = () => {
    const token = window.localStorage.getItem(LocalStorageKeys.TOKEN);
    if (!token) return;

    try {
      const decodedToken = jwtDecode(token) as any;

      const time = new Date().getTime();

      if (!decodedToken?.exp || time > decodedToken?.exp * 1000) {
        onSignout();
        return;
      }

      setToken(token);
    } catch (error: any) {
      message.error(error);
    }
  };
}
