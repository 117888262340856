import axios from "axios";

export const baseApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
  timeout: 15000,
});

export const updateHeaderToken = (token: string) => {
  baseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const SUPPORT_LANGUAGES = ["en", "vi"];

export const updateHeaderLangauge = (lang: string) => {
  baseApi.defaults.headers.common["accept-language"] =
    SUPPORT_LANGUAGES.indexOf(lang) === -1 ? "en" : lang;
};
