import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import qs from "query-string";
import { isLoggedAtom } from "../../jotai/auth";
import { LocalStorageKeys } from "../../constants/common";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../components/layout/MainLayout";

export default function PrivatePage({ children }: { children: any }) {
  const isLogged = useAtomValue(isLoggedAtom);
  const [isValid, setIsValid] = useState(isLogged);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  const checkAuth = () => {
    const token = window.localStorage.getItem(LocalStorageKeys.TOKEN);
    if (!token) {
      const redirect = location.pathname;
      navigate(`/login?${qs.stringify({ redirect })}`);
      return;
    }
    setIsValid(true);
  };

  if (!isValid) return null;

  return <MainLayout>{children}</MainLayout>;
}
