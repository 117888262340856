import { Alert, message } from "antd";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import validator from "validator";

import EmailAnimation from "@app-assets/lotties/email-verification.json";
import PasswordValidator from "@app-components/PasswordValidator";
import StyledButton from "@app-components/button/StyledButton";
import MTInput from "@app-components/input/MTInput";
import MTPasswordInput from "@app-components/input/MTPasswordInput";
import passwordServices from "@app-services/passwordServices";
import useService from "@app-services/shared/use_service";
import { formatPhoneNumber } from "@app-utils/number";
import { PATHS } from "@app-utils/routes";

const useForgotPassword = () => useService(passwordServices.forgot);

const usePhoneResetPassword = () => useService(passwordServices.resetByPhone);

export default function PasswordForget() {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [invalidMessage, setInvalidMessage] = useState("");

  const [resetPasswordData, setResetPasswordData] = useState({
    password: "",
    confirmPassword: "",
    token: "",
  });

  const [payload, onForgot, clearService] = useForgotPassword();

  const [resetPayload, onReset, clearResetPassword] = usePhoneResetPassword();

  useEffect(() => {
    return () => {
      clearService();
      clearResetPassword();
    };
  }, [clearResetPassword, clearService]);

  useEffect(() => {
    const msg = getInvalidMessage(username);
    setInvalidMessage(msg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const validPhone = validator.isMobilePhone(username, "vi-VN");
  const validEmail = validator.isEmail(username);
  const isStrongPassword = validator.isStrongPassword(
    resetPasswordData.password,
    {
      minSymbols: 1,
      minUppercase: 1,
      minLength: 8,
      minLowercase: 1,
      minNumbers: 1,
    }
  );

  const isNumber = (value: string): boolean => {
    return new RegExp(/^\d+$/).test(value);
  };

  const isDisabledResetPassword = () =>
    !resetPasswordData.password ||
    !resetPasswordData.token ||
    !isStrongPassword ||
    !!getInvalidConfirmPasswordMessage();

  const getInvalidMessage = (value: string): string => {
    const isNum = isNumber(value);
    const invalidPhone =
      value && value?.length >= 10 && !validPhone && isNum
        ? t("content.invalidPhone")
        : "";
    const invalidEmail =
      value && value?.length >= 10 && !validEmail
        ? t("content.invalidEmail")
        : "";
    const message =
      validEmail || validPhone ? "" : invalidPhone || invalidEmail;
    return message;
  };

  const getInvalidConfirmPasswordMessage = () =>
    resetPasswordData.confirmPassword.length >= 8 &&
    !!resetPasswordData.password &&
    resetPasswordData.password !== resetPasswordData.confirmPassword
      ? t("content.invalidConfirmPassword")
      : "";

  const handleReset = () => {
    onReset({
      username: formatPhoneNumber(username),
      token: resetPasswordData.token,
      password: resetPasswordData.password,
    });
  };

  const handleConfirm = () => {
    if (!!invalidMessage) {
      message.error(invalidMessage);
      return;
    }
    const formattedPhone = isNumber(username)
      ? formatPhoneNumber(username)
      : username;
    onForgot(formattedPhone);
  };
  const renderContent = () => {
    if (resetPayload.success) {
      return (
        <div>
          <div className="text-center">
            <Link to={PATHS.login}>
              <StyledButton className="outline">
                <span className="mt-text-button-xl py-[13px]">
                  {t("button.login")}
                </span>
              </StyledButton>
            </Link>
          </div>
        </div>
      );
    } else if (validPhone && !invalidMessage && payload.success) {
      return (
        <div className="min-w-[360px] lg:min-w-[464px]">
          <div className="input relative pb-10 flex flex-col gap-[16px]">
            <MTPasswordInput
              label={t("input.newPassword.label")}
              value={resetPasswordData.password}
              onChangeText={(value) =>
                setResetPasswordData((prev) => ({
                  ...prev,
                  password: value,
                }))
              }
            />
            <PasswordValidator password={resetPasswordData.password} />
          </div>
          <div className="input relative pb-10">
            <MTPasswordInput
              label={t("input.confirmPassword.label")}
              value={resetPasswordData.confirmPassword}
              onChangeText={(value) =>
                setResetPasswordData((prev) => ({
                  ...prev,
                  confirmPassword: value,
                }))
              }
              error={getInvalidConfirmPasswordMessage()}
            />
          </div>
          <div className="input relative pb-6">
            <MTInput
              label={t("input.otp.label")}
              value={resetPasswordData.token}
              onChangeText={(value) =>
                setResetPasswordData((prev) => ({
                  ...prev,
                  token: value,
                }))
              }
            />
          </div>
          {resetPayload?.error && (
            <div className="pb-6 text-left">
              <Alert message={t(resetPayload.error)} type="error" showIcon />
            </div>
          )}
          <div className="text-center">
            <StyledButton
              className="outline w-full"
              onClick={handleReset}
              disabled={isDisabledResetPassword()}
              loading={resetPayload.loading}
            >
              <span className="mt-text-button-xl py-[13px]">
                {t("button.resetPassword")}
              </span>
            </StyledButton>
          </div>
        </div>
      );
    } else if (validEmail && !invalidMessage && payload.success) {
      return (
        <div>
          <div className="container flex items-center justify-center flex-col">
            <Lottie
              className="h-[300px] mb-[-50px]"
              animationData={EmailAnimation}
              loop
            />
            <div className="text-center relative z-10">
              <div>
                <div className="mb-5">{t('content.checkYourEmail')}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="min-w-[360px] lg:min-w-[464px]">
        <div className="input relative pb-6">
          <MTInput
            label={t("input.resetPassword.label")}
            value={username}
            onChangeText={(value) => setUsername(value)}
            error={invalidMessage}
          />
        </div>

        {payload?.error && (
          <div className="pb-6 text-left">
            <Alert message={t(payload.error)} type="error" showIcon />
          </div>
        )}
        <div className="text-center">
          <StyledButton
            className="outline w-full"
            onClick={handleConfirm}
            disabled={!username || username.length < 10 || !!invalidMessage}
            // loading={signinPayload.loading}
          >
            <span className="mt-text-button-xl py-[13px]">
              {t("button.confirm")}
            </span>
          </StyledButton>
        </div>
      </div>
    );
  };

  return (
    <div className="auth-page container">
      <div className="flex items-center justify-center">
        <div className="form">
          <div className="auth-page__title">
            {resetPayload?.success
              ? t("title.resetPasswordSuccess")
              : t("title.resetPassword")}
          </div>
          {renderContent()}
        </div>
      </div>
    </div>
  );
}
