import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import PolicyContent, {
  PolicyContentData,
  generateList,
} from "./PolicyContent";

export default function PrivacyContent() {
  const { t } = useTranslation();

  const tableContent: PolicyContentData = useMemo(() => {
    const content = {
      general: {
        title: t("page.privacy.general.title"),
        items: [
          {
            content: [
              <div>
                a) <strong>{t("page.privacy.general.content.1.title")}</strong>
                <span>: {t("page.privacy.general.content.1.content")}</span>
              </div>,
              <div>
                b) <strong>{t("page.privacy.general.content.2.title")}</strong>
                <span>: {t("page.privacy.general.content.2.content")}</span>
              </div>,
              t("page.privacy.general.content.3"),
              t("page.privacy.general.content.4"),
              t("page.privacy.general.content.5"),
            ],
          },
        ],
      },
      dataType: {
        title: t("page.privacy.dataType.title"),
        items: [
          {
            content: [
              t("page.privacy.dataType.content.2.title"),
              generateList(
                [
                  t("page.privacy.dataType.content.2.content.a"),
                  t("page.privacy.dataType.content.2.content.b"),
                  t("page.privacy.dataType.content.2.content.c"),
                  t("page.privacy.dataType.content.2.content.d"),
                  t("page.privacy.dataType.content.2.content.e"),
                  t("page.privacy.dataType.content.2.content.f"),
                  t("page.privacy.dataType.content.2.content.g"),
                  t("page.privacy.dataType.content.2.content.h"),
                  t("page.privacy.dataType.content.2.content.i"),
                  t("page.privacy.dataType.content.2.content.j"),
                  t("page.privacy.dataType.content.2.content.k"),
                  t("page.privacy.dataType.content.2.content.l"),
                  t("page.privacy.dataType.content.2.content.m"),
                ],
                "ml-6 pt-2",
                " "
              ),
              t("page.privacy.dataType.content.3.title"),
              generateList(
                [
                  t("page.privacy.dataType.content.3.content.a"),
                  t("page.privacy.dataType.content.3.content.b"),
                  t("page.privacy.dataType.content.3.content.c"),
                  t("page.privacy.dataType.content.3.content.d"),
                ],
                "ml-6 pt-2",
                " "
              ),
            ],
          },
        ],
      },
      purposesAndMethodes: {
        title: t("page.privacy.purposesAndMethodes.title"),
        items: [
          {
            content: [
              t("page.privacy.purposesAndMethodes.content.1.title"),
              generateList(
                [
                  t("page.privacy.purposesAndMethodes.content.1.a"),
                  t("page.privacy.purposesAndMethodes.content.1.b"),
                  t("page.privacy.purposesAndMethodes.content.1.c"),
                  t("page.privacy.purposesAndMethodes.content.1.d"),
                  t("page.privacy.purposesAndMethodes.content.1.e"),
                  t("page.privacy.purposesAndMethodes.content.1.f"),
                  t("page.privacy.purposesAndMethodes.content.1.g"),
                  t("page.privacy.purposesAndMethodes.content.1.h"),
                  t("page.privacy.purposesAndMethodes.content.1.i"),
                  t("page.privacy.purposesAndMethodes.content.1.j"),
                  t("page.privacy.purposesAndMethodes.content.1.k"),
                  t("page.privacy.purposesAndMethodes.content.1.l"),
                  t("page.privacy.purposesAndMethodes.content.1.m"),
                  t("page.privacy.purposesAndMethodes.content.1.n"),
                  t("page.privacy.purposesAndMethodes.content.1.o"),
                  t("page.privacy.purposesAndMethodes.content.1.p"),
                  t("page.privacy.purposesAndMethodes.content.1.q"),
                  t("page.privacy.purposesAndMethodes.content.1.r"),
                  t("page.privacy.purposesAndMethodes.content.1.s"),
                  t("page.privacy.purposesAndMethodes.content.1.t"),
                  t("page.privacy.purposesAndMethodes.content.1.u"),
                  t("page.privacy.purposesAndMethodes.content.1.v"),
                  t("page.privacy.purposesAndMethodes.content.1.w"),
                  t("page.privacy.purposesAndMethodes.content.1.x"),
                  t("page.privacy.purposesAndMethodes.content.1.y"),
                  t("page.privacy.purposesAndMethodes.content.1.z"),
                ],
                "ml-6 pt-2",
                " "
              ),
              t("page.privacy.purposesAndMethodes.content.2.title"),
              t("page.privacy.purposesAndMethodes.content.3.title"),
              generateList(
                [
                  t("page.privacy.purposesAndMethodes.content.3.a"),
                  t("page.privacy.purposesAndMethodes.content.3.b"),
                  t("page.privacy.purposesAndMethodes.content.3.c"),
                  t("page.privacy.purposesAndMethodes.content.3.d"),
                  t("page.privacy.purposesAndMethodes.content.3.e"),
                  t("page.privacy.purposesAndMethodes.content.3.f"),
                ],
                "ml-6 pt-2",
                " "
              ),

              t("page.privacy.purposesAndMethodes.content.4.title"),
              t("page.privacy.purposesAndMethodes.content.5.title"),
              t("page.privacy.purposesAndMethodes.content.6.title"),
              t("page.privacy.purposesAndMethodes.content.7.title"),
              generateList(
                [
                  t("page.privacy.purposesAndMethodes.content.7.a"),
                  t("page.privacy.purposesAndMethodes.content.7.b"),
                  t("page.privacy.purposesAndMethodes.content.7.c"),
                  t("page.privacy.purposesAndMethodes.content.7.d"),
                  t("page.privacy.purposesAndMethodes.content.7.e"),
                ],
                "ml-6 pt-2",
                " "
              ),
            ],
          },
        ],
      },
      transfer: {
        title: t("page.privacy.transfers.title"),
        items: [
          {
            content: [t("page.privacy.transfer.content")],
          },
        ],
      },
      rightOfSubject: {
        title: t("page.privacy.rightOfSubject.title"),
        items: [
          {
            content: [
              t("page.privacy.rightOfSubject.content.1.title"),
              generateList(
                [
                  t("page.privacy.rightOfSubject.content.1.content.a"),
                  t("page.privacy.rightOfSubject.content.1.content.b"),
                  t("page.privacy.rightOfSubject.content.1.content.c"),
                  t("page.privacy.rightOfSubject.content.1.content.d"),
                  t("page.privacy.rightOfSubject.content.1.content.e"),
                  t("page.privacy.rightOfSubject.content.1.content.f"),
                  t("page.privacy.rightOfSubject.content.1.content.g"),
                  t("page.privacy.rightOfSubject.content.1.content.h"),
                  t("page.privacy.rightOfSubject.content.1.content.i"),
                  t("page.privacy.rightOfSubject.content.1.content.j"),
                  t("page.privacy.rightOfSubject.content.1.content.k"),
                ],
                "ml-6 pt-2",
                " "
              ),
              t("page.privacy.rightOfSubject.content.2.title"),
              t("page.privacy.rightOfSubject.content.3.title"),
              t("page.privacy.rightOfSubject.content.4.title"),
            ],
          },
        ],
      },
      obligation: {
        title: t("page.privacy.obligation.title"),
        items: [
          {
            content: [
              t("page.privacy.obligation.content.1.title"),
              t("page.privacy.obligation.content.2.title"),
              t("page.privacy.obligation.content.3.title"),
              t("page.privacy.obligation.content.4.title"),
              t("page.privacy.obligation.content.5.title"),
              t("page.privacy.obligation.content.6.title"),
              t("page.privacy.obligation.content.7.title"),
              t("page.privacy.obligation.content.8.title"),
            ],
          },
        ],
      },
      storage: {
        title: t("page.privacy.storage.title"),
        items: [
          {
            content: [t("page.privacy.storage.content.1.title")],
          },
        ],
      },
      processing: {
        title: t("page.privacy.processing.title"),
        items: [
          {
            content: [t("page.privacy.proccessing.content.1.title")],
          },
        ],
      },
      contact: {
        title: t("page.privacy.contact.title"),
        items: [
          {
            content: [
              t("page.privacy.contact.content.1.title"),
              generateList(
                [
                  t("page.privacy.contact.content.1.content.a"),
                  t("page.privacy.contact.content.2.content.b"),
                ],
                "ml-6 pt-2",
                " "
              ),
            ],
          },
        ],
      },
    };
    return { content, hideTableContent: true, hideIndex: true };
  }, [t]);

  return <PolicyContent data={tableContent} />;
}
