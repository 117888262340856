import { useState } from "react";
import {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import MemberInfoCard from "./MemberInfoCard";

interface IMemberData {
  name: string;
  role: string;
  image: string;
  desc: string;
}
const SPEED = 800;

export default function MemberCarousel({
  members,
}: {
  members: IMemberData[];
}) {
  const [swiper, setSwiper] = useState<SwiperClass>();

  const handleOnChangeSlide = (slide: SwiperClass) => {
    if (slide.activeIndex > slide.clickedIndex) {
      swiper?.slidePrev(SPEED);
    } else if (slide.activeIndex < slide.clickedIndex) {
      swiper?.slideNext(SPEED);
    }
  };

  return (
    <Swiper
      onSwiper={setSwiper}
      loop={true}
      modules={[Navigation, Autoplay, EffectCoverflow, Pagination]}
      onClick={handleOnChangeSlide}
      onInit={(e: any) => {
        e?.slideNext(SPEED);
      }}
      slidesPerView="auto"
      centeredSlides={true}
      spaceBetween={10}
      effect="coverflow"
      grabCursor={true}
      autoplay={{
        delay: 15000,
        disableOnInteraction: false,
      }}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 300,
        modifier: 1,
        slideShadows: false,
      }}
      breakpoints={{
        768: {
          slidesPerView: "auto",
          effect: "coverflow",
          pagination: false,
        },
        300: {
          pagination: {
            clickable: true,
          },
        },
      }}
      pagination={true}
    >
      {members.map((item, index) => (
        <SwiperSlide key={index}>
          <MemberInfoCard {...item} key={index} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
