import classNames from "classnames";

export default function GuideImage({
  title,
  src,
  description,
  className,
}: {
  title?: string;
  src?: string;
  description?: string;
  className?: string;
}) {
  return (
    <div className={classNames("mt-ugd__card", className)}>
      <div className="mt-ugd__card-title">{title}</div>
      <div className="mt-ugd__card-img">
        <img src={src} alt={title} />
      </div>
      <div className="mt-ugd__card-desc">{description}</div>
    </div>
  );
}
