import { AGENCY_GOOGLE_FORM } from "@app-constants/common";
import { throwErrorMessage } from "@app-utils/errorHandler";

const registerAgency = async (agencyData: string): Promise<any> => {
  try {
    const result = await fetch(
      AGENCY_GOOGLE_FORM.url + "?submit=Submit&" + agencyData,
      {
        method: "GET",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return result;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const agencyServices = {
  registerAgency,
};

export default agencyServices;
