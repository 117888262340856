import { Button } from "antd";
import classNames from "classnames";
import React from "react";

export default function StyledButton({
  children,
  className,
  onClick,
  disabled,
  loading,
}: {
  children: React.ReactDOM | JSX.Element | any;
  className?: string | string[];
  onClick?:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLButtonElement>)
    | undefined;
  disabled?: boolean;
  loading?: boolean;
}) {
  return (
    <Button
      className={classNames("mt-button", className, { disabled })}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    >
      {children}
    </Button>
  );
  // return (
  //   <button className={classNames("mt-button", className)} onClick={onClick}>
  //     {children}
  //   </button>
  // );
}
