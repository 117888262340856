import { atom, useSetAtom } from "jotai";
import { sendOtpServiceAtom } from "./api_atoms";
import { sendOtpPayloadAtom } from "./atoms";
import { INIT_PAYLOAD } from "../constants";

const clearSendOtpPayloadAtom = atom(null, (_, set) => {
  set(sendOtpPayloadAtom, INIT_PAYLOAD);
});

const useSmsWriteOnly = () => {
  const onSendOtp = useSetAtom(sendOtpServiceAtom);

  const onClearSendOtpPayload = useSetAtom(clearSendOtpPayloadAtom);

  return {
    onSendOtp,
    onClearSendOtpPayload,
  };
};

export { useSmsWriteOnly };
