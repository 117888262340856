import { PlusCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import MTRing from "@app-assets/images/mt-ring.png";
import MTRingFront from "@app-assets/images/ring-front.png";
import MTRingUp from "@app-assets/images/ring-up.png";

import "./styles.scss";

export enum ProductDetailType {
  ResilientElegance = "ResilientElegance",
  UltimateComfort = "UltimateComfort",
  SeamlessConnectivity = "SeamlessConnectivity",
  Specifications = "Specifications",
}

const productDetailTypeContent: any = {
  [ProductDetailType.ResilientElegance]: {
    image: MTRingFront,
    title: "page.product.resilientElegance.title",
    subtitle: "page.product.resilientElegance.subtitle",
    content: "page.product.resilientElegance.content",
  },
  [ProductDetailType.UltimateComfort]: {
    image: MTRing,
    title: "page.product.ultimateComfort.title",
    subtitle: "page.product.ultimateComfort.subtitle",
    content: "page.product.ultimateComfort.content",
  },
  [ProductDetailType.SeamlessConnectivity]: {
    image: MTRingUp,
    title: "page.product.seamlessConnectivity.title",
    subtitle: "page.product.seamlessConnectivity.subtitle",
    content: "page.product.seamlessConnectivity.content",
  },
  [ProductDetailType.Specifications]: {
    title: "page.product.specifications.title",
    subtitle: "page.product.specifications.subtitle",
    content: "page.product.specifications.content",
    subcontent: [
      [
        "page.product.specifications.subcontent.1",
        "page.product.specifications.subcontent.2",
        "page.product.specifications.subcontent.3",
        "page.product.specifications.subcontent.4",
        "page.product.specifications.subcontent.5",
        "page.product.specifications.subcontent.6",
      ],
      [
        "page.product.specifications.subcontent.7",
        // "page.product.specifications.subcontent.8",
        "page.product.specifications.subcontent.9",
        "page.product.specifications.subcontent.10",
        "page.product.specifications.subcontent.11",
        "page.product.specifications.subcontent.12",
        "page.product.specifications.subcontent.13",
        "page.product.specifications.subcontent.14",
      ],
    ],
  },
};

export default function DetailCard({ type }: { type: ProductDetailType }) {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const contentModal = useMemo(() => {
    const content = productDetailTypeContent[type];
    return (
      <div className="product-detail-card-modal">
        {content?.image && (
          <div className="product-detail-card_img">
            <img src={content.image} alt="" />
          </div>
        )}
        <div className="mt-heading-xl">
          {t(productDetailTypeContent[type].title)}
        </div>
        <div className="mt-text-body-xl">
          {t(productDetailTypeContent[type].content)}
        </div>
        {content.subcontent && (
          <div className="product-detail-card__subcontent mt-text-body-lg">
            {content.subcontent.map((items: string[], index: number) => (
              <ul className="product-detail-card__subcontent-item" key={index}>
                {items.map((item: string) => (
                  <li key={item}>{t(item)}</li>
                ))}
              </ul>
            ))}
          </div>
        )}
      </div>
    );
  }, [t, type]);

  return (
    <>
      <div className="product-detail-card">
        <div className="mt-heading-md">
          {t(productDetailTypeContent[type]?.title)}
        </div>
        <div className="mt-text-body-xl">
          {t(productDetailTypeContent[type]?.subtitle)}
        </div>
        <div className="plus-icon" onClick={() => setToggle(true)}>
          <PlusCircleFilled />
        </div>
      </div>
      <Modal
        centered
        open={!!toggle}
        footer={null}
        onCancel={() => setToggle(false)}
        bodyStyle={{
          maxWidth: "1000px",
          padding: 20,
        }}
        style={{ width: "auto", padding: 20 }}
        className="modal-w-auto"
      >
        {contentModal}
      </Modal>
    </>
  );
}
