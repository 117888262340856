export default function UserSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6006 11.5H11.5706C9.0006 11.41 6.9906 9.33 6.9906 6.76C6.9906 4.19 9.1306 2 11.7506 2C14.3706 2 16.5006 4.13 16.5006 6.76C16.4906 9.33 14.4806 11.42 11.9206 11.5C11.8806 11.5 11.8306 11.5 11.7906 11.5C11.7806 11.5 11.7306 11.5 11.6906 11.5C11.6606 11.5 11.6306 11.5 11.6006 11.5ZM11.7506 3.5C9.9606 3.5 8.5006 4.96 8.5006 6.76C8.5006 8.56 9.8506 9.92 11.5806 10C11.6906 9.99 11.8106 9.99 11.9206 10C13.6406 9.91 14.9906 8.5 15.0006 6.75C15.0006 4.96 13.5406 3.5 11.7506 3.5Z"
        fill="#111318"
      />
      <path
        d="M20.0006 21.5H3.50061C3.04061 21.5 2.63061 21.31 2.34061 20.97C2.05061 20.62 1.94061 20.14 2.03061 19.67C2.92061 15.09 7.69061 13 11.7506 13C15.8106 13 20.5806 15.09 21.4706 19.67C21.5606 20.14 21.4506 20.62 21.1606 20.97C20.8806 21.31 20.4706 21.5 20.0006 21.5ZM11.7506 14.5C8.29061 14.5 4.23061 16.21 3.50061 19.96L20.0006 20C19.2706 16.21 15.2106 14.5 11.7506 14.5Z"
        fill="#111318"
      />
    </svg>
  );
}
