import { RightOutlined } from "@ant-design/icons";

export interface BreadcrumbItem {
  path: string;
  title: string;
}

export default function MTBreadcrumb({ items }: { items: BreadcrumbItem[] }) {
  return (
    <div className="mt-breadcrumb">
      {items.map((item: BreadcrumbItem, index: number) => (
        <div key={item.title}>
          <a href={item.path} className="mt-breadcrumb-path">
            {item.title}
          </a>
          {index !== items.length - 1 && (
            <RightOutlined className="mt-breadcrumb-separator" />
          )}
        </div>
      ))}
    </div>
  );
}
