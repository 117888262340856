export default function EyeSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M18.0006 22.5007C20.4863 22.5007 22.5013 20.4857 22.5013 18.0001C22.5013 15.5145 20.4863 13.4995 18.0006 13.4995C15.515 13.4995 13.5 15.5145 13.5 18.0001C13.5 20.4857 15.515 22.5007 18.0006 22.5007Z"
        fill="#111318"
      />
      <path
        d="M34.5136 16.7759C32.6531 13.8987 30.2393 11.4546 27.5337 9.70738C24.5405 7.77238 21.2358 6.74933 17.9775 6.74933C14.9878 6.74933 12.048 7.60363 9.23976 9.28831C6.37593 11.006 3.7814 13.5155 1.52789 16.7464C1.27349 17.1115 1.13338 17.544 1.12536 17.989C1.11735 18.4339 1.2418 18.8712 1.48289 19.2453C3.33984 22.1513 5.72976 24.5989 8.3932 26.3222C11.392 28.265 14.6194 29.2493 17.9775 29.2493C21.2618 29.2493 24.5735 28.2347 27.5541 26.3159C30.2583 24.5743 32.6672 22.121 34.5206 19.22C34.7534 18.8546 34.8765 18.4301 34.8752 17.9969C34.874 17.5637 34.7485 17.1399 34.5136 16.7759ZM18.0014 24.7493C16.6664 24.7493 15.3613 24.3535 14.2513 23.6118C13.1413 22.8701 12.2761 21.8158 11.7652 20.5824C11.2543 19.349 11.1207 17.9918 11.3811 16.6825C11.6416 15.3731 12.2844 14.1704 13.2284 13.2264C14.1724 12.2824 15.3752 11.6395 16.6845 11.379C17.9939 11.1186 19.3511 11.2523 20.5845 11.7631C21.8179 12.274 22.8721 13.1392 23.6138 14.2492C24.3555 15.3593 24.7514 16.6643 24.7514 17.9993C24.7494 19.7889 24.0375 21.5046 22.7721 22.77C21.5067 24.0355 19.791 24.7473 18.0014 24.7493Z"
        fill="#111318"
      />
    </svg>
  );
}
