export default function UserSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="248"
      height="229"
      viewBox="0 0 248 229"
      fill="none"
    >
      <path
        d="M122.283 122.227C106.644 121.834 91.7832 115.384 80.8883 104.262C69.9933 93.14 63.9315 78.2312 64.0028 62.7331C63.6292 54.6652 64.9107 46.606 67.7697 39.0426C70.6287 31.4792 75.006 24.5685 80.6369 18.7283C86.2678 12.888 93.0355 8.23947 100.531 5.06347C108.027 1.88748 116.094 0.25 124.246 0.25C132.399 0.25 140.466 1.88748 147.962 5.06347C155.457 8.23947 162.225 12.888 167.856 18.7283C173.487 24.5685 177.864 31.4792 180.723 39.0426C183.582 46.606 184.864 54.6652 184.49 62.7331C184.531 78.1795 178.493 93.0335 167.659 104.142C156.825 115.25 142.048 121.738 126.464 122.227H122.283Z"
        fill="#111318"
        fill-opacity="0.6"
      />
      <path
        d="M228.707 247.743H19.6595C16.8965 247.818 14.1501 247.296 11.6119 246.212C9.07357 245.128 6.80449 243.509 4.96285 241.467C3.01555 239.189 1.61596 236.503 0.869743 233.611C0.123521 230.72 0.0501621 227.698 0.655204 224.773C12.3112 167.287 72.7449 141.055 124.183 141.055C175.622 141.055 236.055 167.287 247.331 224.773C247.929 227.612 247.886 230.546 247.207 233.367C246.529 236.187 245.23 238.824 243.404 241.09C241.616 243.222 239.366 244.926 236.821 246.078C234.277 247.229 231.504 247.799 228.707 247.743Z"
        fill="#111318"
        fill-opacity="0.6"
      />
    </svg>
  );
}
