export default function StarSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <g clipPath="url(#clip0_977_6150)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.013 3.75034C18.2085 3.74791 18.4006 3.80185 18.5663 3.90571C18.7319 4.00957 18.8642 4.15895 18.9472 4.33599L23.0189 12.577L32.1105 13.8599C32.3032 13.889 32.4839 13.9714 32.6322 14.0977C32.7806 14.224 32.8907 14.3893 32.9502 14.5749C33.0096 14.7605 33.016 14.959 32.9687 15.148C32.9213 15.337 32.8221 15.5091 32.6822 15.6447L26.1006 22.0869L27.6484 31.1367C27.6815 31.3293 27.66 31.5273 27.5863 31.7083C27.5126 31.8893 27.3897 32.046 27.2314 32.1607C27.0732 32.2754 26.886 32.3435 26.691 32.3572C26.4961 32.3709 26.3012 32.3297 26.1285 32.2383L18.013 27.9714L9.89746 32.2383C9.72473 32.3297 9.52984 32.3709 9.33489 32.3572C9.13994 32.3435 8.95273 32.2754 8.79449 32.1607C8.63625 32.046 8.51332 31.8893 8.43962 31.7083C8.36592 31.5273 8.34442 31.3293 8.37755 31.1367L9.92535 22.0869L3.3437 15.6726C3.19539 15.5383 3.08874 15.3642 3.0364 15.171C2.98407 14.9779 2.98824 14.7738 3.04843 14.5829C3.10862 14.3921 3.22229 14.2225 3.37597 14.0944C3.52965 13.9662 3.71687 13.8848 3.91541 13.8599L13.007 12.5491L17.0787 4.3081C17.1657 4.13627 17.2997 3.99264 17.4651 3.89391C17.6304 3.79518 17.8204 3.74539 18.013 3.75034Z"
          fill="#111318"
        />
      </g>
      <defs>
        <clipPath id="clip0_977_6150">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
