import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  DISPLAY_PHONE_NUMBER,
  EMAIL,
  PHONE_NUMBER,
} from "@app-constants/common";

import PolicyContent, {
  PolicyContentData,
  generateLink,
  generateList,
} from "./PolicyContent";

export default function TermsContent() {
  const { t } = useTranslation();

  const prohibitedLink = generateLink({
    type: "internal",
    href: "prohibited-activities",
    classNames: "underline font-medium",
    alias: t("page.terms.prohibited.title"),
  });

  const tableContent: PolicyContentData = useMemo(() => {
    const content = {
      ourServices: {
        title: t("page.terms.ourServices.title"),
        href: "services",
        items: [
          {
            content: [t("page.terms.ourServices.content")],
          },
        ],
      },
      ipRights: {
        title: t("page.terms.ipRights.title"),
        href: "intellectual-property-rights",
        items: [
          {
            title: t("page.terms.ipRights.ourIP.title"),
            content: [
              t("page.terms.ipRights.ourIP.content.1"),
              t("page.terms.ipRights.ourIP.content.2"),
              t("page.terms.ipRights.ourIP.content.3"),
            ],
          },
          {
            title: t("page.terms.ipRights.yourUseOfOurServices.title"),
            content: [
              t("page.terms.ipRights.yourUseOfOurServices.content.1", {
                prohibitedLink,
              }),
              generateList([
                t("page.terms.ipRights.yourUseOfOurServices.li.1"),
                t("page.terms.ipRights.yourUseOfOurServices.li.2"),
              ]),
              t("page.terms.ipRights.yourUseOfOurServices.content.6"),
              t("page.terms.ipRights.yourUseOfOurServices.content.2"),
              t("page.terms.ipRights.yourUseOfOurServices.content.3"),
              t("page.terms.ipRights.yourUseOfOurServices.content.4"),
              t("page.terms.ipRights.yourUseOfOurServices.content.5"),
            ],
          },
          {
            title: t("page.terms.ipRights.yourSubmissions.title"),
            content: [
              t("page.terms.ipRights.yourSubmissions.content.1", {
                prohibitedLink,
              }),
              t("page.terms.ipRights.yourSubmissions.content.2"),
              t("page.terms.ipRights.yourSubmissions.content.3"),
              generateList([
                t("page.terms.ipRights.li.1", {
                  prohibitedLink,
                }),
                t("page.terms.ipRights.li.2"),
                t("page.terms.ipRights.li.3"),
                t("page.terms.ipRights.li.4"),
              ]),
              t("page.terms.ipRights.yourSubmissions.content.4"),
            ],
          },
        ],
      },
      userPresentations: {
        title: t("page.terms.userPresentations.title"),
        href: "user-presentations",
        items: [
          {
            content: [
              t("page.terms.userPresentations.content.1"),
              t("page.terms.userPresentations.content.2"),
            ],
          },
        ],
      },
      userRegistration: {
        title: t("page.terms.userRegistration.title"),
        href: "user-registration",
        items: [
          {
            content: [t("page.terms.userRegistration.content")],
          },
        ],
      },
      products: {
        title: t("page.terms.products.title"),
        href: "products",
        items: [
          {
            content: [t("page.terms.products.content")],
          },
        ],
      },
      // purchasesAndPayment: {
      //   title: t("page.terms.purchasesAndPayment.title"),
      //   href: "purchases-and-payment",
      //   items: [
      //     {
      //       content: [
      //         t("page.terms.purchasesAndPayment.paymentMethod.title"),
      //         generateList([
      //           t("page.terms.purchasesAndPayment.paymentMethod.visa"),
      //           t("page.terms.purchasesAndPayment.paymentMethod.mastercard"),
      //           t("page.terms.purchasesAndPayment.paymentMethod.americanExpress"),
      //           t("page.terms.purchasesAndPayment.paymentMethod.discover"),
      //           t("page.terms.purchasesAndPayment.paymentMethod.paypal"),
      //           t("page.terms.purchasesAndPayment.paymentMethod.bankTransfer"),
      //         ]),
      //         t("page.terms.purchasesAndPayment.content.1"),
      //         t("page.terms.purchasesAndPayment.content.2"),
      //         t("page.terms.purchasesAndPayment.content.3"),
      //       ],
      //     },
      //   ],
      // },
      // returnPolicy: {
      //   title: t("page.terms.returnPolicy.title"),
      //   href: "return-policy",
      //   items: [
      //     {
      //       content: [t("page.terms.returnPolicy.content")],
      //     },
      //   ],
      // },
      prohibited: {
        title: t("page.terms.prohibited.title"),
        href: "prohibited-activities",
        items: [
          {
            content: [
              t("page.terms.prohibited.conntent"),
              t("page.terms.prohibited.listTitle"),
              generateList([
                t("page.terms.prohibited.li.1"),
                t("page.terms.prohibited.li.2"),
                t("page.terms.prohibited.li.3"),
                t("page.terms.prohibited.li.4"),
                t("page.terms.prohibited.li.5"),
                t("page.terms.prohibited.li.6"),
                t("page.terms.prohibited.li.7"),
                t("page.terms.prohibited.li.8"),
                t("page.terms.prohibited.li.9"),
                t("page.terms.prohibited.li.10"),
                t("page.terms.prohibited.li.11"),
                t("page.terms.prohibited.li.12"),
                t("page.terms.prohibited.li.13"),
                t("page.terms.prohibited.li.14"),
                t("page.terms.prohibited.li.15"),
                t("page.terms.prohibited.li.16"),
                t("page.terms.prohibited.li.17"),
                t("page.terms.prohibited.li.18"),
                t("page.terms.prohibited.li.19"),
                t("page.terms.prohibited.li.20"),
                t("page.terms.prohibited.li.21"),
                t("page.terms.prohibited.li.22"),
                t("page.terms.prohibited.li.23"),
                t("page.terms.prohibited.li.24"),
              ]),
            ],
          },
        ],
      },
      ugc: {
        title: t("page.terms.ugc.title"),
        href: "user-generated-contributions",
        items: [
          {
            content: [
              t("page.terms.ugc.content.1"),
              generateList([
                t("page.terms.ugc.li.1"),
                t("page.terms.ugc.li.2"),
                t("page.terms.ugc.li.3"),
                t("page.terms.ugc.li.4"),
                t("page.terms.ugc.li.5"),
                t("page.terms.ugc.li.6"),
                t("page.terms.ugc.li.7"),
                t("page.terms.ugc.li.8"),
                t("page.terms.ugc.li.9"),
                t("page.terms.ugc.li.10"),
                t("page.terms.ugc.li.11"),
                t("page.terms.ugc.li.12"),
                t("page.terms.ugc.li.13"),
              ]),
              t("page.terms.ugc.content.2"),
            ],
          },
        ],
      },
      contributionLicense: {
        title: t("page.terms.contributionLicense.title"),
        href: "contribution-license",
        items: [
          {
            content: [
              t("page.terms.contributionLicense.content.1"),
              t("page.terms.contributionLicense.content.2"),
              t("page.terms.contributionLicense.content.3"),
            ],
          },
        ],
      },
      mobileAppLicense: {
        title: t("page.terms.mobileAppLicense.title"),
        href: "mobile-app-license",
        items: [
          {
            title: t("page.terms.mobileAppLicense.useLicense.title"),
            content: [t("page.terms.mobileAppLicense.useLicense.content")],
          },
          {
            title: t("page.terms.mobileAppLicense.device.title"),
            content: [t("page.terms.mobileAppLicense.device.content")],
          },
        ],
      },
      socialMedia: {
        title: t("page.terms.socialMedia.title"),
        href: "social-media",
        items: [
          {
            content: [t("page.terms.socialMedia.content")],
          },
        ],
      },
      thirdPartyWebsitesAndContent: {
        title: t("page.terms.thirdPartyWebsitesAndContent.title"),
        href: "third-party-websites-and-content",
        items: [
          {
            content: [t("page.terms.thirdPartyWebsitesAndContent.content")],
          },
        ],
      },
      servicesManagement: {
        title: t("page.terms.servicesManagement.title"),
        href: "services-management",
        items: [
          {
            content: [t("page.terms.servicesManagement.content")],
          },
        ],
      },
      // privacyPolicy: {
      //   title: t("page.terms.privacyPolicy.title"),
      //   href: "privacy-policy",
      //   items: [
      //     {
      //       content: [
      //         t("page.terms.privacyPolicy.content", {
      //           link: generateLink({
      //             type: "internal",
      //             path: PATHS.privacyPolicy,
      //             classNames: "underline",
      //           }),
      //         }),
      //       ],
      //     },
      //   ],
      // },
      termAndTermination: {
        title: t("page.terms.termAndTermination.title"),
        href: "term-and-termination",
        items: [
          {
            content: [
              t("page.terms.termAndTermination.content.1"),
              t("page.terms.termAndTermination.content.2"),
            ],
          },
        ],
      },
      modificationsAndInterruptions: {
        title: t("page.terms.modificationsAndInterruptions.title"),
        href: "modifications-and-interruptions",
        items: [
          {
            content: [
              t("page.terms.modificationsAndInterruptions.content.1"),
              t("page.terms.modificationsAndInterruptions.content.2"),
            ],
          },
        ],
      },
      governingLaw: {
        title: t("page.terms.governingLaw.title"),
        href: "governing-law",
        items: [
          {
            content: [
              t("page.terms.governingLaw.content", {
                companyName: t("contact.companyName"),
              }),
            ],
          },
        ],
      },
      disputeResolution: {
        title: t("page.terms.disputeResolution.title"),
        href: "dispute-resolution",
        items: [
          {
            title: t("page.terms.disputeResolution.informal.title"),
            content: [t("page.terms.disputeResolution.informal.content")],
          },
          {
            title: t("page.terms.disputeResolution.binding.title"),
            content: [t("page.terms.disputeResolution.binding.content")],
          },
          // {
          //   title: t("page.terms.disputeResolution.restrictions.title"),
          //   content: [t("page.terms.disputeResolution.restrictions.content")],
          // },
          {
            title: t("page.terms.disputeResolution.exceptions.title"),
            content: [t("page.terms.disputeResolution.exceptions.content")],
          },
        ],
      },
      corrections: {
        title: t("page.terms.corrections.title"),
        href: "corrections",
        items: [
          {
            content: [t("page.terms.corrections.content")],
          },
        ],
      },
      disclaimer: {
        title: t("page.terms.disclaimer.title"),
        href: "disclaimer",
        items: [
          {
            content: [t("page.terms.disclaimer.content")],
          },
        ],
      },
      limitationsOfLiability: {
        title: t("page.terms.limitationsOfLiability.title"),
        href: "limitations-of-liability",
        items: [
          {
            content: [t("page.terms.limitationsOfLiability.content")],
          },
        ],
      },
      indemnification: {
        title: t("page.terms.indemnification.title"),
        href: "indemnification",
        items: [
          {
            content: [t("page.terms.indemnification.content")],
          },
        ],
      },
      userData: {
        title: t("page.terms.userData.title"),
        href: "user-data",
        items: [
          {
            content: [t("page.terms.userData.content")],
          },
        ],
      },
      electronicCommunications: {
        title: t("page.terms.electronicCommunications.title"),
        href: "electronic-communications",
        items: [
          {
            content: [t("page.terms.electronicCommunications.content")],
          },
        ],
      },
      miscellaneous: {
        title: t("page.terms.miscellaneous.title"),
        href: "miscellaneous",
        items: [
          {
            content: [t("page.terms.miscellaneous.content")],
          },
        ],
      },
      contactUs: {
        title: t("page.terms.contactUs.title"),
        href: "contact-us",
        items: [
          {
            content: [
              t("page.terms.contactUs.content"),
              <div className="font-medium">
                <div>{t("contact.companyName")}</div>
                <div>{t("contact.companyAddressContent")}</div>
                <div>{t("contact.zipCode")}</div>
                <div>
                  <span>{t("contact.phoneNumber")}: </span>
                  <a href={`tel:${PHONE_NUMBER}`}>{DISPLAY_PHONE_NUMBER}</a>
                </div>
                <div>
                  <a href={`mailto:${EMAIL.destouches}`}>{EMAIL.destouches}</a>
                </div>
              </div>,
            ],
          },
        ],
      },
    };
    return { content };
  }, [t, prohibitedLink]);

  return <PolicyContent data={tableContent} />;
}
