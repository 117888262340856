import { throwErrorMessage } from "@app-utils/errorHandler";

import { baseApi } from "./common";

const getItems = async (): Promise<any> => {
  try {
    const response = await baseApi.get("items");
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const getPointHistory = async (id: string): Promise<any> => {
  try {
    const response = await baseApi.get(`items/${id}/point`);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const itemServices = { getItems, getPointHistory };

export default itemServices;
