import React from "react";

export default function CardSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 17.2138C2 17.8768 2.26339 18.5127 2.73223 18.9815C3.20107 19.4504 3.83696 19.7138 4.5 19.7138H19.5C20.163 19.7138 20.7989 19.4504 21.2678 18.9815C21.7366 18.5127 22 17.8768 22 17.2138V10.3388H2V17.2138ZM4.94643 13.8209C4.94643 13.4657 5.08753 13.1251 5.3387 12.8739C5.58986 12.6227 5.93051 12.4816 6.28571 12.4816H8.42857C8.78377 12.4816 9.12442 12.6227 9.37559 12.8739C9.62675 13.1251 9.76786 13.4657 9.76786 13.8209V14.7138C9.76786 15.069 9.62675 15.4096 9.37559 15.6608C9.12442 15.912 8.78377 16.0531 8.42857 16.0531H6.28571C5.93051 16.0531 5.58986 15.912 5.3387 15.6608C5.08753 15.4096 4.94643 15.069 4.94643 14.7138V13.8209Z"
        fill="#111318"
      />
      <path
        d="M19.5 5H4.5C3.83696 5 3.20107 5.26339 2.73223 5.73223C2.26339 6.20107 2 6.83696 2 7.5V8.66072H22V7.5C22 6.83696 21.7366 6.20107 21.2678 5.73223C20.7989 5.26339 20.163 5 19.5 5Z"
        fill="#111318"
      />
    </svg>
  );
}
