export const PATHS = {
  home: "/",
  privacyPolicy: "/privacy-policy",
  terms: "/term-of-conditions",
  returns: "/returns",
  shipping: "/shipping-policy",
  legal: "/legal",
  warrantyPolicy: "/warranty-policy",
  paymentPolicy: "/payment-policy",
  returnsPolicy: "/returns-policy",
  login: "/login",
  register: "/register",
  profile: "/profile",
  settings: "/settings",
  cart: "/cart",
  checkout: "/checkout",
  orders: "/orders",
  forgotPassword: "/password/forgot",
  resetPassword: "/email/reset-password/",
  emailVerify: "/email/verify",
  faq: "/faq",
  afterSalesSupport: "/faq/after-sales-support",
  deliverySupport: "/faq/delivery-support",
  productSupport: "/faq/product-support",
  partnershipSupport: "/faq/partnership-support",
  product: "/product",
  aboutUs: "/about-us",
  partners: "/partners",
  news: "/news",
  contactsUs: "/contact-us",
  preOrder: "/pre-order-policy",
  contact: "/contact",
  useRing: "/use-ring",
  getRingSize: "/get-ring-size",
  forPayment: "/for-payment",
  accessControl: "/access-control",
  festivalDemo: "/festival-demo",
  gbaLogin: "/gba/login",
  gba: "/gba",
};

export const getRoutes = (t: (param: string) => string) => ({
  home: {
    title: t("title.home"),
    path: PATHS.home,
  },
  privacyPolicy: {
    path: PATHS.privacyPolicy,
    title: t("title.privacyPolicy"),
  },
  warrantyPolicy: {
    path: PATHS.warrantyPolicy,
    title: t("title.warrantyPolicy"),
  },
  paymentPolicy: {
    path: PATHS.paymentPolicy,
    title: t("title.paymentPolicy"),
  },
  returnsPolicy: {
    path: PATHS.returnsPolicy,
    title: t("title.returnsPolicy"),
  },
  terms: {
    path: PATHS.terms,
    title: t("title.terms"),
  },
  returns: {
    path: PATHS.returns,
    title: t("title.refunds"),
  },
  shipping: {
    path: PATHS.shipping,
    title: t("title.shipping"),
  },
  legal: {
    path: PATHS.legal,
    title: t("title.privacyPolicy"),
  },
  login: {
    path: PATHS.login,
    title: t("title.signin"),
  },
  register: {
    path: PATHS.register,
    title: t("title.newAccount"),
  },
  settings: {
    path: "/settings",
    title: t("title.settings"),
  },
  profile: {
    title: t("title.profile"),
    path: "/profile",
  },
  cart: {
    path: PATHS.cart,
    title: t("title.cart"),
  },
  checkout: {
    path: PATHS.checkout,
    title: t("title.checkout"),
  },
  orders: {
    path: PATHS.orders,
    title: t("title.orders"),
  },
  forgotPassword: {
    path: PATHS.forgotPassword,
    title: t("title.resetPassword"),
  },
  resetPassword: {
    path: PATHS.resetPassword,
    title: t("title.resetPassword"),
  },
  faq: {
    path: PATHS.faq,
    title: t("title.faq"),
  },
  afterSalesSupport: {
    path: PATHS.afterSalesSupport,
    title: t("title.afterSalesSupport"),
  },
  deliverySupport: {
    path: PATHS.deliverySupport,
    title: t("title.deliverySupport"),
  },
  productSupport: {
    path: PATHS.productSupport,
    title: t("title.productSupport"),
  },
  partnershipSupport: {
    path: PATHS.partnershipSupport,
    title: t("title.partnershipSupport"),
  },
  product: {
    path: PATHS.product,
    title: t("title.product"),
    pattern: `${PATHS.product}/:id`,
  },
  aboutUs: {
    path: PATHS.aboutUs,
    title: t("title.aboutUs"),
  },
  partners: {
    path: PATHS.partners,
    title: t("title.partners"),
  },
  news: {
    path: PATHS.news,
    title: t("title.news"),
  },
  contactsUs: {
    path: PATHS.contactsUs,
    title: t("title.contactsUs"),
  },
  useRing: {
    path: PATHS.useRing,
    title: t("page.product.guide.howToUseTheRing"),
  },
  getRingSize: {
    path: PATHS.getRingSize,
    title: t("page.product.guide.howToGetYourRingSize"),
  },
  forPayment: {
    path: PATHS.forPayment,
    title: t("page.product.guide.money"),
  },
  accessControl: {
    path: PATHS.accessControl,
    title: t("page.product.guide.accessKey"),
  },
  preOrder: {
    path: PATHS.preOrder,
    title: t("title.preOrderPolicy"),
  },
  festivalDemo: {
    path: PATHS.festivalDemo,
    title: t("title.festival"),
  },
  gbaLogin: {
    path: PATHS.gbaLogin,
    title: t("title.gbaLogin"),
  },
  gba: {
    path: PATHS.gba,
    title: t("title.gba"),
  },
});
