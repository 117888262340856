import numeral from "numeral";

export const formatPhoneNumber = (phone: string) =>
  phone.indexOf("+84") === -1 ? phone.replace("0", "+84") : phone;

export function toMoney(num: number, options?: any) {
  const { format = "0,0" } = options || {};
  // num = Math.floor(num * 100) / 100
  return numeral(num).format(format);
}

export const isNumber = (value: string): boolean => {
  return new RegExp(/^\d+$/).test(value);
};

export const toNumber = (value?: string | number | undefined | null) => {
  if (typeof value === "number") {
    return value;
  } else if (value && isNumber(value)) {
    return parseFloat(value);
  }
  return 0;
};
