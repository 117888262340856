export const LocalStorageKeys = {
  SAVED_ACCOUNT: "MT_SAVED_ACCOUNT",
  TOKEN: "MT_TOKEN",
  CART: "MT_CART_DATA",
  CART_REF: "MT_CART_REF_DATA",
  CHECKOUT: "MT_CHECKOUT_DATA",
  ANONYMOUS_ADDRESSES: "MT_ANONYMOUS_ADDRESSES",
  ANONYMOUS_ORDERS: "MT_ANONYMOUS_ORDERS",
  FESTIVAL_USERS: "FESTIVAL_USERS",
};

export const WEB_TYPE_NAME: { [key: string]: string } = {
  PNS: "PNS",
  AGENCY: "AGENCY",
};

export const WEB_TYPE = process.env.REACT_APP_TYPE;
export const WEB_TITLE = process.env.REACT_APP_TITLE || "Many Touches";
export const WEB_URL =
  process.env.REACT_APP_WEB_URL || "https://manytouches.com";

export const PHONE_NUMBER = "1800556841";
export const DISPLAY_PHONE_NUMBER = "1800 556 841";
export const ADDRESS = "";
export const CONTACT_EMAIL = "contact@manytouches.com";
export const PNS_EMAIL = "partnership@manytouches.com";
export const DESTOUCHES_EMAIL = "destouches@manytouches.com";
export const INFO_EMAIL = "info@manytouches.com";
export const PRIVACY_EMAIL = "privacy@manytouches.com";

export const EMAIL = {
  info: INFO_EMAIL,
  destouches: DESTOUCHES_EMAIL,
  contact: CONTACT_EMAIL,
  privacy: PRIVACY_EMAIL,
};

export const SOCIAL_LINK = {
  FACEBOOK: "https://www.facebook.com/manytouches2021",
  YOUTUBE: "https://www.youtube.com/@ManyTouches",
  LINKEDIN: "https://www.linkedin.com/company/many-touches-llc/",
  INSTAGRAM: "https://www.instagram.com/many_touches",
  TWITTER: "",
};

export const AGENCY_GOOGLE_FORM = {
  url: "https://docs.google.com/forms/d/e/1FAIpQLSdIPM6DujmZePLLKifreKEMSP7PVBXRXzm9zmYJDXVzMLRxQA/formResponse",
  form: {
    phone: {
      id: "entry.75432854",
      name: "phone",
      type: "input",
    },
    email: {
      id: "entry.36823863",
      name: "email",
      type: "input",
    },
  },
};
