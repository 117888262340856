import { message } from "antd";
import { atom, useSetAtom } from "jotai";
import jwtDecode from "jwt-decode";

import { LocalStorageKeys } from "@app-constants/common";
import { INIT_PAYLOAD } from "@app-jotai/constants";
import { Mixpanel } from "@app-mixpanel/index";
import { updateHeaderToken } from "@app-services/common";

import { signinServiceAtom, signupServiceAtom } from "./api_atoms";
import {
  isLoggedAtom,
  signinPayloadAtom,
  signupPayloadAtom,
  tokenAtom,
} from "./atoms";

const clearSigninPayloadAtom = atom(null, (_, set) => {
  set(signinPayloadAtom, INIT_PAYLOAD);
});
const clearSignupPayloadAtom = atom(null, (_, set) => {
  set(signupPayloadAtom, INIT_PAYLOAD);
});

const setTokenAtom = atom(null, (_, set, token: string) => {
  set(isLoggedAtom, true);

  set(tokenAtom, token);

  updateHeaderToken(token);

  window.localStorage.setItem(LocalStorageKeys.TOKEN, token);

  const decodedToken = jwtDecode(token) as any;
  Mixpanel.identify(decodedToken.sub);
});

const signoutAtom = atom(null, (_, set) => {
  set(isLoggedAtom, false);
  set(tokenAtom, "");
  updateHeaderToken("");
  window.localStorage.removeItem(LocalStorageKeys.TOKEN);
  message.success("Sign out successfully.");
});

const useAuthWriteOnly = () => {
  const onSignin = useSetAtom(signinServiceAtom);
  const onSignup = useSetAtom(signupServiceAtom);
  const setToken = useSetAtom(setTokenAtom);
  const onSignout = useSetAtom(signoutAtom);

  const clearSigninPayload = useSetAtom(clearSigninPayloadAtom);
  const clearSignupPayload = useSetAtom(clearSignupPayloadAtom);
  return {
    onSignin,
    onSignup,
    clearSigninPayload,
    clearSignupPayload,
    setToken,
    onSignout,
  };
};

export { useAuthWriteOnly };
