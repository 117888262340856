import { Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import MTRingFront from "@app-assets/images/ring-front.png";

import { PNSForm } from "./PNSForm";

export function PNSHeroSection() {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);

  return (
    <div className="pns-landing-page__hero">
      <div className="pns-landing-page__hero-img">
        <div />
      </div>
      <div className="pns-landing-page__hero-content container">
        <div className="pns-landing-page__hero-intro">
          <h1 className="mt-display-lg text-white ">
            {t("page.pns.hero.intro.title1")}
          </h1>
          <h1 className="mt-display-lg text-white">
            {t("page.pns.hero.intro.title2")}
          </h1>
          {/* <div>
            <p className="mt-text-body-xxl mb-[16px] text-white">
              {t("page.pns.hero.intro.content1")}
            </p>
            <p className="mt-text-body-xxl text-white">
              {t("page.pns.hero.intro.content2")}
            </p>
          </div> */}
          <button
            className="bg-transparent text-white mt-text-button-lg view-more-btn"
            onClick={() => setToggle(true)}
          >
            {t("page.pns.hero.intro.viewMore")}
          </button>
        </div>
        <PNSForm />
      </div>
      <Modal
        centered
        open={!!toggle}
        footer={null}
        onCancel={() => setToggle(false)}
        bodyStyle={{
          maxWidth: "1000px",
          padding: 20,
        }}
        className="modal-w-auto"
      >
        <div className="pns-landing-page__hero-modal">
          <div className="img">
            <img src={MTRingFront} alt="" />
          </div>
          <div className="mt-heading-xl text-center whitespace-break-spaces">
            {t("page.pns.hero.intro.modal.title")}
          </div>
          <div className="mt-text-body-xxl text-center">
            {t("page.pns.hero.intro.modal.content")}
          </div>
        </div>
      </Modal>
    </div>
  );
}
