export default function CheckCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="7.99967" cy="8.00016" r="6.66667" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31827 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31827 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668ZM11.3709 5.44315C11.5366 5.15923 11.4408 4.79473 11.1568 4.62903C10.8729 4.46333 10.5084 4.55915 10.3427 4.84308L7.35577 9.96094L5.5144 8.48784C5.25769 8.28248 4.88311 8.3241 4.67775 8.5808C4.47239 8.8375 4.51401 9.21208 4.77071 9.41744L6.72046 10.9772C6.8403 11.075 6.98023 11.1451 7.13028 11.1826C7.28069 11.2203 7.43753 11.2242 7.58959 11.1941C7.73937 11.1654 7.88133 11.105 8.00595 11.0171C8.13155 10.9284 8.23683 10.8134 8.31419 10.6805L11.3709 5.44315Z"
        fill="#196C42"
      />
    </svg>
  );
}
