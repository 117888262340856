import React from "react";
import Header from "../header/Header";
import LPFooter from "../landing-page/Footer";

export default function MainLayout({ children }: { children: any }) {
  return (
    <div className="min-h-screen flex flex-col main-layout">
      <Header />
      <div className="main-layout__content flex flex-1">{children}</div>
      <LPFooter />
    </div>
  );
}
