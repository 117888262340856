import { Alert, message } from "antd";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import validator from "validator";

import StyledButton from "@app-components/button/StyledButton";
import MTInput from "@app-components/input/MTInput";
import { isLoggedAtom } from "@app-jotai/auth";
import { profileAtom, useUserWriteOnly } from "@app-jotai/user";
import useService from "@app-services/shared/use_service";
import userServices from "@app-services/userServices";

const useUpdateProfile = () => useService(userServices.updateProfile);

const useSendVerify = () => useService(userServices.sendVerifyEmail);

export default function Profile() {
  const { t } = useTranslation();

  const { getProfile } = useUserWriteOnly();

  const profile = useAtomValue(profileAtom);

  const [updateProfilePayload, updateProfile, clearUpdateProfile] =
    useUpdateProfile();

  const [sendVerifyPayload, sendVerify, clearSendVerifyState] = useSendVerify();

  const isLogged = useAtomValue(isLoggedAtom);

  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    taxCode: "",
  });

  const validEmail = validator.isEmail(profileData.email);

  useEffect(() => {
    if (isLogged) {
      getProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProfileData({
      name: profile?.name || "",
      email: profile?.email || "",
      taxCode: profile?.taxCode || "",
    });
  }, [profile]);

  useEffect(() => {
    if (updateProfilePayload?.success) {
      message.success(t("message.updateProfileSuccess"));
      getProfile();
    }

    return () => {
      clearUpdateProfile();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfilePayload]);

  useEffect(() => {
    if (sendVerifyPayload?.success) {
      message.success(t("message.sendVerifySuccess"));
      clearSendVerifyState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendVerifyPayload?.success]);

  const handleUpdate = () => {
    updateProfile(profileData);
  };

  return (
    <div className="container auth-page min-h-full flex items-center justify-center ">
      <div className="form min-w-[360px] lg:min-w-[464px]">
        <div className="auth-page__title">{t("title.profile")}</div>
        <div className="input relative min-w-[360px] pb-10">
          <MTInput
            label={t("input.name.label")}
            value={profileData.name}
            onChangeText={(value) =>
              setProfileData((prev) => ({
                ...prev,
                name: value,
              }))
            }
          />
        </div>
        <div className="input relative min-w-[360px] pb-6">
          <MTInput
            label={t("input.email.label")}
            value={profileData.email}
            onChangeText={(value) =>
              setProfileData((prev) => ({
                ...prev,
                email: value,
              }))
            }
            error={
              profileData.email &&
              profileData.email?.length >= 10 &&
              !validEmail
                ? t("content.invalidEmail")
                : ""
            }
          />
          {profile?.email && !profile?.emailVerifiedAt && (
            <div className="text-right flex justify-end">
              <span
                className="text-sm cursor-pointer py-2 mt-2 text-danger"
                onClick={() =>
                  !sendVerifyPayload?.loading &&
                  !sendVerifyPayload?.success &&
                  sendVerify(profile?.email)
                }
              >
                {sendVerifyPayload?.loading
                  ? t("content.sendingVerificationLink")
                  : sendVerifyPayload?.success
                  ? t("content.sentVerificationLink")
                  : sendVerifyPayload?.error
                  ? t(sendVerifyPayload?.error)
                  : t("content.verifyYourEmail")}
              </span>
            </div>
          )}
        </div>
        {/* <div className="input relative min-w-[360px] pb-6">
          <MTInput
            label={t("input.taxCode.label")}
            value={profileData.taxCode}
            onChangeText={(value) =>
              setProfileData((prev) => ({
                ...prev,
                taxCode: value,
              }))
            }
          />
        </div> */}

        {updateProfilePayload?.error && (
          <div className="pb-6 text-left">
            <Alert
              message={t(updateProfilePayload.error)}
              type="error"
              showIcon
            />
          </div>
        )}

        <div className="text-center">
          <StyledButton
            className="outline w-full"
            onClick={handleUpdate}
            // disabled={!profileData.phone || !profileData.password}
            loading={updateProfilePayload?.loading}
          >
            <span className="mt-text-button-xl py-[13px]">
              {t("button.update")}
            </span>
          </StyledButton>
        </div>
      </div>
    </div>
  );
}
