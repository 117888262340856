import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

import LifestyleTitleImage from "@app-assets/images/homepage/lifestyles.png";
import LifestyleBgBlurImage from "@app-assets/images/homepage/styles-blur.png";

const LifestyleBgImage =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/home/styles.png";

export default function LifeStyles() {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="lifestyle-images ">
        <div className="flex-1 flex flex-col gap-[36px]">
          <div>
            <img
              src={LifestyleTitleImage}
              alt="lifestyle title"
              className="max-w-lg"
            />
          </div>
          <div className="mt-text-body-xl whitespace-break-spaces">
            {t("page.newHome.style.content")}
          </div>
        </div>
        <div className="flex-1 w-full">
          <LazyLoadImage
            className="image"
            wrapperClassName="contain-center"
            width={"100%"}
            height={"100%"}
            placeholderSrc={LifestyleBgBlurImage}
            src={LifestyleBgImage}
            alt="The Ring"
          />
        </div>
      </div>
    </div>
  );
}
