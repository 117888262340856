import { useTranslation } from "react-i18next";

import { QnASection } from "@app-components/faq/FaqSection";
import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import { WEB_TYPE, WEB_TYPE_NAME } from "@app-constants/common";
import { faqData } from "@app-constants/faq";
import { getRoutes } from "@app-utils/routes";

export default function PartnershipSupportPage() {
  const { t } = useTranslation();
  const routes = getRoutes(t);
  return (
    <div className="container faq faq-detail">
      <MTBreadcrumb
        items={[
          routes.home,
          ...(WEB_TYPE !== WEB_TYPE_NAME.PNS ? [routes.faq] : []),
          routes.partnershipSupport,
        ]}
      />
      <div className="faq-detail-container">
        <div className="wrapper">
          <div className="faq-detail-header">
            <div className="faq-detail-header-container">
              <div className="faq-detail-title">
                {t("page.faq.section4.title")}
              </div>
              <div className="faq-detail-subtitle">
                {t("page.faq.section4.subtitle")}
              </div>
            </div>
          </div>
          <QnASection item={faqData[3]} showHeader={false} />
        </div>
      </div>
    </div>
  );
}
