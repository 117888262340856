import { atom } from "jotai";
import { INIT_PAYLOAD } from "../constants";
import { Payload } from "../types";
import { IToken } from "../../interface/auth";

const isLoggedAtom = atom(false);

const tokenAtom = atom("");

const signinPayloadAtom = atom<Payload<IToken>>(INIT_PAYLOAD);
const signupPayloadAtom = atom<Payload<IToken>>(INIT_PAYLOAD);

export { isLoggedAtom, tokenAtom, signinPayloadAtom, signupPayloadAtom };
