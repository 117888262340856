import React from "react";

export default function ChevronRight() {
  return (


<svg
xmlns="http://www.w3.org/2000/svg"
width="44"
height="44"
viewBox="0 0 44 44"
fill="none"
>
<path
  d="M22 3.66663C32.1248 3.66663 40.3334 11.8752 40.3334 22C40.3334 32.1247 32.1248 40.3333 22 40.3333C11.8753 40.3333 3.66671 32.1247 3.66671 22C3.66671 11.8752 11.8753 3.66663 22 3.66663ZM18.8878 29.4646C18.7513 29.5943 18.6422 29.7499 18.5668 29.9224C18.4915 30.0949 18.4514 30.2807 18.449 30.469C18.4466 30.6572 18.4819 30.844 18.5528 31.0184C18.6237 31.1927 18.7288 31.3511 18.8619 31.4842C18.995 31.6173 19.1534 31.7224 19.3278 31.7934C19.5022 31.8643 19.689 31.8996 19.8772 31.8972C20.0654 31.8948 20.2512 31.8547 20.4237 31.7793C20.5962 31.704 20.7519 31.5948 20.8815 31.4584L29.3431 22.9968C29.6073 22.7324 29.7558 22.3738 29.7558 22C29.7558 21.6261 29.6073 21.2675 29.3431 21.0031L20.8815 12.5415C20.6149 12.2882 20.2599 12.1491 19.8921 12.1538C19.5244 12.1585 19.173 12.3067 18.913 12.5667C18.6529 12.8268 18.5048 13.1782 18.5 13.5459C18.4953 13.9136 18.6345 14.2687 18.8878 14.5353L26.3516 22L18.8878 29.4646Z"
  fill="#111318"
/>
</svg>
  );
}
