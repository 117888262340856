import { useTranslation } from "react-i18next";

import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import ShippingContent from "@app-components/policy/ShippingContent";
import { WEB_URL } from "@app-constants/common";
import { PATHS, getRoutes } from "@app-utils/routes";

export default function ShippingPolicyPage() {
  const { t } = useTranslation();
  const routes = getRoutes(t);

  return (
    <div className="container policy faq-detail">
      <MTBreadcrumb items={[routes.home, routes.shipping]} />
      <div className="faq-detail-container">
        <div className="wrapper">
          <div className="faq-detail-header">
            <div className="faq-detail-header-container">
              <div className="faq-detail-title">{t("page.shipping.title")}</div>
              <div className="faq-detail-subtitle">
                {t("page.shipping.subtitle")}
              </div>
            </div>
          </div>
          <div>
            <div className="mt-text-body-lg whitespace-break-spaces">
              <div>
                <span>{t("page.shipping.summary")}</span>
                <a
                  href={`${WEB_URL}${PATHS.terms}`}
                  target="blank"
                  className="underline"
                >
                  {`${WEB_URL}${PATHS.terms}`}
                </a>
              </div>
              <br />
              <span>{t("page.shipping.summary1")}</span>
            </div>
          </div>
          <ShippingContent />
        </div>
      </div>
    </div>
  );
}
