export default function Package() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="34"
      viewBox="0 0 32 34"
      fill="none"
    >
      <path
        d="M23.5323 11.967L29.9656 8.26699C29.9656 8.26699 30.1156 8.21699 30.199 8.18366C29.9823 7.95033 29.7323 7.73366 29.449 7.56699L17.6823 0.866992C17.1656 0.566992 16.599 0.416992 15.999 0.416992C15.399 0.416992 14.8323 0.566992 14.3156 0.866992L9.26562 3.75033L23.5156 11.967H23.5323Z"
        fill="#111318"
      />
      <path
        d="M1.94987 8.28353L15.9499 16.3169L20.9999 13.4169L6.7332 5.2002L2.54987 7.58353C2.24987 7.7502 1.99987 7.96686 1.7832 8.21686C1.8332 8.23353 1.89987 8.23353 1.94987 8.26686V8.28353Z"
        fill="#111318"
      />
      <path
        d="M14.6995 18.5007L0.882812 10.5674V23.934C0.882812 24.5174 1.03281 25.1007 1.33281 25.6174C1.63281 26.134 2.04948 26.5507 2.56615 26.8507L14.3328 33.5674C14.4495 33.6341 14.5828 33.6674 14.7161 33.7174V18.5007H14.6995Z"
        fill="#111318"
      />
      <path
        d="M17.2168 18.4999V33.7499C17.3668 33.6833 17.5335 33.6499 17.6835 33.5666L29.4501 26.8499C29.9668 26.5499 30.3835 26.1333 30.6835 25.6166C30.9835 25.0999 31.1335 24.5333 31.1335 23.9333V10.5166L17.2335 18.4999H17.2168Z"
        fill="#111318"
      />
    </svg>
  );
}
