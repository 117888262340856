export default function Partner() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="27"
      viewBox="0 0 38 27"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.805779 2.13635C1.11159 1.57449 1.82014 1.36414 2.38838 1.66652L7.89354 4.59602L12.7271 3.76853L12.7271 3.76853C14.8393 3.40693 17.004 4.0048 18.6209 5.3964C19.1077 5.8153 19.1588 6.54505 18.7352 7.02634C18.3115 7.50763 17.5735 7.5582 17.0867 7.1393C16 6.20407 14.5453 5.80227 13.1257 6.04528C13.1257 6.04528 13.1257 6.04528 13.1257 6.04528L7.89454 6.94085C7.6369 6.98496 7.37174 6.94234 7.14149 6.81981L1.28094 3.70119C0.712706 3.39881 0.499969 2.69821 0.805779 2.13635Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.869106 14.6512C1.23258 14.124 1.95947 13.9879 2.49267 14.3473L16.7726 23.9725C18.2148 24.9446 20.1164 24.9267 21.5393 23.9274L29.8253 18.1087C30.3514 17.7393 31.0808 17.8615 31.4545 18.3817C31.8281 18.9019 31.7045 19.6231 31.1784 19.9926L22.8925 25.8112C22.8924 25.8112 22.8925 25.8112 22.8925 25.8112C20.6726 27.3702 17.706 27.3981 15.4564 25.8817C15.4564 25.8817 15.4564 25.8817 15.4564 25.8817L1.17642 16.2565C0.643226 15.8971 0.505635 15.1784 0.869106 14.6512Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.1918 0.939182C37.4987 1.50049 37.2872 2.20148 36.7195 2.50488L29.493 6.3672C29.2851 6.47831 29.0481 6.52447 28.8131 6.49962L23.383 5.92516L23.3829 5.92516C21.5295 5.72906 19.6753 6.28905 18.2489 7.47571L14.7648 10.3743C13.9611 11.0429 13.8364 12.2205 14.4826 13.0392C15.1304 13.8598 16.3194 14.0248 17.1706 13.4122L17.1706 13.4122L20.5167 11.0042C20.9441 10.6967 21.527 10.7111 21.9383 11.0395L30.3591 17.7626L35.5048 14.2859C36.0375 13.9259 36.7646 14.0611 37.1287 14.5879C37.4927 15.1147 37.356 15.8336 36.8232 16.1936L30.9602 20.155C30.5344 20.4427 29.9683 20.4203 29.567 20.1L21.1674 13.3938L18.5458 15.2804C16.6758 16.6262 14.0635 16.2636 12.6405 14.4608C11.2207 12.6621 11.4946 10.0752 13.2603 8.60623L13.9982 9.47343L13.2603 8.60623L16.7444 5.70766L17.4967 6.59168L16.7444 5.70766C18.6578 4.11581 21.1452 3.36459 23.6316 3.62765C23.6316 3.62765 23.6316 3.62765 23.6316 3.62765L28.701 4.16395L35.6084 0.4722C36.176 0.168798 36.885 0.377873 37.1918 0.939182Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3387 10.3929C28.8611 10.7674 28.9776 11.4898 28.5988 12.0064C27.8187 13.0703 26.4666 13.8499 25.014 14.0953C23.5251 14.3467 21.8273 14.0529 20.4283 12.8204C19.9465 12.396 19.9039 11.6657 20.3331 11.1893C20.7624 10.7129 21.501 10.6708 21.9828 11.0952C22.7727 11.791 23.7214 11.9695 24.6205 11.8176C25.5557 11.6597 26.3328 11.1603 26.7069 10.6501C27.0857 10.1335 27.8163 10.0184 28.3387 10.3929Z"
        fill="black"
      />
    </svg>
  );
}
