import Lottie from "lottie-react";

import comingsoonAnimation from "../../assets/lotties/coming-soon.json";
import Header from "../../components/header/Header";

export default function ComingSoon() {
  return (
    <div className="mt-comming-soon">
      <Header />
      <div className="container w-full">
        <Lottie
          className="flex items-center justify-center min-h-screen"
          animationData={comingsoonAnimation}
          loop
        />
      </div>
    </div>
  );
}
