import { Modal } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";

import WearRingBlurImage from "@app-assets/images/product/wear-ring-blur.png";
import AddBankImage from "@app-assets/svg/guides/add-bank.svg";
import AccessKeyImage from "@app-assets/svg/guides/add-building.svg";
import RingSizeImage from "@app-assets/svg/guides/ring-size.svg";
import UseRingImage from "@app-assets/svg/guides/use-ring.svg";
import { SolidIcon } from "@app-components/svg/icon";
import ContentHowToUse from "@app-components/userGuide/ContentHowToUse";
import ContentHowToUsingRing from "@app-components/userGuide/ContentHowToUsingRing";
import { PATHS } from "@app-utils/routes";

import "./styles.scss";

const GUIDE_TYPE = {
  useRing: "useRing",
  ringSize: "ringSize",
  addBank: "addBank",
  accessKey: "accessKey",
};

const guides = [
  {
    title: "page.newProduct.guide.useRing",
    image: UseRingImage,
    link: PATHS.useRing,
    type: GUIDE_TYPE.useRing,
  },
  {
    title: "page.newProduct.guide.ringSize",
    image: RingSizeImage,
    link: PATHS.getRingSize,
    type: GUIDE_TYPE.ringSize,
  },
  {
    title: "page.newProduct.guide.addBank",
    image: AddBankImage,
    link: PATHS.forPayment,
    type: GUIDE_TYPE.addBank,
  },
  {
    title: "page.newProduct.guide.accessKey",
    image: AccessKeyImage,
    link: PATHS.accessControl,
    type: GUIDE_TYPE.accessKey,
  },
];

function ProductGuides({ scrollPosition }: { scrollPosition: any }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState("");

  const handleOnCloseModal = () => {
    setOpen("");
  };

  const renderContent = useMemo(() => {
    switch (open) {
      case GUIDE_TYPE.useRing:
        return <ContentHowToUsingRing />;
      case GUIDE_TYPE.ringSize:
        return <ContentHowToUse type="ringSize" />;
      case GUIDE_TYPE.addBank:
        return <ContentHowToUse type="payment" />;
      case GUIDE_TYPE.accessKey:
        return <ContentHowToUse type="access" />;
      default:
        return <></>;
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      document.body.setAttribute("style", "overflow: hidden;");
    } else {
      document.body.setAttribute("style", "");
    }
  }, [open]);

  return (
    <div className="guides-bg">
      <div className="bg">
        <LazyLoadImage
          height={"100%"}
          width={"100%"}
          placeholderSrc={WearRingBlurImage}
          wrapperClassName="cover-center"
          src={
            "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/product/wear-ring.png"
          }
          scrollPosition={scrollPosition}
          alt="How to use The Ring images"
        />
      </div>
      <div className="guides-container">
        {guides.map((guide, index) => (
          <div
            className="content-container"
            key={index}
            onClick={() => setOpen(guide.type)}
          >
            <div className="image">
              <img src={guide.image} alt={t(guide.title)} />
            </div>
            <div className="content">
              <div className="title ">{t(guide.title)}</div>
              <div className="icon">
                <SolidIcon.PlusCircle color="#fcfbfc" />
              </div>
            </div>
          </div>
        ))}
      </div>

      {!!open && (
        <Modal
          centered
          open={!!open}
          footer={null}
          onCancel={handleOnCloseModal}
          rootClassName="modal-guide-auto"
        >
          <div>{renderContent}</div>
        </Modal>
      )}
    </div>
  );
}
export default trackWindowScroll(ProductGuides);
