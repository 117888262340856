import { atom } from "jotai";
import { INIT_PAYLOAD } from "../constants";
import { Payload } from "../types";
import { IUser } from "../../interface/user";
import { IAddress } from "../../interface/address";

const profilePayloadAtom = atom<Payload<IUser>>(INIT_PAYLOAD);

const addressesListPayloadAtom =
  atom<Payload<{ metadata: any; models: IAddress[] }>>(INIT_PAYLOAD);

const profileAtom = atom<IUser>(
  (get) => get(profilePayloadAtom)?.data as IUser
);

export { profilePayloadAtom, profileAtom, addressesListPayloadAtom };
