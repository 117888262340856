export default function PlusCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 12C1.75 6.06294 6.56294 1.25 12.5 1.25C18.4371 1.25 23.25 6.06294 23.25 12C23.25 17.9371 18.4371 22.75 12.5 22.75C6.56294 22.75 1.75 17.9371 1.75 12ZM12.5 2.75C7.39136 2.75 3.25 6.89136 3.25 12C3.25 17.1086 7.39136 21.25 12.5 21.25C17.6086 21.25 21.75 17.1086 21.75 12C21.75 6.89136 17.6086 2.75 12.5 2.75ZM12.5 7.25C12.9142 7.25 13.25 7.58579 13.25 8V11.25H16.5C16.9142 11.25 17.25 11.5858 17.25 12C17.25 12.4142 16.9142 12.75 16.5 12.75H13.25V16C13.25 16.4142 12.9142 16.75 12.5 16.75C12.0858 16.75 11.75 16.4142 11.75 16V12.75H8.5C8.08579 12.75 7.75 12.4142 7.75 12C7.75 11.5858 8.08579 11.25 8.5 11.25H11.75V8C11.75 7.58579 12.0858 7.25 12.5 7.25Z"
        fill="#111318"
      />
    </svg>
  );
}
