import classNames from "classnames";
import { useTranslation } from "react-i18next";
import validator from "validator";

import { OutlineIcon } from "./svg/icon";

export default function PasswordValidator({ password }: { password: string }) {
  const { t } = useTranslation();

  const hasSymbol = validator.isStrongPassword(password, {
    minSymbols: 1,
    minUppercase: 0,
    minLength: 0,
    minLowercase: 0,
    minNumbers: 0,
  });

  const hasLowerAndUppercase = validator.isStrongPassword(password, {
    minSymbols: 0,
    minUppercase: 1,
    minLength: 0,
    minLowercase: 1,
    minNumbers: 0,
  });

  const hasMinChars = validator.isStrongPassword(password, {
    minSymbols: 0,
    minUppercase: 0,
    minLength: 8,
    minLowercase: 0,
    minNumbers: 0,
  });

  const hasMinNumber = validator.isStrongPassword(password, {
    minSymbols: 0,
    minUppercase: 0,
    minLength: 0,
    minLowercase: 0,
    minNumbers: 1,
  });
  return (
    <div>
      <div className="mt-title-md pb-[16px]">
        {t("content.passwordRequirement")}
      </div>

      <div className="flex flex-col gap-[8px]">
        <div
          className={classNames("flex items-center mt-text-body-md gap-[8px]", {
            "text-support-success ": hasMinChars,
          })}
        >
          {!hasMinChars ? <OutlineIcon.Circle /> : <OutlineIcon.CheckCircle />}
          <span>{t("content.passwordValidate.atleast8Char")}</span>
        </div>
        <div
          className={classNames("flex items-center mt-text-body-md gap-[8px]", {
            "text-support-success ": hasLowerAndUppercase,
          })}
        >
          {!hasLowerAndUppercase ? (
            <OutlineIcon.Circle />
          ) : (
            <OutlineIcon.CheckCircle />
          )}
          <span>{t("content.passwordValidate.hasLowerAndUpper")}</span>
        </div>
        <div
          className={classNames("flex items-center mt-text-body-md gap-[8px]", {
            "text-support-success ": hasMinNumber,
          })}
        >
          {!hasMinNumber ? <OutlineIcon.Circle /> : <OutlineIcon.CheckCircle />}
          <span>{t("content.passwordValidate.atleast1Number")}</span>
        </div>
        <div
          className={classNames("flex items-center mt-text-body-md gap-[8px]", {
            "text-support-success ": hasSymbol,
          })}
        >
          {!hasSymbol ? <OutlineIcon.Circle /> : <OutlineIcon.CheckCircle />}
          <span>{t("content.passwordValidate.atleast1Symbol")}</span>
        </div>
      </div>
    </div>
  );
}
