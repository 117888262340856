import { useTranslation } from "react-i18next";

import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import ReturnsContent from "@app-components/policy/ReturnsContent";
import { getRoutes } from "@app-utils/routes";

export default function ReturnsPolicyPage() {
  const { t } = useTranslation();
  const routes = getRoutes(t);

  return (
    <div className="container policy faq-detail">
      <MTBreadcrumb items={[routes.home, routes.returnsPolicy]} />
      <div className="faq-detail-container">
        <div className="wrapper">
          <div className="faq-detail-header">
            <div className="faq-detail-header-container">
              <div className="faq-detail-title">{t("page.returns.title")}</div>
              <div className="faq-detail-subtitle">
                {t("page.return.subtitle")}
              </div>
            </div>
          </div>
          <div className="mt-text-body-lg">
            {t('page.return.summary')}
          </div>
          <ReturnsContent />
        </div>
      </div>
    </div>
  );
}
