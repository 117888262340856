import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import MediaQuery from "react-responsive";

import { OutlineIcon } from "@app-components/svg/icon";

export default function BannerVideo({ onTouch }: { onTouch: () => void }) {
  const { t } = useTranslation();

  return (
    <div className="banner-video w-screen">
      {/* <div className="pointer">
        <PlayCircleSvg />
      </div> */}

      <MediaQuery minWidth={768}>
        <div className="banner-video__video-content">
          <ReactPlayer
            width="100%"
            height="100%"
            loop
            muted
            playing
            playsinline
            url="https://video.wixstatic.com/video/9a71f9_bd8c8aafbfca4b7dbcab94c4e623c52d/1080p/mp4/file.mp4"
          />
          {/* <video autoPlay muted loop>
            <source src="static/videos/desktop-video.mp4" type="video/mp4" />
          </video> */}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <div className="banner-video__video-content">
          <ReactPlayer
            // ref={videoRef}
            width="100%"
            height="100%"
            loop
            muted
            playing
            playsinline
            url="https://video.wixstatic.com/video/9a71f9_c628464571024315928dc0894e2a3c50/1080p/mp4/file.mp4"
          />
          {/* <video autoPlay muted loop preload="auto">
            <source
              src="https://artsandculture.withgoogle.com/gcs/national-parks-service/en-us/_KENAI_INTRO_FROM_MAP_081516_mobile.mp4"
              type="video/mp4"
            />
          </video> */}
        </div>
      </MediaQuery>

      <div className="banner-video__content">
        <div className="banner-video__title">
          {t("page.landing.part.bannerVideo.title")}
        </div>
        <div className="text-white text-[40px] font-semibold -mt-[20px] pb-[20px]">
          {t("page.demo.title2")}
        </div>
        <div className="banner-video__button-wrapper flex">
          <div
            className="stroke-white [&>svg]:w-[60px] [&>svg]:h-[60px] flex w-[100px] h-[100px] items-center justify-center cursor-pointer"
            onClick={onTouch}
          >
            <OutlineIcon.FingerTap />
          </div>
        </div>
      </div>
    </div>
  );
}
