export default function Warning({
  className,
  color,
}: {
  className?: string;
  color?: string;
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 2C6.48606 2 2 6.48606 2 12C2 17.5139 6.48606 22 12 22C17.5139 22 22 17.5139 22 12C22 6.48606 17.5139 2 12 2ZM12 17.3803C11.8098 17.3803 11.6239 17.3239 11.4658 17.2182C11.3077 17.1126 11.1844 16.9624 11.1117 16.7867C11.0389 16.611 11.0198 16.4177 11.0569 16.2312C11.094 16.0446 11.1856 15.8733 11.3201 15.7388C11.4546 15.6044 11.6259 15.5128 11.8124 15.4757C11.9989 15.4386 12.1923 15.4576 12.368 15.5304C12.5437 15.6032 12.6938 15.7264 12.7995 15.8845C12.9051 16.0427 12.9615 16.2286 12.9615 16.4188C12.9615 16.6738 12.8602 16.9183 12.6799 17.0987C12.4996 17.279 12.255 17.3803 12 17.3803ZM13.0442 7.70962L12.7683 13.575C12.7683 13.779 12.6872 13.9747 12.543 14.1189C12.3987 14.2632 12.2031 14.3442 11.999 14.3442C11.795 14.3442 11.5994 14.2632 11.4551 14.1189C11.3109 13.9747 11.2298 13.779 11.2298 13.575L10.9538 7.7125V7.7101C10.9478 7.5692 10.9703 7.42853 11.0201 7.29657C11.0698 7.1646 11.1458 7.04407 11.2433 6.94223C11.3409 6.84039 11.4581 6.75935 11.5878 6.70399C11.7175 6.64862 11.857 6.62008 11.9981 6.62008C12.1391 6.62008 12.2787 6.64862 12.4084 6.70399C12.5381 6.75935 12.6553 6.84039 12.7528 6.94223C12.8504 7.04407 12.9263 7.1646 12.9761 7.29657C13.0258 7.42853 13.0484 7.5692 13.0423 7.7101L13.0442 7.70962Z"
        fill={color || "#111318"}
      />
    </svg>
  );
}
