export const INDUSTRIES = [
  { value: "Accommodation Services", label: "Accommodation Services" },
  {
    value: "Administrative and Support Services",
    label: "Administrative and Support Services",
  },
  { value: "Construction", label: "Construction" },
  { value: "Consumer Services", label: "Consumer Services" },
  { value: "Education", label: "Education" },
  { value: "Entertainment Providers", label: "Entertainment Providers" },
  {
    value: "Farming, Ranching, Forestry",
    label: "Farming, Ranching, Forestry",
  },
  { value: "Financial Services", label: "Financial Services" },
  { value: "Government Administration", label: "Government Administration" },
  { value: "Holding Companies", label: "Holding Companies" },
  { value: "Hospitals and Health Care", label: "Hospitals and Health Care" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Oil, Gas, and Mining", label: "Oil, Gas, and Mining" },
  { value: "Professional Services", label: "Professional Services" },
  {
    value: "Real Estate and Equipment Rental Services",
    label: "Real Estate and Equipment Rental Services",
  },
  { value: "Retail", label: "Retail" },
  {
    value: "Technology, Information and Media",
    label: "Technology, Information and Media",
  },
  {
    value: "Transportation, Logistics, Supply Chain and Storage",
    label: "Transportation, Logistics, Supply Chain and Storage",
  },
  { value: "Utilities", label: "Utilities" },
  { value: "Wholesale", label: "Wholesale" },
];

export const PROVINCES = [
  "An Giang",
  "Bà rịa Vũng Tàu",
  "Bạc Liêu",
  "Bắc Giang",
  "Bắc Kạn",
  "Bắc Ninh",
  "Bến Tre",
  "Bình Dương",
  "Bình Định",
  "Bình Phước",
  "Bình Thuận",
  "Cà Mau",
  "Cao Bằng",
  "Cần Thơ",
  "Đà Nẵng",
  "Đắk Lắk",
  "Đắk Nông",
  "Điện Biên",
  "Đồng Nai",
  "Đồng Tháp",
  "Gia Lai",
  "Hà Giang",
  "Hà Nam",
  "Hà Nội",
  "Hà Tĩnh",
  "Hải Dương",
  "Hải Phòng",
  "Hậu Giang",
  "Hòa Bình",
  "Hưng Yên",
  "Khánh Hòa",
  "Kiên Giang",
  "Kon Tum",
  "Lai Châu",
  "Lạng Sơn",
  "Lào Cai",
  "Lâm Đồng",
  "Long An",
  "Nam Định",
  "Nghệ An",
  "Ninh Bình",
  "Ninh Thuận",
  "Phú Thọ",
  "Phú Yên",
  "Quảng Bình",
  "Quảng Nam",
  "Quảng Ngãi",
  "Quảng Ninh",
  "Quảng Trị",
  "Sóc Trăng",
  "Sơn La",
  "Tây Ninh",
  "Thái Bình",
  "Thái Nguyên",
  "Thanh Hóa",
  "Thừa Thiên Huế",
  "Tiền Giang",
  "TP Hồ Chí Minh",
  "Trà Vinh",
  "Tuyên Quang",
  "Vĩnh Long",
  "Vĩnh Phúc",
  "Yên Bái",
];

export const JOB_ROLE = [
  "CEO",
  "CFO",
  "CMO",
  "CLO",
  "CCO",
  "COO",
  "Chairman of the board",
  "Director",
  "Branch manager",
  "Manager",
  "Leader",
  "Specialist",
];
