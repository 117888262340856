import { useTranslation } from "react-i18next";

import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
// import { generateLink } from "@app-components/policy/PolicyContent";
import PrivacyContent from "@app-components/policy/PrivacyContent";
import { getRoutes } from "@app-utils/routes";

export default function Privacy() {
  const { t } = useTranslation();
  const routes = getRoutes(t);

  // const internalLink = (href: string, title?: string, className?: string) => {
  //   return generateLink({
  //     type: "internal",
  //     href,
  //     alias: title,
  //     classNames: className || "underline lowercase",
  //   });
  // };

  return (
    <div className="container policy faq-detail">
      <MTBreadcrumb items={[routes.home, routes.privacyPolicy]} />
      <div className="faq-detail-container">
        <div className="wrapper">
          <div className="faq-detail-header">
            <div className="faq-detail-header-container">
              <div className="faq-detail-title">{t("page.privacy.title")}</div>
            </div>
          </div>
          <div className="policy-content">
            <div className="mt-text-body-lg">
              <p>{t("page.privacy.overview.content.1")}</p>
              <p className="pt-4">{t("page.privacy.overview.content.2")}</p>
            </div>
            <PrivacyContent />
          </div>
        </div>
      </div>
    </div>
  );
}
