import { Divider } from "antd";
import { useTranslation } from "react-i18next";

import AcbImage from "@app-assets/images/pns/partners/acb.png";
import NamlongImage from "@app-assets/images/pns/partners/namlong.png";
import VietcombankImage from "@app-assets/images/pns/partners/vietcombank.png";
import VinhomesImage from "@app-assets/images/pns/partners/vinhomes.png";
import MTCarousel from "@app-components/media/MTCarousel";

export function PNSPartnersSection() {
  const { t } = useTranslation();
  return (
    <section className="container pns-landing-page__partners">
      <Divider className="my-[72px]" />
      <h2 className="section-title mb-[40px]">
        {t("page.pns.partners.title")}
      </h2>
      <div style={{ height: "162px" }}>
        <MTCarousel
          settings={{
            slidesToShow: 4,
            centerMode: true,
            autoplaySpeed: 10000,
            speed: 1000,
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  autoplay: true,
                  slidesToShow: 3,
                  centerPadding: "0px",
                },
              },
              {
                breakpoint: 768,
                settings: {
                  autoplay: true,
                  slidesToShow: 1,
                  centerPadding: "0px",
                },
              },
            ],
          }}
         
          images={[VinhomesImage, NamlongImage, VietcombankImage, AcbImage]}
        />
      </div>
    </section>
  );
}
