import { throwErrorMessage } from "@app-utils/errorHandler";

import { IUser, UpdateProfileReq } from "../interface/user";
import { baseApi } from "./common";

const getProfile = async (): Promise<IUser> => {
  try {
    const response = await baseApi.get(`user`);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const updateProfile = async (data: UpdateProfileReq) => {
  try {
    const response = await baseApi.put(`user/update`, data);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const sendVerifyEmail = async (email: string) => {
  try {
    const response = await baseApi.post(`email/verify`, { email });

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const confirmEmail = async (token: string) => {
  try {
    const response = await baseApi.post(`email/confirm`, { token });

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const userServices = {
  updateProfile,
  sendVerifyEmail,
  confirmEmail,
  getProfile,
};

export default userServices;
