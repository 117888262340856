import { Divider } from "antd";

import { NewsCard } from "./news/NewsCard";

const NEWS = [
  {
    title: "One touch to create Many!",
    datetime: "12/12/2023",
    image: require("@app-assets/images/pns/news/touches.png"),
  },
  {
    title:
      "Many Touches officially joined the German Business Association (GBA) and EuroCham",
    datetime: "12/12/2023",
    image: require("@app-assets/images/pns/news/events.png"),
  },
];
export function PNSNewsSection() {
  return (
    <section className="container pns-landing-page__news">
      <Divider className="my-[72px]" />
      <h2 className="section-title mb-[40px]">News</h2>
      <div className="pns-landing-page__news-container">
        {NEWS.map((item, index) => (
          <NewsCard
            key={index}
            title={item.title}
            img={item.image}
            datetime={"12/12/2023"}
          />
        ))}
      </div>
    </section>
  );
}
