import { Alert } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import validator from "validator";

import PasswordValidator from "@app-components/PasswordValidator";
import StyledButton from "@app-components/button/StyledButton";
import MTPasswordInput from "@app-components/input/MTPasswordInput";
import passwordServices from "@app-services/passwordServices";
import useService from "@app-services/shared/use_service";
import { PATHS } from "@app-utils/routes";

const useEmailResetPassword = () => useService(passwordServices.resetByEmail);

export default function EmailResetPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [resetPayload, onReset] = useEmailResetPassword();

  const token = searchParams.get("token");

  const [resetPasswordData, setResetPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (resetPayload.success) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPayload.success]);

  const handleReset = () => {
    onReset({ password: resetPasswordData.password, token: token || "" });
  };

  const isStrongPassword = validator.isStrongPassword(
    resetPasswordData.password,
    {
      minSymbols: 1,
      minUppercase: 1,
      minLength: 8,
      minLowercase: 1,
      minNumbers: 1,
    }
  );

  const isDisabled = () =>
    !resetPasswordData.password ||
    !resetPasswordData.confirmPassword ||
    !isStrongPassword ||
    resetPasswordData.password !== resetPasswordData.confirmPassword;

  const getInvalidConfirmPasswordMessage = () =>
    resetPasswordData.confirmPassword.length >= 8 &&
    !!resetPasswordData.password &&
    resetPasswordData.password !== resetPasswordData.confirmPassword
      ? t("content.invalidConfirmPassword")
      : "";

  return (
    <div className="auth-page container">
      <div className="flex items-center justify-center min-h-full">
        <div className="form">
          <div className="auth-page__title">
            {resetPayload?.success
              ? t("title.resetPasswordSuccess")
              : t("title.resetPassword")}
          </div>
          <div>
            {resetPayload.success ? (
              <div>
                <div className="text-center">
                  <Link to={PATHS.login}>
                    <StyledButton className="outline">
                      {t("button.login")}
                    </StyledButton>
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <div className="input min-w-[360px] relative pb-10">
                  <MTPasswordInput
                    label={t("input.newPassword.label")}
                    value={resetPasswordData.password}
                    onChangeText={(value) =>
                      setResetPasswordData((prev) => ({
                        ...prev,
                        password: value,
                      }))
                    }
                  />
                  <PasswordValidator password={resetPasswordData.password} />
                </div>
                <div className="input min-w-[360px] relative pb-10">
                  <MTPasswordInput
                    label={t("input.confirmPassword.label")}
                    value={resetPasswordData.confirmPassword}
                    onChangeText={(value) =>
                      setResetPasswordData((prev) => ({
                        ...prev,
                        confirmPassword: value,
                      }))
                    }
                    error={getInvalidConfirmPasswordMessage()}
                  />
                </div>
                {resetPayload?.error && (
                  <div className="pb-6 text-left">
                    <Alert
                      message={t(resetPayload.error)}
                      type="error"
                      showIcon
                    />
                  </div>
                )}
                <div className="text-center">
                  <StyledButton
                    className="outline"
                    onClick={handleReset}
                    disabled={isDisabled()}
                    loading={resetPayload.loading}
                  >
                    {t("button.resetPassword")}
                  </StyledButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
