import { atom } from "jotai";
import { atomService } from "../utils";
import { sendOtpPayloadAtom } from "./atoms";
import smsServices from "../../services/smsServices";

const sendOtpServiceAtom = atom(null, (get, set, phone: string) =>
  atomService(sendOtpPayloadAtom, () => smsServices.sendOtp(phone), get, set)
);

export { sendOtpServiceAtom };
