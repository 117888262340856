export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";

export const SIGN_UP_PAGE = "SIGN_UP_PAGE";
export const PREORDER_PAGE = "PREORDER_PAGE";

export const PREORDER_ITEM = "PREORDER_ITEM";

export const SHOPPING_CART_PAGE = "SHOPPING_CART_PAGE";
export const CHECKOUT_PAGE = "CHECKOUT_PAGE";
export const GUEST_CHECKOUT = "GUEST_CHECKOUT";
export const MEMBER_CHECKOUT = "MEMBER_CHECKOUT";
export const MEMBER_LOGIN_BEFORE_CHECKOUT = "MEMBER_LOGIN_BEFORE_CHECKOUT";

export const MEMBER_ORDER_SUCCESS = "MEMBER_ORDER_SUCCESS";
export const GUEST_ORDER_SUCCESS = "GUEST_ORDER_SUCCESS";
