import React from "react";

export default function PlayIconSvg() {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_416_1649"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="14"
      >
        <path
          d="M11.3292 0.294189H0.552734V13.4707H11.3292V0.294189Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_416_1649)">
        <path
          d="M11.2259 6.55293C11.2943 6.65619 11.3307 6.7773 11.3307 6.90116C11.3307 7.02502 11.2943 7.14614 11.2259 7.2494C11.173 7.35535 11.0871 7.44123 10.9812 7.4941L1.59762 13.4C1.4908 13.4567 1.36985 13.4812 1.24939 13.4706C1.0669 13.4636 0.893782 13.388 0.76465 13.2588C0.635519 13.1297 0.559899 12.9566 0.552918 12.7741V1.0094C0.54982 0.885303 0.586059 0.76341 0.656448 0.661163C0.709318 0.555213 0.795204 0.469327 0.901154 0.416457C1.0034 0.346068 1.12529 0.309829 1.24939 0.312927C1.36868 0.316118 1.48651 0.340002 1.59762 0.383516L10.9812 6.2941C11.0803 6.36954 11.1635 6.46392 11.2259 6.57175"
          fill="white"
        />
      </g>
    </svg>
  );
}
