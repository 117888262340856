import { useCallback, useState } from "react";

export interface ServicePayload<T> {
  data: T;
  loading: boolean;
  success: boolean;
  error: string | any | null;
}
export type ServiceExec<R extends any[]> = (...params: R) => Promise<void>;
export type ServiceReset = () => void;

export default function useService<T, R extends any[]>(
  fetch: (...params: R) => Promise<T>,
  initialData?: T,
  initialLoading?: boolean,
  initialSuccess?: boolean
): [ServicePayload<T>, ServiceExec<R>, ServiceReset] {
  const [data, setData] = useState<T>(initialData || ({} as T));
  const [loading, setLoading] = useState(initialLoading || false);
  const [success, setSuccess] = useState(initialSuccess || false);
  const [error, setError] = useState<any | null>(null);

  const exec = useCallback(async (...params: R) => {
    try {
      setError(null);
      setSuccess(false);
      setLoading(true);
      setData(await fetch(...params));
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reset = useCallback(
    (data?: T) => {
      setData((data || initialData) as T);
      setLoading(initialLoading as boolean);
      setError(null);
      setSuccess(false);
    },
    [initialData, initialLoading]
  );

  return [
    {
      data,
      loading,
      success,
      error,
    },
    exec,
    reset,
  ];
}
