import mixpanel, { Callback, Dict, RequestOptions } from "mixpanel-browser";

export * from "./eventTypes";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

export const identify = (userId: string) => mixpanel.identify(userId);

export const track = (
  event_name: string,
  properties?: Dict | undefined,
  optionsOrCallback?: RequestOptions | Callback | undefined,
  callback?: Callback | undefined
) => mixpanel.track(event_name, properties, optionsOrCallback, callback);

export const Mixpanel = {
  track,
  identify,
};
