import { LazyLoadImage } from "react-lazy-load-image-component";

import ChargelessBlurImage from "@app-assets/images/homepage/no-charge-blur.png";
import SecureBlurImage from "@app-assets/images/homepage/secure-blur.png";
import StrongBlurImage from "@app-assets/images/homepage/strong-blur.png";
import UnwetBlurImage from "@app-assets/images/homepage/water-blur.png";
import ChargelessSvg from "@app-assets/svg/chargeless.svg";
import ResitantSvg from "@app-assets/svg/resistant.svg";
import UnwetBlurSvg from "@app-assets/svg/waterproof.svg";

const ChargelessImage =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/home/no-charge.png";

const SecureImage =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/home/secure.png";
const UnwetImage =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/home/water.png";
const StrongImage =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/home/strong.png";

export default function IntroImages() {
  return (
    <div className="intro-images container gap-[24px]">
      <div className="flex-1 img-container">
        <LazyLoadImage
          className="main"
          wrapperClassName="cover-center"
          width={"100%"}
          height={"100%"}
          placeholderSrc={SecureBlurImage}
          src={SecureImage}
          alt="The Ring - Secure"
        />
      </div>
      <div className="flex flex-col flex-wrap md:flex-nowrap md:flex-row gap-[24px]">
        <div className="img-container flex-1 relative">
          <div className="gradient-layer" />
          <LazyLoadImage
            wrapperClassName="cover-center"
            className="secondary"
            width={"100%"}
            height={"100%"}
            placeholderSrc={UnwetBlurImage}
            src={UnwetImage}
            alt="The Ring - Unwet"
          />
          <div className="icon">
            <img src={UnwetBlurSvg} alt="unwet" />
          </div>
        </div>
        <div className="img-container flex-1 relative">
          <div className="gradient-layer" />
          <LazyLoadImage
            className="secondary"
            wrapperClassName="cover-center"
            width={"100%"}
            height={"100%"}
            placeholderSrc={ChargelessBlurImage}
            src={ChargelessImage}
            alt="The Ring - Chargeless"
          />
          <div className="icon">
            <img src={ChargelessSvg} alt="The Ring - Chargeless" />
          </div>
        </div>
        <div className="img-container flex-1 relative">
          <div className="gradient-layer" />
          <LazyLoadImage
            className="secondary"
            wrapperClassName="cover-center"
            width={"100%"}
            height={"100%"}
            placeholderSrc={StrongBlurImage}
            src={StrongImage}
            alt="The Ring - Strong"
          />
          <div className="icon">
            <img src={ResitantSvg} alt="The Ring - Strong" />
          </div>
        </div>
      </div>
    </div>
  );
}
