import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// import BannerFooterImage from "@app-assets/images/homepage/banner-footer.png";
import StyledButton from "@app-components/button/StyledButton";
import Header from "@app-components/header/Header";
import Banner from "@app-components/landing-page-v2/Banner";
import DoItAllBanner from "@app-components/landing-page-v2/DoItAll";
import IntroFunctions from "@app-components/landing-page-v2/IntroFunctions";
import IntroImages from "@app-components/landing-page-v2/IntroImages";
import LifeStyles from "@app-components/landing-page-v2/LifeStyles";
// import Partners from "@app-components/landing-page-v2/Partners";
import SliderImages from "@app-components/landing-page-v2/SliderImages";
import LPFooter from "@app-components/landing-page/Footer";
import useScrollToTop from "@app-hook/useScrollToTop";
import { PATHS } from "@app-utils/routes";

export default function LandingPageV2() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useScrollToTop();
  return (
    <div className="landing-page-v2">
      <Header />
      <Banner />
      <div className="page-bg">
        <DoItAllBanner />
        <IntroFunctions />
        <IntroImages />
        <LifeStyles />
        <SliderImages />
        {/* <div className="py-[56px] ">
          <img src={BannerFooterImage} className="m-auto" alt="footer" />
        </div> */}

        {/* <Partners /> */}
        <div className="contact-us">
          <div className="title">Interesting?</div>
          <div>
            <StyledButton
              className="primary mt-button-lg px-[16px]"
              onClick={() => navigate(PATHS.contactsUs)}
            >
              {t("button.contactUs")}
            </StyledButton>
          </div>
        </div>
      </div>

      <LPFooter />
    </div>
  );
}
