export default function NumberTwoCircle() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9.79232"
        cy="9.79134"
        r="8.20833"
        stroke="#111318"
        strokeWidth="1.5"
      />
      <path
        d="M12.8542 13.0723V14.166H7.29427V13.2261L9.99447 10.2809C10.2907 9.94672 10.5243 9.65809 10.6951 9.41504C10.866 9.17198 10.9857 8.95361 11.054 8.75993C11.1262 8.56245 11.1623 8.37066 11.1623 8.18457C11.1623 7.92253 11.1129 7.69276 11.0142 7.49528C10.9192 7.294 10.7787 7.13639 10.5926 7.02246C10.4065 6.90473 10.1806 6.84587 9.91471 6.84587C9.6071 6.84587 9.34885 6.91233 9.13997 7.04525C8.9311 7.17817 8.77349 7.36236 8.66716 7.59782C8.56082 7.82948 8.50765 8.09532 8.50765 8.39534H7.13477C7.13477 7.91303 7.2449 7.47249 7.46517 7.07373C7.68544 6.67117 8.00445 6.35216 8.4222 6.1167C8.83995 5.87744 9.34315 5.75781 9.9318 5.75781C10.4863 5.75781 10.9572 5.85086 11.3446 6.03695C11.7319 6.22304 12.0263 6.48698 12.2275 6.82878C12.4326 7.17057 12.5352 7.57503 12.5352 8.04215C12.5352 8.3004 12.4934 8.55675 12.4098 8.8112C12.3263 9.06565 12.2067 9.3201 12.0509 9.57454C11.899 9.8252 11.7186 10.0777 11.5098 10.3322C11.3009 10.5828 11.0711 10.8373 10.8205 11.0955L9.02604 13.0723H12.8542Z"
        fill="#111318"
      />
    </svg>
  );
}
