import { throwErrorMessage } from "@app-utils/errorHandler";

import { IToken, SignUpRequest, SigninRequest } from "../interface/auth";
import { baseApi } from "./common";

const signup = async (data: SignUpRequest): Promise<IToken> => {
  try {
    const response = await baseApi.post(`auth/signup`, data);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const signin = async (data: SigninRequest): Promise<IToken> => {
  try {
    const response = await baseApi.post(`auth/signin`, data);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const authServices = {
  signup,
  signin,
};

export default authServices;
