import { Layout, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import StyledButton from "@app-components/button/StyledButton";
import HeaderLanguage from "@app-components/header/HeaderLanguage";
import LoadingAnimation from "@app-components/lotties/LoadingAnimation";
import SuccessAnimation from "@app-components/lotties/SuccessAnimation";
import MTVideo from "@app-components/media/MTVideo";
import MTLogoWhiteSvg from "@app-components/svg/MTLogoSvg";
import NfcSvg from "@app-components/svg/nfc";

import BannerVideo from "./components/BannerVideo";
import "./styles.scss";

const { ipcRenderer } = window.electron || {};

const Step2Content = ({ onSuccess }: { onSuccess: () => void }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "c") {
        onSuccess?.();
      }
    };

    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="gap-[24px] flex flex-col items-center md:flex-row md:items-stretch">
      <div className="bg-silver-100 p-[24px] rounded-[16px] flex flex-col xl:flex-row flex-1 gap-[30px] items-center">
        {/* Video */}
        <div className="max-w-[400px] w-full flex-shrink-0 [&>div]:pt-[75%] xl:[&>div]:pt-[128%]">
          <MTVideo videoUrl="https://video.wixstatic.com/video/9a71f9_116bb798bf464a7b98d0afbc62464907/1080p/mp4/file.mp4" />
        </div>
        <div className="text-center">
          <div className="whitespace-break-spaces text-[28px] font-semibold leading-[1.25]">
            {t("page.demo.title1")}
            <br />
            The Ring
          </div>
          <div className="text-[18px] leading-[1.4] pt-[12px]">
            {t("page.demo.content1")}
          </div>
        </div>
      </div>
      {/* Information */}
      <div className="bg-silver-100 rounded-[16px] py-[56px] px-[16px] basis-1/3 text-center max-w-[460px] w-full flex flex-col items-center justify-center">
        <div className="text-[28px] font-semibold leading-[1.4] pb-[10px]">
          {t("title.scanToPayment")}
        </div>
        <div className="flex items-center justify-center pt-[40px]">
          <NfcSvg />
        </div>
      </div>
    </div>
  );
};

// const ExportUsers = () => {
//   const { t } = useTranslation();

//   const [data, setData] = useState([]);

//   const [showModal, setShowModal] = useState(false);

//   const [showModalClearUser, setShowModalClearUser] = useState(false);

//   const handleShowModal = () => {
//     const storedData = window.localStorage.getItem(
//       LocalStorageKeys.FESTIVAL_USERS
//     );

//     const data = storedData
//       ?.split("\n")
//       .filter((i) => !!i)
//       .reduce((result, item: string) => {
//         try {
//           result.push(JSON.parse(item));
//         } catch (error) {}
//         return result;
//       }, [] as any);

//     setShowModal(!!data?.length);
//     if (!!data?.length) setData(data);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setData([]);
//   };

//   return (
//     <>
//       <div onClick={handleShowModal} className="flex items-center">
//         <DownloadOutlined className="text-[22px] mr-[10px]" />
//       </div>
//       <Modal open={showModal} title={t("page.festival.title.t1")} footer={null}>
//         <div>
//           <p>{t("page.festival.content.c1")}</p>
//           <div className="text-right pt-10 flex flex-row gap-[10px] justify-end">
//             <Button onClick={handleCloseModal}>{t("button.cancel")}</Button>
//             {!!data?.length && (
//               <CSVLink
//                 headers={[
//                   { label: "Name", key: "name" },
//                   { label: "Phone", key: "phone" },
//                   { label: "Email", key: "email" },
//                 ]}
//                 data={data}
//                 asyncOnClick={true}
//                 onClick={() => {
//                   console.log("click");
//                   setShowModal(false);
//                   setShowModalClearUser(true);
//                 }}
//                 className="flex"
//               >
//                 <Button type="primary">{t("button.export")}</Button>
//               </CSVLink>
//             )}
//           </div>
//         </div>
//       </Modal>
//       <Modal
//         open={showModalClearUser}
//         title={t("page.festival.title.t2")}
//         footer={null}
//       >
//         <div>
//           <p>{t("page.festival.content.c2")}</p>
//           <div className="text-right pt-10 flex flex-row gap-[10px] justify-end">
//             <Button onClick={() => setShowModalClearUser(false)}>
//               {t("button.cancel")}
//             </Button>
//             <Button
//               type="primary"
//               onClick={() => {
//                 setShowModalClearUser(false);
//                 window.localStorage.setItem(
//                   LocalStorageKeys.FESTIVAL_USERS,
//                   ""
//                 );
//               }}
//             >
//               {t("button.clearUsers")}
//             </Button>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

export default function FestivalDemo() {
  const { t } = useTranslation();

  const [step, setStep] = useState(2);

  const [receivedCard, setReceivedCard] = useState(false);

  const [renderBanner, setRenderBanner] = useState(true);

  useEffect(() => {
    registerNfcUsb();
    return () => {
      if (ipcRenderer) {
        ipcRenderer.off("fromMain", handleGetCardData);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (receivedCard) {
      setReceivedCard(false);
      handlePayment();
    }
  }, [receivedCard]);

  const handleReload = () => {
    setStep(2);
    setRenderBanner(true);
  };

  const registerNfcUsb = () => {
    if (!ipcRenderer) return;
    ipcRenderer.on("fromMain", handleGetCardData);
  };

  const handleGetCardData = (_: any, event: any) => {
    console.log(event); // Returns: {'SAVED': 'File Saved'}
    if (event.text) {
      message.info(event.text);
    } else {
      // if (event.card) {
      //   // message.success(`Received nfc ring with uid: ${event?.card?.uid}`);
      //   // cardRef.current = event.card;
      //   // handleAddRingFromNfcUsb(event.card.uid);
      //   setStep(3);
      //   setTimeout(() => {
      //     setStep(4);
      //   }, 1000);
      // } else if (event.error) {
      //   message.error(event.error?.message);
      // } else
      setStep(3);
      setTimeout(() => {
        setStep(4);
      }, 1000);
    }
  };

  const handlePayment = () => {};

  const renderContent = () => {
    // if (step === 1) {
    //   return (
    //     <div className="gap-[24px] flex flex-col items-center md:flex-row md:items-stretch">
    //       <div className="bg-silver-100 p-[24px] rounded-[16px] flex flex-col xl:flex-row flex-1 gap-[30px] items-center">
    //         {/* Video */}
    //         <div className="max-w-[400px] w-full flex-shrink-0 [&>div]:pt-[75%] xl:[&>div]:pt-[128%]">
    //           <MTVideo videoUrl="https://video.wixstatic.com/video/9a71f9_e983d07d0ac64d508aed2b3ad303ce35/1080p/mp4/file.mp4" />
    //         </div>
    //         <div className="text-center">
    //           <div className="whitespace-break-spaces text-[28px] font-semibold leading-[1.25]">
    //             {t("page.landing.functionIntro.func1.title")}
    //           </div>
    //           <div className="text-[18px] leading-[1.4] pt-[12px]">
    //             {t("page.landing.functionIntro.func1.desc")}
    //           </div>
    //         </div>
    //       </div>
    //       {/* Information */}
    //       <div className="bg-silver-100 rounded-[16px] py-[56px] px-[16px] basis-1/3 text-center max-w-[460px] w-full">
    //         <div className="text-[28px] font-semibold leading-[1.4] pb-[10px]">
    //           {t("title.information")}
    //         </div>
    //         <div className="pt-[24px]">
    //           <MTInput
    //             label={t("input.name.label")}
    //             value={info.name}
    //             onChangeText={(text) =>
    //               setInfo((prev) => ({ ...prev, name: text }))
    //             }
    //             onFocus={() => setShowNameError(false)}
    //             onBlur={() => setShowNameError(true)}
    //             error={
    //               showNameError && !info.name ? t("content.inputYourName") : ""
    //             }
    //           />
    //         </div>
    //         <div className="pt-[24px]">
    //           <MTInput
    //             label={t("input.phoneNumber.label")}
    //             value={info.phone}
    //             onChangeText={(text) =>
    //               setInfo((prev) => ({ ...prev, phone: text.trim() }))
    //             }
    //             onFocus={() => setShowPhoneError(false)}
    //             onBlur={() => setShowPhoneError(true)}
    //             error={
    //               showPhoneError && !validPhone ? t("content.invalidPhone") : ""
    //             }
    //           />
    //         </div>
    //         <div className="pt-[24px]">
    //           <MTInput
    //             label={t("input.email.label")}
    //             value={info.email}
    //             onChangeText={(text) =>
    //               setInfo((prev) => ({ ...prev, email: text.trim() }))
    //             }
    //             onFocus={() => setShowEmailError(false)}
    //             onBlur={() => setShowEmailError(true)}
    //             error={
    //               showEmailError && !validEmail ? t("content.invalidEmail") : ""
    //             }
    //           />
    //         </div>
    //         <div className="mt-[56px]">
    //           <StyledButton
    //             className="primary w-full"
    //             onClick={handleNext}
    //             disabled={!info.name || !validEmail || !validPhone}
    //           >
    //             {t("button.next")}
    //           </StyledButton>
    //           <StyledButton
    //             className="text w-full shadow-none mt-2"
    //             onClick={() => setStep(2)}
    //           >
    //             {t("button.skip")}
    //           </StyledButton>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // } else
    if (step === 2) {
      return (
        <Step2Content
          onSuccess={() => {
            setStep(3);
            setTimeout(() => {
              setStep(4);
            }, 1000);
          }}
        />
      );
    } else if (step === 3) {
      return (
        <div className="flex flex-col items-center">
          <LoadingAnimation />
          <div className="pt-[32px] text-[32px] leading-[1.25] font-semibold">
            {t("content.pleaseWait")}
          </div>
        </div>
      );
    } else if (step === 4) {
      return (
        <div className="flex flex-col items-center">
          <SuccessAnimation />
          <div className="max-w-[650px] text-center pt-[32px] text-[32px] leading-[1.25] font-semibold whitespace-pre-line">
            {t("content.congraPaymentSuccess")}
          </div>
          <StyledButton
            className="primary w-[200px] mt-[30px]"
            onClick={handleReload}
          >
            {t("button.goToHome")}
          </StyledButton>
        </div>
      );
    }

    return null;
  };

  return (
    <Layout className="bg-white festival min-h-screen">
      <Layout.Header className="bg-anti-flash fixed z-[1000] w-full px-[15px] [&>div]:max-w-[1440px] [&>div]:px-[15px] [&>div]:mx-auto shadow-sm">
        <div>
          <div className="logo h-[64px] flex items-center justify-between">
            <div className="w-[64px]" />
            <button className="" onClick={() => window.location.reload()}>
              <MTLogoWhiteSvg color="#000" />
            </button>
            <div className="flex items-center">
              {/* <ExportUsers /> */}

              <HeaderLanguage />
            </div>
          </div>
        </div>
      </Layout.Header>
      <Layout.Content className="flex items-center">
        {renderBanner ? (
          <BannerVideo onTouch={() => setRenderBanner(false)} />
        ) : (
          <div className="max-w-[1440px] mx-auto px-[15px] pt-[140px] pb-[60px] h-full">
            {renderContent()}
          </div>
        )}
      </Layout.Content>
    </Layout>
  );
}
