import { useEffect } from "react";
import Header from "../../components/header/Header";
import { useSearchParams } from "react-router-dom";
import Lottie from "lottie-react";

import useService from "../../services/shared/use_service";
import userServices from "../../services/userServices";
import EmailAnimation from "../../assets/lotties/email-verification.json";
import StyledButton from "../../components/button/StyledButton";

const useConfirmEmail = () => useService(userServices.confirmEmail);

export default function EmailVerification() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [payload, onCofirmEmail] = useConfirmEmail();

  useEffect(() => {
    if (token) {
      onCofirmEmail(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Header />
      <div className="container flex items-center justify-center flex-col">
        <Lottie
          className="h-[600px] mb-[-50px]"
          animationData={EmailAnimation}
          loop
        />
        <div className="text-center relative z-10">
          {payload?.error && (
            <div>
              <div className="mb-5">{payload.error}</div>
              <a href="mailto:contact@manytouches.com">
                <StyledButton className="primary">Contact Support</StyledButton>
              </a>
            </div>
          )}
          {payload?.success && (
            <div>
              <div className="mb-5">Your email has been verified!</div>
              <StyledButton className="primary">Go to Profile</StyledButton>
            </div>
          )}
          {payload?.loading && <span>Your email is verifying...</span>}
        </div>
      </div>
    </div>
  );
}
