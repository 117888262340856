import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import PolicyContent, {
  PolicyContentData,
  generateList,
} from "./PolicyContent";

export default function PaymentContent() {
  const { t } = useTranslation();

  const tableContent: PolicyContentData = useMemo(() => {
    return {
      hideTableContent: true,
      hideIndex: true,
      content: {
        payment: {
          items: [
            {
              content: [
                t("page.payment.content.1"),
                generateList([
                  "Visa",
                  "Mastercard",
                  "American Express",
                  "Discover",
                  "PayPal",
                  t("page.payment.content.2"),
                ]),
                t("page.payment.content.3"),
                t("page.payment.content.4"),
                t("page.payment.content.5"),
              ],
            },
          ],
        },
      },
    };
  }, [t]);

  return <PolicyContent data={tableContent} />;
}
