// import axios from "axios";
import { IPartnership } from "@app-interface/pns";

// import { throwErrorMessage } from "@app-utils/errorHandler";

// export const provincesApi = axios.create({
//   baseURL: "https://provinces.open-api.vn/api/",
//   responseType: "json",
//   timeout: 15000,
// });

// const getProvincesList = async (): Promise<any> => {
//   try {
//     const response = await provincesApi.get("/");
//     console.log(response);
//     return response.data;
//   } catch (error: any) {
//     throw throwErrorMessage(error);
//   }
// };

const createPartnership = async (pnsData: IPartnership): Promise<any> => {
  return await new Promise((resolve: any) => {
    setTimeout(() => {
      resolve({ data: "success" });
    }, 2000);
  });
};

const pnsServices = {
  createPartnership,
};

export default pnsServices;
