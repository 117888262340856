import React from "react";

export default function ChevronDown() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2803 8.21967C17.9874 7.92678 17.5126 7.92678 17.2197 8.21967L11.75 13.6893L6.28033 8.21967C5.98744 7.92678 5.51256 7.92678 5.21967 8.21967C4.92678 8.51256 4.92678 8.98744 5.21967 9.28033L11.2197 15.2803C11.5126 15.5732 11.9874 15.5732 12.2803 15.2803L18.2803 9.28033C18.5732 8.98744 18.5732 8.51256 18.2803 8.21967Z"
        fill="#111318"
      />
    </svg>
  );
}
