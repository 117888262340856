import { Alert, message } from "antd";
import { useAtomValue } from "jotai";
import jwtDecode from "jwt-decode";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { useNavigate, useSearchParams } from "react-router-dom";
import validator from "validator";

import PasswordValidator from "@app-components/PasswordValidator";
import StyledButton from "@app-components/button/StyledButton";
import MTInput from "@app-components/input/MTInput";
import MTPasswordInput from "@app-components/input/MTPasswordInput";
import { signupPayloadAtom, useAuthWriteOnly } from "@app-jotai/auth";
import { sendOtpPayloadAtom, useSmsWriteOnly } from "@app-jotai/sms";
import {
  Mixpanel,
  SIGN_UP_ERROR,
  SIGN_UP_PAGE,
  SIGN_UP_SUCCESS,
} from "@app-mixpanel/index";
import { formatPhoneNumber } from "@app-utils/number";
import { PATHS } from "@app-utils/routes";

export default function Register() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { onSignup, clearSignupPayload, setToken } = useAuthWriteOnly();

  const { onSendOtp, onClearSendOtpPayload } = useSmsWriteOnly();

  const sendOtpPayload = useAtomValue(sendOtpPayloadAtom);

  const signupPayload = useAtomValue(signupPayloadAtom);

  const [countDown, setCountDown] = useState(0);

  const [showOtp, setShowOtp] = useState(false);

  const [signup, setSignup] = useState({
    phone: "",
    password: "",
    otp: "",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const validPhone = validator.isMobilePhone(signup.phone, "vi-VN");

  const isStrongPassword = validator.isStrongPassword(signup.password, {
    minSymbols: 1,
    minUppercase: 1,
    minLength: 8,
    minLowercase: 1,
    minNumbers: 1,
  });

  const formattedPhone = useMemo(() => {
    if (!validPhone) return signup.phone;
    return formatPhoneNumber(signup.phone);
  }, [signup.phone, validPhone]);

  useEffect(() => {
    Mixpanel.track(SIGN_UP_PAGE);

    return () => {
      onClearSendOtpPayload();
      clearSignupPayload();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timer | null = null;

    if (sendOtpPayload.success) {
      setShowOtp(true);
      messageApi.success(t("message.sentOtpCode"));
      timer = setInterval(() => {
        setCountDown((prev) => {
          if (prev < 0) {
            if (timer) {
              clearInterval(timer);
              timer = null;
            }
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendOtpPayload]);

  useEffect(() => {
    if (signupPayload?.success) {
      Mixpanel.track(SIGN_UP_SUCCESS);

      setToken(signupPayload?.data?.accessToken as string);
      const redirect = searchParams.get("redirect");
      navigate(redirect || "/profile");

      const decoded = jwtDecode(
        signupPayload?.data?.accessToken as string
      ) as any;

      Mixpanel.identify(decoded.sub);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupPayload?.success]);

  useEffect(() => {
    if (signupPayload.error) {
      Mixpanel.track(SIGN_UP_ERROR, {
        error: signupPayload.error,
        phone: signup.phone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupPayload.error]);

  const handleSendOtp = () => {
    setCountDown(180);

    onSendOtp(formattedPhone);
  };

  const handleSignup = () => {
    onSignup({ ...signup, phone: formattedPhone });
  };

  const renderContent = () => {
    if (signupPayload.success) return null;

    return (
      <>
        {showOtp ? (
          <>
            <div className="text-md text-metalic-silver pb-4">
              {t("title.enterOTP")}
            </div>
            <OtpInput
              value={signup.otp}
              onChange={(otp) =>
                setSignup((prev) => ({
                  ...prev,
                  otp,
                }))
              }
              containerStyle="justify-between pb-4"
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => (
                <input {...props} className="otp-input" />
              )}
            />
            <div className="text-metalic-silver pb-10">
              {t("content.notGetCode")}{" "}
              {!!countDown ? (
                <span>
                  {t("content.resendAfter")} {countDown}s
                </span>
              ) : (
                <span
                  className="underline cursor-pointer font-semibold"
                  onClick={handleSendOtp}
                >
                  {t("button.resend")}
                </span>
              )}
            </div>
            {signupPayload?.error && (
              <div className="pb-10 text-left">
                <Alert message={t(signupPayload.error)} type="error" showIcon />
              </div>
            )}
          </>
        ) : (
          <>
            <div className="input relative pb-10">
              <MTInput
                label={t("input.phoneNumber.label")}
                value={signup.phone}
                onChangeText={(value) =>
                  setSignup((prev) => ({
                    ...prev,
                    phone: value,
                  }))
                }
                error={
                  signup.phone && !validPhone ? t("content.invalidPhone") : ""
                }
              />
            </div>
            <div className="input relative pb-6 flex flex-col gap-[16px]">
              <MTPasswordInput
                label={t("input.password.label")}
                value={signup.password}
                onChangeText={(value) =>
                  setSignup((prev) => ({
                    ...prev,
                    password: value,
                  }))
                }
              />
              <PasswordValidator password={signup.password} />
            </div>

            {sendOtpPayload?.error && (
              <div className="pb-6 text-left">
                <Alert
                  message={t(sendOtpPayload.error)}
                  type="error"
                  showIcon
                />
              </div>
            )}
          </>
        )}

        <div className="text-center">
          <StyledButton
            className="primary w-full"
            onClick={!showOtp ? handleSendOtp : handleSignup}
            disabled={
              !isStrongPassword || !validPhone || (showOtp && !signup.otp)
            }
            loading={
              (sendOtpPayload?.loading && !showOtp) || signupPayload.loading
            }
          >
            <span className="mt-text-button-xl py-[13px]">
              {showOtp ? t("button.submit") : t("button.signup")}
            </span>
          </StyledButton>

          <div className="py-[32px]">
            <span className="mt-text-body-xl">
              {t("content.alreadyHaveAccount")}
            </span>
          </div>

          <StyledButton
            className="w-full outline"
            onClick={() => navigate(PATHS.login)}
          >
            <span className="mt-text-button-xl py-[13px]">
              {t("button.signin")}
            </span>
          </StyledButton>
        </div>
      </>
    );
  };

  return (
    <div className="auth-page container">
      {contextHolder}
      <div className="">
        <div className="flex items-center justify-center">
          <div className="form min-w-[360px] lg:min-w-[464px]">
            <div className="auth-page__title">
              {signupPayload.success
                ? t("title.welcomeToMT")
                : t("button.signup")}
            </div>
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
}
