import { atom } from "jotai";
import { atomService } from "../utils";
import { addressesListPayloadAtom, profilePayloadAtom } from "./atoms";
import userServices from "../../services/userServices";
import addressServices from "../../services/addressServices";

const getProfileServiceAtom = atom(null, (get, set) =>
  atomService(profilePayloadAtom, () => userServices.getProfile(), get, set)
);

const getAddressesServiceAtom = atom(null, (get, set) =>
  atomService(
    addressesListPayloadAtom,
    () => addressServices.getAddressesList(),
    get,
    set
  )
);

export { getProfileServiceAtom, getAddressesServiceAtom };
