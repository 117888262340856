import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { PATHS } from "@app-utils/routes";

import PolicyContent, {
  PHONE_NUMBER_LINK,
  PolicyContentData,
  generateLink,
  generateList,
  getEmailLink,
} from "./PolicyContent";

export default function ReturnsContent() {
  const { t } = useTranslation();
  const emailContact = getEmailLink("contact");

  const tableContent: PolicyContentData = useMemo(() => {
    return {
      hideTableContent: true,
      hideIndex: true,
      titleStyles: "mt-title-xxl",
      content: {
        returnsExchanges: {
          // title: t("page.returns.returnsExchanges.title"),
          items: [
            {
              title: t("page.returns.returnsExchanges.condition.title"),
              content: [
                t("page.returns.returnsExchanges.condition.content1"),
                t("page.returns.returnsExchanges.condition.content2"),
                generateList([
                  t("page.returns.returnsExchanges.condition.content3.1"),
                  t("page.returns.returnsExchanges.condition.content3.2"),
                  t("page.returns.returnsExchanges.condition.content3.3"),
                ]),
              ],
            },
            {
              title: t("page.returns.returnsExchanges.process.title"),
              content: [
                t("page.returns.returnsExchanges.process.content.1", {
                  email: emailContact,
                }),
                t("contact.companyName"),
                t("page.returns.returnsExchanges.process.content.3", {
                  address: t("contact.companyAddressContent"),
                }),
              ],
            },
            {
              title: t("page.returns.returnsExchanges.fee.title"),
              content: [t("page.returns.returnsExchanges.fee.content.2")],
            },
            {
              title: t("page.returns.returnsExchanges.refunds.title"),
              content: [
                t("page.returns.returnsExchanges.refunds.content.1"),
                t("page.returns.returnsExchanges.refunds.content.2"),
              ],
            },
            {
              title: t("page.returns.returnsExchanges.exceptions.title"),
              content: [
                t("page.returns.returnsExchanges.exceptions.content.1"),
                t("page.returns.returnsExchanges.exceptions.content.2"),
              ],
            },
            {
              title: t("page.returns.returnsExchanges.questions.title"),
              content: [
                t("page.returns.returnsExchanges.questions.content.1"),
                generateList([
                  t("page.returns.returnsExchanges.questions.content.2", {
                    phone: PHONE_NUMBER_LINK,
                  }),
                  t("page.returns.returnsExchanges.questions.content.3", {
                    email: emailContact,
                  }),
                ]),
                t("contact.companyName"),
                t("page.returns.returnsExchanges.questions.content.4", {
                  address: t("contact.companyAddressContent"),
                }),
                t("page.returns.returnsExchanges.questions.content.5"),
                t("page.returns.returnsExchanges.questions.content.6", {
                  link: generateLink({
                    path: PATHS.privacyPolicy,
                    type: "internal",
                  }),
                }),
                t("page.returns.returnsExchanges.questions.content.7", {
                  link: generateLink({
                    path: PATHS.terms,
                    type: "internal",
                  }),
                }),
                t("page.returns.returnsExchanges.questions.content.8", {
                  link: generateLink({
                    path: PATHS.returnsPolicy,
                    type: "internal",
                  }),
                }),
                t("page.returns.returnsExchanges.questions.content.9", {
                  link: generateLink({
                    path: PATHS.shipping,
                    type: "internal",
                  }),
                }),
                t("page.returns.returnsExchanges.questions.content.10", {
                  email: emailContact,
                }),
              ],
            },
          ],
        },
      },
    };
  }, [emailContact, t]);

  return <PolicyContent data={tableContent} />;
}
