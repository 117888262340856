import { throwErrorMessage } from "@app-utils/errorHandler";

import { baseApi } from "./common";

const sendOtp = async (phone: string) => {
  try {
    const response = await baseApi.post(`sms/otp`, { phone });

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const smsServices = {
  sendOtp,
};

export default smsServices;
