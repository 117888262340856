import { useTranslation } from "react-i18next";

import Func1 from "@app-assets/images/ring-guide.png";

export default function RingOverview() {
  const { t } = useTranslation();
  return (
    <div className="lp-function__list-item lp-function__list-item-1">
      <div className="content">
        <div className="lp-function__text top">
          {t("page.product.guide.theRingDetail.forAccessKey")}
        </div>
        <img src={Func1} alt="" />
        <div className="lp-function__text bottom">
          {t("page.product.guide.theRingDetail.forPayment")}
        </div>
      </div>
    </div>
  );
}
