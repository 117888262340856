import { useSetAtom } from "jotai";
import { getAddressesServiceAtom, getProfileServiceAtom } from "./api_atoms";

const useUserWriteOnly = () => {
  const getProfile = useSetAtom(getProfileServiceAtom);
  const getAddressesList = useSetAtom(getAddressesServiceAtom);
  return {
    getProfile,
    getAddressesList,
  };
};

export { useUserWriteOnly };
