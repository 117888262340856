import { ResetPasswordReq } from "@app-interface/password";
import { throwErrorMessage } from "@app-utils/errorHandler";

import { baseApi } from "./common";

const forgot = async (username: string) => {
  try {
    const response = await baseApi.post(`password/forgot`, { username });

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const resetByPhone = async (data: ResetPasswordReq) => {
  try {
    const response = await baseApi.put(`password/phone/reset`, data);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const resetByEmail = async (data: ResetPasswordReq) => {
  try {
    const response = await baseApi.put(`password/email/reset`, data);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const passwordServices = {
  forgot,
  resetByPhone,
  resetByEmail,
};

export default passwordServices;
