import { useTranslation } from "react-i18next";

import CreateAccountSvg from "@app-assets/images/userGuide/create-account.svg";
import LinkBankSvg from "@app-assets/images/userGuide/link-bank.svg";
import LinkBuildingSvg from "@app-assets/images/userGuide/link-building.svg";
import OpenAppSvg from "@app-assets/images/userGuide/open-app.svg";
import PaymentSvg from "@app-assets/images/userGuide/payment.svg";
import RingListSvg from "@app-assets/images/userGuide/ring-list.svg";
import RingSizeGuideSvg from "@app-assets/images/userGuide/ring-size-guide.svg";
import SelectAccessKeySvg from "@app-assets/images/userGuide/select-access-key.svg";
import SelectBuildingSvg from "@app-assets/images/userGuide/select-building.svg";
import SelectLinkBankSvg from "@app-assets/images/userGuide/select-link-bank.svg";
import WithRingTabSvg from "@app-assets/images/userGuide/with-ring-tab.svg";
import WithoutRingTabSvg from "@app-assets/images/userGuide/without-ring-tab.svg";

import GuideImage from "./GuideImage";

type GuideContentType = "access" | "payment" | "ringSize";

const getSteps = (t: any, type: GuideContentType): any => ({
  access: {
    title: t("page.newProduct.guide.accessKey"),
    steps: [
      {
        title: t("page.guide.step", [1]),
        src: OpenAppSvg,
        description: t("page.guide.download"),
      },
      {
        title: t("page.guide.step", [2]),
        src: CreateAccountSvg,
        description: t("page.guide.createAccount"),
      },
      {
        title: t("page.guide.step", [3]),
        src: RingListSvg,
        description: t("page.guide.ringList"),
      },
      {
        title: t("page.guide.step", [4]),
        src: SelectAccessKeySvg,
        description: t("page.guide.selectAccessKeyTab"),
      },
      {
        title: t("page.guide.step", [5]),
        src: SelectBuildingSvg,
        description: t("page.guide.selectBuilding"),
      },
      {
        title: t("page.guide.step", [6]),
        src: LinkBuildingSvg,
        description: t("page.guide.addAccessKey"),
      },
    ],
  },
  payment: {
    title: t("page.newProduct.guide.addBank"),
    steps: [
      {
        title: t("page.guide.step", [1]),
        src: OpenAppSvg,
        description: t("page.guide.download"),
      },
      {
        title: t("page.guide.step", [2]),
        src: CreateAccountSvg,
        description: t("page.guide.createAccount"),
      },
      {
        title: t("page.guide.step", [3]),
        src: RingListSvg,
        description: t("page.guide.ringList"),
      },
      {
        title: t("page.guide.step", [4]),
        src: PaymentSvg,
        description: t("page.guide.selectPaymentTab"),
      },
      {
        title: t("page.guide.step", [5]),
        src: SelectLinkBankSvg,
        description: t("page.guide.selectLinkBank"),
      },
      {
        title: t("page.guide.step", [6]),
        src: LinkBankSvg,
        description: t("page.guide.linkBank"),
      },
    ],
  },
  ringSize: {
    title: t("page.newProduct.guide.ringSize"),
    steps: [
      {
        title: t("page.guide.step", [1]),
        src: OpenAppSvg,
        description: t("page.guide.download"),
      },
      {
        title: t("page.guide.step", [2]),
        src: RingSizeGuideSvg,
        description: t("page.guide.tabRingSizeGuide"),
      },
      {
        title: t("page.guide.step", [3]),
        src: WithRingTabSvg,
        description: t("page.guide.selectWithRingTab"),
      },
      {
        title: t("page.guide.step", [4]),
        src: WithoutRingTabSvg,
        description: t("page.guide.selectWithoutRingTab"),
      },
    ],
  },
});

export default function ContentHowToUse({ type }: { type: GuideContentType }) {
  const { t } = useTranslation();

  const content = getSteps(t, type);

  return (
    <div className="mt-ugd">
      <div className="mt-title">
        <span>{content?.[type]?.title}</span>
      </div>

      <div className="mt-ugd__link-imgs">
        {content[type]?.steps?.map((step: any) => {
          return (
            <GuideImage
              key={step.title}
              className={`ur-img ${type}`}
              title={step.title}
              src={step.src}
              description={step.description}
            />
          );
        })}
      </div>
    </div>
  );
}
