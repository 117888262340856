import { useTranslation } from "react-i18next";

import { SolidIcon } from "@app-components/svg/icon";

export default function MemberInfoCard({
  name,
  role,
  desc,
  image,
}: {
  name: string;
  role: string;
  desc: string;
  image: any;
}) {
  const { t } = useTranslation();

  return (
    <div className="member-card-container">
      <div className="member-card">
        <div className="image-container">
          {image ? <img src={image} alt={name} /> : <SolidIcon.User />}
        </div>
        <div className="desc-container">
          <div className="mt-text-body-xl">{name}</div>
          <div className="mt-text-body-lg">{t(role)}</div>
          <div className="mt-text-body-md mt-[12px] text-">{t(desc)}</div>
        </div>
      </div>
    </div>
  );
}
