import classNames from "classnames";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import EngFlag from "@app-components/svg/EngFlag";
import VNFlag from "@app-components/svg/VnFlag";
import { WEB_TYPE } from "@app-constants/common";
import { updateHeaderLangauge } from "@app-services/common";

const flags: any = {
  en: <EngFlag />,
  vi: <VNFlag />,
};

const langs: any = {
  vi: "Tiếng Việt",
  en: "English",
};

export default function HeaderLanguage() {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language) {
      updateHeaderLangauge(i18n.language);
    }
  }, [i18n.language]);

  const handleOnChange = () => {
    i18n.changeLanguage(i18n.language === "vi" ? "en" : "vi");
  };

  return (
    <div
      onClick={handleOnChange}
      className={classNames(
        "mt-header__language cursor-pointer",
        WEB_TYPE &&
          "flex flex-row justify-center items-center gap-[8px] font-medium"
      )}
    >
      {flags[i18n.language]} {WEB_TYPE && langs[i18n.language]}
    </div>
  );
}
