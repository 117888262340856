import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

import BannerBlurImage from "@app-assets/images/homepage/banner-blur.png";
import RingBlurImage from "@app-assets/images/homepage/ring-blur.png";
import { PATHS } from "@app-utils/routes";

import StyledButton from "../button/StyledButton";

const RingImage =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/home/ring.png";

const BannerImage =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/home/banner.png";

export default function Banner() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="banner-header">
      <LazyLoadImage
        className="bg"
        width={"100%"}
        height={"100%"}
        placeholderSrc={BannerBlurImage}
        src={BannerImage}
      />
      <div className="flex container flex-col md:flex-row content">
        <div className="flex flex-col gap-[16px] flex-1 justify-center items-center md:items-start order-2 md:order-1">
          <div className="banner-title">The Ring</div>
          <div className="banner-subtitle">{t("page.newHome.banner")}</div>
          <div>
            <StyledButton
              className="primary mt-button-lg px-[16px]"
              onClick={() => navigate(PATHS.product)}
            >
              {t("page.newHome.startYourJourney")}
            </StyledButton>
          </div>
        </div>
        <div className="md:flex-1 flex justify-center items-center md:order-2 order-1">
          <div className="image">
            <LazyLoadImage
              className="image"
              width={"100%"}
              height={"100%"}
              placeholderSrc={RingBlurImage}
              src={RingImage}
              alt="The Ring"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
