import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

import FingerBlurImage from "@app-assets/images/homepage/finger-blur.png";

const FingerImage =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/home/finger.png";

export default function DoItAllBanner() {
  const { t } = useTranslation();

  return (
    <div className="do-it-all-banner flex flex-col md:flex-row">
      <div className="img-container flex-1">
        <div className="image">
          <LazyLoadImage
            wrapperClassName="cover-center"
            width={"100%"}
            height={"100%"}
            placeholderSrc={FingerBlurImage}
            src={FingerImage}
            alt="The Ring"
          />
        </div>
      </div>
      <div className="container flex flex-1">
        <div className="flex-1 placeholder"></div>
        <div className="flex flex-1 content">
          <div className="title">
            <div className="title-secondary">
              {t("page.newHome.headline.doItAll")}
            </div>
            <div className="title-main">
              {t("page.newHome.headline.mainTitle")}
            </div>
          </div>
          <div className="mt-text-body-xxl">
            {t("page.newHome.headline.content")}
          </div>
        </div>
      </div>
    </div>
  );
}
