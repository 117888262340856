import { useTranslation } from "react-i18next";

import { QnASection } from "@app-components/faq/FaqSection";
import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import { faqData } from "@app-constants/faq";
import { getRoutes } from "@app-utils/routes";

export default function ProductSupportPage() {
  const { t } = useTranslation();
  const routes = getRoutes(t);
  return (
    <div className="container faq faq-detail">
      <MTBreadcrumb items={[routes.home, routes.faq, routes.productSupport]} />
      <div className="faq-detail-container">
        <div className="wrapper">
          <div className="faq-detail-header">
            <div className="faq-detail-header-container">
              <div className="faq-detail-title">
                {t("page.faq.section1.title")}
              </div>
              <div className="faq-detail-subtitle">
                {t("page.faq.section3.subtitle")}
              </div>
            </div>
          </div>
          <QnASection item={faqData[0]} showHeader={false} />
        </div>
      </div>
    </div>
  );
}
