import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import BoxComponentPng from "@app-assets/images/box-components.png";
import StyledButton from "@app-components/button/StyledButton";
import MTVideo from "@app-components/media/MTVideo";
import ProductBox from "@app-components/product/ProductBox";
import ProductGuides from "@app-components/product/ProductGuides";
import ProductHeader from "@app-components/product/ProductHeader";
import useScrollToTop from "@app-hook/useScrollToTop";
import Faq from "@app-pages/faq/FaqPage";
import { PATHS } from "@app-utils/routes";

export default function ProductV2() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useScrollToTop();

  return (
    <div className="product-v2">
      <ProductHeader />
      <ProductBox />
      <ProductGuides />

      <div className="page-bg">
        <div className="container video">
          <div className="title">{t("page.newProduct.videoTitle")}</div>
          <MTVideo
            id="product-detail-video"
            videoUrl="https://video.wixstatic.com/video/9a71f9_e9e5c7ec4bc240a38bb0ba5acfeafd49/1080p/mp4/file.mp4"
            thumbnail={BoxComponentPng}
          />
          <div className="action">
            <StyledButton
              className="primary mt-button-lg  px-[16px]"
              onClick={() => navigate(PATHS.contactsUs)}
            >
              {t("button.requestDemo")}
            </StyledButton>
          </div>
        </div>
        <Faq title={t("page.faq.searchForSolutions")} />
      </div>
    </div>
  );
}
