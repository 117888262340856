import { Carousel, CarouselProps } from "antd";
import classNames from "classnames";
import { ReactElement, useMemo } from "react";

import { SolidIcon } from "@app-components/svg/icon";

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={classNames("mt-carousel__arrow", className)}
      style={style}
      onClick={onClick}
    >
      <SolidIcon.ChevronLeft />
    </div>
  );
};

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={classNames("mt-carousel__arrow", className)}
      style={style}
      onClick={onClick}
    >
      <SolidIcon.ChevronRight />
    </div>
  );
};

export default function MTCarousel({
  images,
  customElements,
  autoplay,
  showDots = true,
  showArrows = true,
  settings,
}: {
  images?: string[] | string;
  customElements?: ReactElement[];
  autoplay?: boolean;
  showDots?: boolean;
  showArrows?: boolean;
  settings?: CarouselProps;
}) {
  const displayImages = useMemo(
    () => (typeof images === "string" ? [images] : images),
    [images]
  );

  const settingsConfig = useMemo(() => {
    if (!images && !customElements) {
      return {};
    }
    const config = {
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };
    if (images) {
      return config;
    }
    return {
      ...config,
      slidesToShow: 1,
      centerMode: true,
      infinite: true,
      centerPadding: "230px",
      className: "center",
      slidesToScroll: 1,
      autoplaySpeed: 10000,
      speed: 1000,
      responsive: [
        {
          breakpoint: 1198,
          settings: {
            slidesToShow: 1,
            centerPadding: "100px",
          },
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 1,
            centerPadding: "0px",
          },
        },
      ],
    };
  }, [customElements, images]);

  return (
    <div
      className={classNames(
        "mt-carousel",
        images && "mt-carousel-img",
        customElements && "mt-carousel-el"
      )}
    >
      <Carousel
        {...settings}
        {...settingsConfig}
        autoplay={autoplay}
        arrows={showArrows}
        dots={showDots ? { className: "mt-carousel__dots" } : false}
      >
        {displayImages?.map((item) => (
          <img src={item} key={item} alt="" />
        ))}
        {customElements}
      </Carousel>
    </div>
  );
}
