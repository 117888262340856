import { useTranslation } from "react-i18next";

import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import ContentHowToUse from "@app-components/userGuide/ContentHowToUse";
import useScrollToTop from "@app-hook/useScrollToTop";
import { getRoutes } from "@app-utils/routes";

export default function AccessControlPage() {
  const { t } = useTranslation();

  useScrollToTop();
  const routes = getRoutes(t);

  return (
    <div className="container mt-ugd faq faq-detail">
      <MTBreadcrumb items={[routes.home, routes.accessControl]} />

      <ContentHowToUse type="access" />
    </div>
  );
}
