import Lottie from "lottie-react";

import animationData from "@app-assets/lotties/checked.json";

export default function SuccessAnimation({ size = 100 }: { size?: number }) {
  return (
    <Lottie
      animationData={animationData}
      autoPlay
      loop
      style={{ width: size, height: size }}
    />
  );
}
