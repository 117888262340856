/* ts-ignore */
import { Getter, Setter, WritableAtom } from "jotai";

import { CartData } from "@app-interface/cart";
import { generateCartId } from "@app-utils/cart";
import { toNumber } from "@app-utils/number";

export const atomService = async (
  atom: WritableAtom<unknown, [(prev: any) => any], unknown>,
  fetch: () => void,
  get: Getter,
  set: Setter
) => {
  try {
    set(atom, (prev: any) => ({
      ...prev,
      success: false,
      loading: true,
      error: null,
    }));
    const data = await fetch();
    set(atom, (prev: any) => ({
      ...prev,
      success: true,
      loading: false,
      data,
    }));
    return data;
  } catch (error) {
    set(atom, (prev: any) => ({
      ...prev,
      success: false,
      loading: false,
      error,
    }));
  }
};

export const migrateCartData = (listItem: CartData[]): CartData[] => {
  return Object.values(
    listItem.reduce((result: any, item: CartData) => {
      const cartId = generateCartId(item.product.id, item.size, item.color);
      if (!result[cartId]) {
        result[cartId] = {
          ...item,
          cartId,
        };
      } else {
        result[cartId] = {
          ...result[cartId],
          cartId,
          quanlity: toNumber(result[cartId].quanlity) + toNumber(item.quantity),
        };
      }
      return result;
    }, {})
  );
};
