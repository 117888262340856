import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input } from "antd";
import classNames from "classnames";
import { useMemo } from "react";

import { OptionData } from "@app-components/select/types";

export function MTAutoComplete({
  options,
  value,
  filterOption,
  placeholder,
  allowClear,
  prefix,
  suffix,
  className,
  disabled,
  onSelect,
  onSearch,
}: {
  options: OptionData[];
  value?: string;
  filterOption?: boolean;
  placeholder?: string;
  allowClear?: boolean;
  prefix?: boolean;
  suffix?: boolean;
  className?: string;
  disabled?: boolean;
  onSelect?: (value: string) => void;
  onSearch?: (value: string) => void;
}) {
  const filters = useMemo(() => {
    const defaultFilterOption = (inputValue: any, option: any) =>
      option!.value.indexOf(inputValue.toLowerCase()) !== -1;
    return filterOption || defaultFilterOption;
  }, [filterOption]);

  return (
    <AutoComplete
      className={classNames(className)}
      allowClear={allowClear}
      options={options}
      onSelect={onSelect}
      onSearch={onSearch}
      filterOption={filters}
      value={value}
      disabled={disabled}
    >
      <Input
        className="mt-autocomplete"
        bordered={false}
        size="large"
        disabled={disabled}
        placeholder={placeholder}
        prefix={prefix && <SearchOutlined />}
        suffix={suffix && <DownOutlined />}
      />
    </AutoComplete>
  );
}
