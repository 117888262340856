import { Divider } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const contents = [
  {
    title: "page.pns.values.innovation.title",
    desc: "page.pns.values.innovation.desc",
    img: require("@app-assets//images/pns/innovation.gif"),
  },
  {
    title: "page.pns.values.enhanced.title",
    desc: "page.pns.values.enhanced.desc",
    img: require("@app-assets/images/pns/enhance.gif"),
  },
  {
    title: "page.pns.values.distinctive.title",
    desc: "page.pns.values.distinctive.desc",
    img: require("@app-assets/images/pns/distinctive.gif"),
  },
];

export function PNSValueSection() {
  const { t } = useTranslation();

  return (
    <section className="container pns-landing-page__value">
      <h2 className="section-title mb-[40px] lg:mb-[16px]">
        {t('page.pns.values.title')}
      </h2>
      {contents.map(({ title, desc, img }, index) => (
        <div key={index}>
          {index !== 0 && <Divider className="my-[72px]" />}
          <div className="value-container">
            <div
              className={classNames(
                index % 2 !== 0 ? "order-2" : "order-1",
                "content-container"
              )}
            >
              <div className="title">{t(title)}</div>
              <div className="desc">{t(desc)}</div>
            </div>
            <div
              className={classNames(
                index % 2 !== 0 ? "order-1" : "order-2",
                "gif-container"
              )}
            >
              <img src={img} alt={title} />
            </div>
          </div>
        </div>
      ))}
    </section>
  );
}
