import { atom } from "jotai";
import { atomService } from "../utils";
import { SignUpRequest, SigninRequest } from "../../interface/auth";
import authServices from "../../services/authServices";
import { signinPayloadAtom, signupPayloadAtom } from "./atoms";

const signinServiceAtom = atom(null, (get, set, data: SigninRequest) =>
  atomService(signinPayloadAtom, () => authServices.signin(data), get, set)
);

const signupServiceAtom = atom(null, (get, set, data: SignUpRequest) =>
  atomService(signupPayloadAtom, () => authServices.signup(data), get, set)
);

export { signinServiceAtom, signupServiceAtom };
