import React from "react";

export default function ChevronRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M18.0013 1.33301C27.2027 1.33301 34.668 8.79829 34.668 17.9997C34.668 27.2011 27.2027 34.6663 18.0013 34.6663C8.79991 34.6663 1.33463 27.2011 1.33463 17.9997C1.33463 8.79829 8.79991 1.33301 18.0013 1.33301Z"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M14.5249 26.3351L22.8594 18.0005L14.5249 9.66602"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
