import { useTranslation } from "react-i18next";

import "./styles.scss";

export default function ProductBox() {
  const { t } = useTranslation();
  const names = [
    "The Ring",
    t("page.newProduct.userManual"),
    t("page.newProduct.cover"),
  ];
  return (
    <div className="box container">
      <div className="title">{t("page.newProduct.boxTitle")}</div>
      <div className="flex flex-col md:flex-row gap-[16px]">
        <div className="banner">
          <img
            src={
              "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/product/box.png"
            }
            alt="What's in the box?"
          />
        </div>
        <div className="flex flex-col gap-[16px] flex-1 justify-end">
          {names.map((title, index) => (
            <div key={index} className="note">
              <div className="index mt-text-body-lg">{index + 1}</div>
              <div>{title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
