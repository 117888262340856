import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

import { SolidIcon } from "@app-components/svg/icon";
import { scrollTrigger } from "@app-utils/jQuery";

export default function MTVideo({
  id,
  videoUrl,
  thumbnail,
  paddingTop,
}: {
  id?: string;
  videoUrl: string;
  thumbnail?: any;
  paddingTop?: string;
}) {
  const [isPlay, setIsPlay] = useState(false);

  const [showThumb, setShowThumb] = useState(false);

  const isVisited = useRef(false);

  useEffect(() => {
    setShowThumb(!!thumbnail);
  }, [thumbnail]);

  useEffect(() => {
    isVisited.current = false;
    id &&
      scrollTrigger(
        `#${id}.mt-media__video`,
        {
          threshold: 0.5,
        },
        () => {
          if (!isVisited.current) {
            isVisited.current = true;
            setIsPlay(true);
            setShowThumb(false);
          }
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsPlay(true);
    setShowThumb(false);
  }, [videoUrl]);

  const handleIconClick = () => {
    setIsPlay(!isPlay);
    if (showThumb) {
      setShowThumb(false);
    }
  };

  return (
    <div
      className="mt-media__video"
      id={id}
      style={paddingTop ? { paddingTop } : undefined}
    >
      <ReactPlayer
        width="100%"
        height="100%"
        loop
        muted
        playing={isPlay}
        playsinline
        onPause={() => setIsPlay(false)}
        url={videoUrl}
        onPlay={() => {
          if (!isPlay) setIsPlay(true);
        }}
      />
      {showThumb && (
        <div className="mt-media__video-thumb">
          <img src={thumbnail} alt="" />
        </div>
      )}
      <div className="video-icon" onClick={handleIconClick}>
        {isPlay ? <SolidIcon.Pause /> : <SolidIcon.PlayIcon />}
      </div>
    </div>
  );
}
