import React from "react";

export default function PlayCircleSvg() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="47.0248"
        height="47.0248"
        rx="23.5124"
        fill="white"
        fillOpacity="0.68"
      />
      <path
        d="M17 14.8368C17 14.5 17.0927 14.1696 17.2678 13.8818C17.443 13.594 17.6939 13.36 17.9932 13.2052C18.2924 13.0505 18.6284 12.981 18.9645 13.0044C19.3006 13.0278 19.6237 13.1432 19.8986 13.3379L32.757 22.4545C32.9958 22.6234 33.1909 22.8469 33.3259 23.1064C33.461 23.3659 33.5321 23.6539 33.5333 23.9464C33.5346 24.2389 33.466 24.5275 33.3332 24.7882C33.2004 25.0489 33.0072 25.274 32.7698 25.445L19.9115 34.6975C19.6372 34.8953 19.3136 35.0136 18.9763 35.0393C18.6391 35.0649 18.3013 34.997 18.0002 34.8429C17.6991 34.6889 17.4464 34.4547 17.2699 34.1661C17.0934 33.8776 17 33.5459 17 33.2077V14.8368Z"
        fill="#111318"
      />
    </svg>
  );
}
