export default function NumberOneCircle() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9.79232"
        cy="9.79134"
        r="8.20833"
        stroke="#111318"
        strokeWidth="1.5"
      />
      <path
        d="M10.9066 5.83301V14.1558H9.53369V7.46224L7.5 8.15153V7.0179L10.7414 5.83301H10.9066Z"
        fill="#111318"
      />
    </svg>
  );
}
