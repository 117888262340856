import { useState } from "react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import {
  Autoplay,
  EffectCoverflow,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import Member1BlurImage from "@app-assets/images/about/team1-blur.png";
import Member2BlurImage from "@app-assets/images/about/team2-blur.png";
import Member3BlurImage from "@app-assets/images/about/team3-blur.png";
import Member4BlurImage from "@app-assets/images/about/team4-blur.png";
import Member5BlurImage from "@app-assets/images/about/team5-blur.png";
import CustomLazyLoadImage from "@app-components/common/CustomLazyLoadImage";

const Member1Image =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/about/team1.png";
const Member2Image =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/about/team2.png";
const Member3Image =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/about/team3.png";
const Member4Image =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/about/team4.png";
const Member5Image =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/about/team5.png";

const SPEED = 800;

const images = [
  { image: Member5Image, placeholder: Member5BlurImage },
  { image: Member1Image, placeholder: Member1BlurImage },
  { image: Member2Image, placeholder: Member2BlurImage },
  { image: Member3Image, placeholder: Member3BlurImage },
  { image: Member4Image, placeholder: Member4BlurImage },
];

export default function SliderMember() {
  const [swiper, setSwiper] = useState<SwiperClass>();

  const handleOnChangeSlide = (slide: SwiperClass) => {
    if (slide.activeIndex > slide.clickedIndex) {
      swiper?.slidePrev(SPEED);
    } else if (slide.activeIndex < slide.clickedIndex) {
      swiper?.slideNext(SPEED);
    }
  };
  return (
    <Swiper
      onSwiper={setSwiper}
      onClick={handleOnChangeSlide}
      onInit={(e: any) => {
        e?.slideNext(SPEED);
      }}
      effect="coverflow"
      grabCursor
      breakpoints={{
        400: {
          slidesPerView: 1,
          effect: "none",
        },
        768: {
          slidesPerView: 1.75,
          effect: "coverflow",
          centeredSlides: false,
          coverflowEffect: {
            rotate: 50,
            depth: 100,
            modifier: 0.2,
            scale: 1.5,
          },
        },
      }}
      className="mySwiper"
      keyboard
      loopAddBlankSlides
      loop={true}
      modules={[
        Navigation,
        Autoplay,
        EffectCoverflow,
        Pagination,
        Keyboard,
        Mousewheel,
      ]}
      spaceBetween={60}
      autoplay={{
        delay: 8000,
        disableOnInteraction: false,
      }}
      speed={1000}
      pagination={{
        clickable: true,
        type: undefined,
      }}
    >
      {images.map((item, index) => (
        <SwiperSlide key={index}>
          <div className="overlay"></div>
          <CustomLazyLoadImage
            placeholder={item.placeholder}
            image={item.image}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
