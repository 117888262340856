export default function CheckSvg({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.48606 2 2 6.48606 2 12C2 17.5139 6.48606 22 12 22C17.5139 22 22 17.5139 22 12C22 6.48606 17.5139 2 12 2ZM17.2043 8.64856L10.7428 16.3409C10.6719 16.4253 10.5837 16.4935 10.4842 16.5409C10.3846 16.5882 10.2761 16.6136 10.1659 16.6154H10.1529C10.0451 16.6153 9.93846 16.5926 9.83998 16.5487C9.7415 16.5048 9.65335 16.4407 9.58125 16.3606L6.81202 13.2837C6.74169 13.2091 6.68698 13.1212 6.65111 13.0251C6.61524 12.9291 6.59892 12.8268 6.60313 12.7244C6.60733 12.622 6.63197 12.5214 6.67559 12.4286C6.71922 12.3359 6.78094 12.2527 6.85715 12.1842C6.93336 12.1156 7.0225 12.0629 7.11935 12.0293C7.21619 11.9957 7.31878 11.9817 7.42109 11.9883C7.5234 11.9949 7.62336 12.0219 7.7151 12.0676C7.80683 12.1134 7.8885 12.177 7.95529 12.2548L10.1327 14.674L16.0264 7.65913C16.1586 7.50629 16.3457 7.41161 16.5471 7.39555C16.7486 7.3795 16.9483 7.44337 17.103 7.57335C17.2577 7.70333 17.3551 7.88899 17.3741 8.09018C17.393 8.29138 17.3321 8.49196 17.2043 8.64856Z"
        fill="currentColor"
      />
    </svg>
  );
}
