import { Alert } from "antd";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import validator from "validator";

import StyledButton from "@app-components/button/StyledButton";
import MTInput from "@app-components/input/MTInput";
import { LocalStorageKeys } from "@app-constants/common";
import { signinPayloadAtom, useAuthWriteOnly } from "@app-jotai/auth";
import { formatPhoneNumber, isNumber } from "@app-utils/number";
import { PATHS } from "@app-utils/routes";

export default function GBALogin() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { onSignin, clearSigninPayload, setToken } = useAuthWriteOnly();
  const signinPayload = useAtomValue(signinPayloadAtom);

  const [signin, setSignin] = useState({
    phone: "",
    password: "TheRing@16",
  });
  const [isRemember, setIsRemember] = useState(false);

  const validPhone = validator.isMobilePhone(signin.phone, "vi-VN");
  const validEmail = validator.isEmail(signin.phone);

  useEffect(() => {
    handleGetSavedAccount();
    return () => {
      clearSigninPayload();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signinPayload.success) {
      if (isRemember) {
        window.localStorage.setItem(
          LocalStorageKeys.SAVED_ACCOUNT,
          signin.phone
        );
      } else {
        window.localStorage.removeItem(LocalStorageKeys.SAVED_ACCOUNT);
      }

      setToken(signinPayload?.data?.accessToken as string);
      const redirect = searchParams.get("redirect");
      navigate(redirect || PATHS.gba);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signinPayload.success]);

  const handleGetSavedAccount = () => {
    const phone = window.localStorage.getItem(LocalStorageKeys.SAVED_ACCOUNT);
    if (phone) {
      setIsRemember(true);
      setSignin((prev) => ({
        ...prev,
        phone,
      }));
    }
  };

  const handleSignin = () => {
    if (signin.phone && signin.password) {
      const formattedPhone = isNumber(signin.phone)
        ? formatPhoneNumber(signin.phone)
        : signin.phone;
      onSignin({ username: formattedPhone, password: signin.password });
    }
  };

  const handleOnNavigateSignup = () => {
    navigate(PATHS.register);
  };

  const invalidUsernameMsg = useMemo(() => {
    const isNum = isNumber(signin.phone);

    const invalidPhone =
      signin.phone && signin.phone?.length >= 10 && !validPhone && isNum
        ? t("content.invalidPhone")
        : "";

    const invalidEmail =
      signin.phone && signin.phone?.length >= 10 && !validEmail
        ? t("content.invalidEmail")
        : "";
    const message =
      validEmail || validPhone ? "" : invalidPhone || invalidEmail;

    return message;
  }, [signin.phone, t, validEmail, validPhone]);

  const renderContent = () => {
    if (signinPayload.success) return null;
    return (
      <>
        <div className="input relative pb-6">
          <MTInput
            label={t("input.resetPassword.label")}
            value={signin.phone}
            onChangeText={(value) =>
              setSignin((prev) => ({
                ...prev,
                phone: value,
              }))
            }
            error={invalidUsernameMsg}
          />
        </div>

        {signinPayload?.error && (
          <div className="pb-6 text-left">
            <Alert message={t(signinPayload.error)} type="error" showIcon />
          </div>
        )}

        <div className="flex items-center justify-between pb-[24px]">
          <Link to={PATHS.forgotPassword}>
            <span className="cursor-pointer mt-text-button-md">
              {t("button.forgotPassword")}
            </span>
          </Link>
        </div>

        <div className="text-center">
          <StyledButton
            className="w-full primary"
            onClick={handleSignin}
            loading={signinPayload.loading}
            disabled={!signin.phone || !signin.password || !!invalidUsernameMsg}
          >
            <span className="mt-text-button-xl py-[13px]">
              {t("button.signin")}
            </span>
          </StyledButton>

          <div className="py-[32px]">
            <span className="mt-text-body-xl">{t("content.needAccount")}</span>
          </div>
          <StyledButton
            className="outline w-full"
            onClick={handleOnNavigateSignup}
          >
            <span className="mt-text-button-xl py-[13px]">
              {t("button.signup")}
            </span>
          </StyledButton>
        </div>
      </>
    );
  };

  return (
    <div className="auth-page container flex items-center justify-center pt-[20px] pb-[40px]">
      <div className="form sm:min-w-[360px] lg:min-w-[464px]">
        <div className="gba-page auth-page__title">
          {signinPayload.success ? t("title.welcomeToMT") : t("title.signin")}
        </div>
        <div className="gba-page faq-detail-subtitle">
          Welcome to GBA Family Day 2024
        </div>
        {renderContent()}
      </div>
    </div>
  );
}
