export default function VNFlag(props: any) {
  return (
    <svg
      {...props}
      width="42"
      height="32"
      viewBox="0 0 42 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_826_271)">
        <rect width="42" height="32" rx="2" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H42V32H0V0Z"
          fill="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V32H42V0H0Z"
          fill="#E31D1C"
        />
        <mask
          id="mask0_826_271"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="42"
          height="32"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0V32H42V0H0Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_826_271)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.0813 21.3066L14.3207 25.6732L16.5883 18.0292L11.7663 13.035L18.4143 12.8874L21.3556 5.34098L24.0358 12.9871L30.668 13.1047L25.684 18.1905L28.0114 25.4625L21.0813 21.3066Z"
            fill="#FFD221"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_826_271">
          <rect width="42" height="32" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
