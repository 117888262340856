import React from "react";

export default function Logout() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M16.8752 27.3334H3.42421C2.6844 27.3334 1.98943 27.042 1.47381 26.5264C0.958187 26.0107 0.666748 25.2933 0.666748 24.5647V3.42421C0.666748 2.6844 0.958187 1.98943 1.47381 1.47381C2.00064 0.958187 2.69561 0.666748 3.42421 0.666748H16.8752C17.615 0.666748 18.31 0.958187 18.8256 1.47381C19.3525 1.98943 19.6327 2.6844 19.6327 3.42421V7.26896C19.6327 7.72854 19.2516 8.10965 18.792 8.10965C18.3324 8.10965 17.9513 7.72854 17.9513 7.26896V3.42421C17.9513 3.13277 17.8392 2.86375 17.6375 2.66198C17.4357 2.46022 17.1667 2.34813 16.8752 2.34813H3.42421C3.13277 2.34813 2.86375 2.46022 2.66198 2.66198C2.46022 2.86375 2.34813 3.14398 2.34813 3.42421V24.5647C2.34813 24.845 2.46022 25.1252 2.66198 25.327C2.86375 25.5287 3.14398 25.6408 3.42421 25.6408H16.8752C17.1667 25.6408 17.4357 25.5287 17.6375 25.327C17.8392 25.1252 17.9513 24.845 17.9513 24.5647V20.72C17.9513 20.2604 18.3324 19.8793 18.792 19.8793C19.2516 19.8793 19.6327 20.2604 19.6327 20.72V24.5647C19.6327 25.2933 19.3413 26.0107 18.8256 26.5151C18.31 27.042 17.615 27.3334 16.8752 27.3334Z"
        fill="#111318"
      />
      <path
        d="M26.4926 14.8352H13.0416C12.582 14.8352 12.2009 14.4541 12.2009 13.9945C12.2009 13.5349 12.582 13.1538 13.0416 13.1538H26.4926C26.9522 13.1538 27.3333 13.5349 27.3333 13.9945C27.3333 14.4541 26.9522 14.8352 26.4926 14.8352Z"
        fill="#111318"
      />
      <path
        d="M22.6479 18.6799C22.435 18.6799 22.222 18.6014 22.0539 18.4332C21.7288 18.1082 21.7288 17.5701 22.0539 17.2451L25.3045 13.9944L22.0539 10.7437C21.7288 10.4187 21.7288 9.88063 22.0539 9.55557C22.3789 9.2305 22.917 9.2305 23.242 9.55557L27.0868 13.4003C27.4119 13.7254 27.4119 14.2634 27.0868 14.5885L23.242 18.4332C23.0739 18.6014 22.8609 18.6799 22.6479 18.6799Z"
        fill="#111318"
      />
    </svg>
  );
}
