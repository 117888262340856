import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import React, { useState } from "react";

export default function MTPasswordInput({
  label,
  value,
  error,
  onChangeText,
}: {
  label: string;
  value?: string;
  error?: string;
  onChangeText?: (text: string) => void;
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <div className="input relative w-full mt-input">
        <input
          value={value}
          autoComplete="new-password"
          type={showPassword ? "text" : "password"}
          className="
          block
          py-[6px]
          w-full
          bg-transparent
          border-0
          border-b
          border-b-metalic-silver
          appearance-none
          focus:outline-none
          focus:ring-0
          peer
        "
          // dont remove this line
          placeholder=" "
          // dont remove this line
          onChange={(e) => onChangeText?.(e.target.value)}
        />
        <label
          className="
          absolute
          duration-300
          transform
          -translate-y-[8px]
          flex
          items-center
          h-[34px]
          top-0
          left-0
          -z-10
          origin-[0]
          peer-placeholder-shown:translate-y-[16px]
          peer-placeholder-shown:text-[18px]
          peer-placeholder-shown:leading-6
          peer-focus:-translate-y-[8px]
          peer-focus:bottom-0
          select-none
          text-tertiary
          peer-focus:text-[14px]
          peer-focus:leading-[20px]
        "
        >
          {label}
        </label>
        <div
          className="
          absolute
          right-0
          bottom-0
          w-[34px]
          h-[41px]
          flex
          items-center
          justify-center
          cursor-pointer
        "
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        </div>
      </div>
      {!!error && (
        <div className="input-error text-red-600 text-md pt-2 text-left">
          {error}
        </div>
      )}
    </div>
  );
}
