import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { DISPLAY_PHONE_NUMBER, EMAIL } from "@app-constants/common";
import { getRoutes } from "@app-utils/routes";

export default function PolicyContact() {
  const { t } = useTranslation();
  const routes = getRoutes(t);

  return (
    <div>
      <br />
      <br />
      <br />
      <p>
        {t("contact.company")}:{" "}
        <strong className="uppercase">{t("contact.companyName")}</strong>
      </p>
      <br />
      <p>
        {t("contact.enterpriseCode")}: <strong>0317004074</strong>
      </p>
      <br />
      <p>
        {t("label.headquarter")}:{" "}
        <strong>{t("contact.companyAddressContent")}</strong>
      </p>
      <br />
      <p>
        {t("contact.phoneNumber")}: <strong>{DISPLAY_PHONE_NUMBER}</strong>
      </p>
      <br />
      <Trans
        i18nKey="contact.questionForPrivacy"
        components={{
          textLink: (
            <strong>
              <a href={`https://manytouches.com${routes.privacyPolicy.path}`}>
                {routes.privacyPolicy.title}
              </a>
            </strong>
          ),
          urlLink: (
            <a href={`https://manytouches.com${routes.privacyPolicy.path}`}>
              {`https://manytouches.com${routes.privacyPolicy.path}`}
            </a>
          ),
        }}
      />

      <br />
      <br />

      <Trans
        i18nKey="contact.questionForTerm"
        components={{
          textLink: (
            <strong>
              <a href={`https://manytouches.com${routes.terms.path}`}>
                {routes.terms.title}
              </a>
            </strong>
          ),
          urlLink: (
            <a href={`https://manytouches.com${routes.terms.path}`}>
              {`https://manytouches.com${routes.terms.path}`}
            </a>
          ),
        }}
      />
      <br />
      <br />

      <Trans
        i18nKey="contact.questionForReturn"
        components={{
          textLink: (
            <strong>
              <a href={`https://manytouches.com${routes.returnsPolicy.path}`}>
                {routes.returnsPolicy.title}
              </a>
            </strong>
          ),
          urlLink: (
            <a href={`https://manytouches.com${routes.returnsPolicy.path}`}>
              {`https://manytouches.com${routes.returnsPolicy.path}`}
            </a>
          ),
        }}
      />
      <br />
      <br />

      <Trans
        i18nKey="contact.questionForShipping"
        components={{
          textLink: (
            <strong>
              <a href={`https://manytouches.com${routes.shipping.path}`}>
                {routes.shipping.title}
              </a>
            </strong>
          ),
          urlLink: (
            <a href={`https://manytouches.com${routes.shipping.path}`}>
              {`https://manytouches.com${routes.shipping.path}`}
            </a>
          ),
        }}
      />
      <br />
      <br />
      <p>
        {t("contact.companyEmail")}:{" "}
        <strong>
          <a href="mailto:contact@manytouches.com">{EMAIL.contact}</a>
        </strong>
      </p>
    </div>
  );
}
