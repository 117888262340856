export default function Home() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5315 2.41435C11.8054 2.19522 12.1946 2.19522 12.4685 2.41435L21.4685 9.61435C21.6464 9.75668 21.75 9.97216 21.75 10.2V21C21.75 21.4142 21.4142 21.75 21 21.75H15.6C15.1858 21.75 14.85 21.4142 14.85 21V13.65H9.15V21C9.15 21.4142 8.81421 21.75 8.4 21.75H3C2.58579 21.75 2.25 21.4142 2.25 21V10.2C2.25 9.97216 2.35357 9.75668 2.53148 9.61435L11.5315 2.41435ZM3.75 10.5605V20.25H7.65V12.9C7.65 12.4858 7.98579 12.15 8.4 12.15H15.6C16.0142 12.15 16.35 12.4858 16.35 12.9V20.25H20.25V10.5605L12 3.96047L3.75 10.5605Z"
        fill="#111318"
      />
    </svg>
  );
}
