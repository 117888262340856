import { Collapse } from "antd";
import { TOptionsBase } from "i18next";
import { useTranslation } from "react-i18next";

import { ReactComponent as ChevronDownSvg } from "@app-assets/svg/chevronDown.svg";

export interface OptionData {
  value: string;
  label: string;
}

export interface SubContentProps {
  label?: string;
  items?: string[];
  html?: string;
}

export type QnAItemProps = {
  label: string;
  content?: string;
  contentClasses?: string;
  subContent?: SubContentProps[];
  summary?: string;
  summaryParams?: TOptionsBase & object & { [key: string]: any };
  summaryClasses?: string;
  images?: string[];
  html?: string;
  params?: { [key: string]: any };
};

export interface FaqItemProps {
  label: string;
  items: QnAItemProps[];
}

export function QnASubContent({ item }: { item: SubContentProps }) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="faq__section-subcontent-title">
        {item?.label && t(item.label)}
      </div>
      {item?.items && (
        <ul className="faq__section-subcontent-option">
          {item.items.map((item: string) => (
            <li
              dangerouslySetInnerHTML={{
                __html: t(item),
              }}
              key={item}
            />
          ))}
        </ul>
      )}
      {!!item?.html && (
        <div dangerouslySetInnerHTML={{ __html: t(item.html) }} />
      )}
    </div>
  );
}

export function QnAItem({ item }: { item: QnAItemProps }) {
  const { t } = useTranslation();
  return (
    <div className="faq__section-item" key={item.label}>
      <div>
        {item.html ? (
          <span
            dangerouslySetInnerHTML={{
              __html: item.params ? t(item.html, item?.params) : t(item.html),
            }}
          />
        ) : (
          <span className={item.contentClasses}>{t(item.content || "")}</span>
        )}
        <div className="faq__section-subcontent">
          {item?.subContent &&
            item.subContent.map((itm: SubContentProps) => (
              <QnASubContent key={itm.label} item={itm} />
            ))}
        </div>

        {!!item?.summary && (
          <div
            className={item.summaryClasses}
            dangerouslySetInnerHTML={{
              __html: item.summaryParams
                ? t(item.summary, item.summaryParams)
                : t(item.summary),
            }}
          />
        )}
        {!!item.images && (
          <div className="faq__section-image">
            {item.images.map((img) => (
              <img key={img} alt="app-download" src={img} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export function QnASection({
  item,
  showHeader = true,
}: {
  item: FaqItemProps;
  showHeader?: boolean;
}) {
  const { t } = useTranslation();
  // const items = item.items.map((itm: QnAItemProps) => ({
  //   key: item.label,
  //   label: item.label,
  //   children: <QnAItem item={itm} />,
  // }));
  return (
    <div className="faq__section" key={item.label}>
      {showHeader && <h2 className="faq__section-title">{t(item.label)}</h2>}
      <div className="faq__section-content">
        <Collapse
          bordered={false}
          expandIconPosition="end"
          ghost
          expandIcon={({ isActive }) => (
            <ChevronDownSvg className={isActive ? "faq__section-rotate" : ""} />
          )}
        >
          {item.items.map((itm: any) => (
            <Collapse.Panel
              header={
                <div className="faq__section-question">{t(itm.label)}</div>
              }
              key={itm.label}
            >
              <QnAItem item={itm} key={itm.label} />
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
}
