import { Alert, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import StyledButton from "@app-components/button/StyledButton";
import { useAuthWriteOnly } from "@app-jotai/auth";
import useService from "@app-services/shared/use_service";
import userServices from "@app-services/userServices";
import { PATHS } from "@app-utils/routes";

const useUpdateProfile = () => useService(userServices.updateProfile);

export default function SettingPage() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const { onSignout } = useAuthWriteOnly();
  const navigate = useNavigate();

  const [updateProfilePayload, updateProfile] = useUpdateProfile();

  useEffect(() => {
    if (updateProfilePayload.success) {
      message.success(t("message.deactivateSuccess"));
      onSignout();
      navigate(PATHS.login);
    }
  }, [navigate, onSignout, t, updateProfilePayload.success]);

  const handleDeactivateAccount = () => {
    updateProfile({
      deactivatedAt: true,
    });
  };

  return (
    <div className="container pt-10">
      <div>
        <div className="text-[24px] font-bold">
          {t("title.deactivateYourAccount")}
        </div>
        <div className="text-right pt-5">
          <StyledButton className="primary" onClick={() => setShowModal(true)}>
            {t("button.deactivateNow")}
          </StyledButton>
        </div>
      </div>
      <Modal open={showModal} onCancel={handleCloseModal} footer={null}>
        <div>
          <div className="text-[24px] font-bold">
            {t("content.modalDelete.deactivateYourAccount")}
          </div>
          <div className="text-metalic-silver">
            {t("content.modalDelete.seeYouGo")}
          </div>
          <div className="font-semibold pt-3">
            {t("content.modalDelete.beforeYouGo")}
          </div>
          <div className="border-b-[1px] pt-1 border-metalic-silver"></div>
          <ul className="list-disc pl-5 pt-2">
            <li className="pt-1">{t("content.modalDelete.loginAgain")}</li>
            <li className="pt-1">{t("content.modalDelete.deleteAfter")}</li>
            <li className="pt-1">{t("content.modalDelete.cannotRestore")}</li>
          </ul>
          {updateProfilePayload?.error && (
            <div className="pt-5">
              <Alert
                message={t(updateProfilePayload.error)}
                type="error"
                showIcon
              />
            </div>
          )}
          <div className="pt-5">
            <StyledButton className="primary" onClick={handleCloseModal}>
              {t("button.keepMyAccount")}
            </StyledButton>
            <StyledButton
              className="ml-4"
              loading={updateProfilePayload.loading}
              onClick={handleDeactivateAccount}
            >
              <span className="text-secondary-color">
                {t("button.deactivateNow")}
              </span>
            </StyledButton>
          </div>
        </div>
      </Modal>
    </div>
  );
}
