export default function HandShake() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="32"
      viewBox="0 0 44 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9243 10.4862L21.3369 6.49004C22.3517 5.57278 23.5536 4.88746 24.8593 4.48158C26.165 4.07571 27.5433 3.95899 28.8986 4.13953L34.738 4.9136L42.8403 0.530381C42.96 0.465508 43.0945 0.432887 43.2306 0.435716C43.3667 0.438546 43.4997 0.476728 43.6166 0.546522C43.7336 0.616315 43.8304 0.715326 43.8976 0.833849C43.9648 0.952373 44.0001 1.08634 44 1.22263V16.1312C43.9999 16.6315 43.8807 17.1245 43.6522 17.5694C43.4237 18.0143 43.0926 18.3983 42.6863 18.6894L37.2303 22.5943L31.3217 17.6353C32.4742 17.2204 33.5139 16.5416 34.3577 15.6529C34.537 15.467 34.678 15.2475 34.7726 15.007C34.8672 14.7665 34.9136 14.5097 34.9091 14.2513C34.9046 13.9928 34.8492 13.7378 34.7463 13.5008C34.6433 13.2637 34.4947 13.0493 34.309 12.8698C34.1233 12.6902 33.904 12.5491 33.6638 12.4544C33.4236 12.3596 33.1671 12.3132 32.909 12.3177C32.6508 12.3223 32.3961 12.3777 32.1594 12.4808C31.9226 12.5838 31.7085 12.7326 31.5291 12.9185C30.9477 13.5258 30.272 13.916 29.48 14.0702C28.7917 14.2055 27.9149 14.1835 26.8117 13.8436L25.41 12.6637L21.4971 15.8386C20.7833 16.4045 19.8783 16.6715 18.972 16.5835C18.0657 16.4955 17.2288 16.0593 16.6369 15.3666C16.0451 14.6738 15.7444 13.7784 15.798 12.8684C15.8515 11.9584 16.2552 11.1046 16.9243 10.4862ZM14.2906 7.56618L17.4586 4.69963C16.0966 4.32758 14.6696 4.2599 13.2786 4.50139L7.51143 5.50201L1.18172 2.0911C1.0595 2.02514 0.922269 1.99209 0.783464 1.99521C0.644658 1.99833 0.509042 2.03749 0.389904 2.10887C0.270766 2.18025 0.172192 2.28139 0.103842 2.40238C0.0354918 2.52338 -0.000290334 2.66007 1.77423e-06 2.79908V16.1281C1.77423e-06 17.1916 0.524859 18.186 1.40172 18.7838L18.546 30.486C19.6297 31.2254 20.9137 31.6148 22.2251 31.6019C23.5365 31.589 24.8124 31.1743 25.8814 30.4136L33.7574 24.8158L25.366 17.7675L23.9674 18.8939C22.4585 20.1081 20.5367 20.6864 18.6092 20.5063C16.6817 20.3262 14.9 19.4019 13.6415 17.9292C12.383 16.4565 11.7466 14.5511 11.8672 12.6166C11.9878 10.6822 12.8558 8.87082 14.2874 7.56618H14.2906Z"
        fill="#111318"
      />
    </svg>
  );
}
